import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root',
})

export class DataService {
    configData: any = null;
    constructor(private http: HttpClient, private config: AppConfigService) {
        this.configData = this.config.getConfig();
    }

    public getData() {
        // "sampleDataUrl" : "../data/data.json"
        return this.http.get(this.configData.sampleDataUrl)
            .map(data => {
                return data;
            });
    }

    public getData2() {

        // "sampleDataUrl" : "../data/data.json"
        return this.http.get(this.configData.sampleDataUrl2)
            .map(data => {
                return data;
            });
    }

    /**
     * Get the environment data for all docs
     */
public getEnvironmentData() {
    console.log ('in data service getEnvironmentData');
    return this.http.get('../../../../data/environment.json')
        .map(data => {
            console.log('in map');
            return data;
        });
}

    /**
     * Get the requesttypes data for all docs
     */
    public getRequestTypes() {
        console.log ('in data service getRequestTypes');
        return this.http.get('../../../../data/requestType.json')
            .map(data => {
                console.log('in map');
                return data;
            });
    }

    /**
     * Get the AD groups data
     */
    public getADGroupsData() {
        console.log ('in  getADGroupsData - Data Service');
        return this.http.get('../../../../data/adgroup.json')
            .map(data => {
                console.log('in map');
                return data;
            });
    }

     /**
      * Get the Stream data
      */
    public getStreamsData() {
        console.log ('in  getStreamsData - Data Service');
        return this.http.get('../../../../data/stream.json')
            .map(data => {
                console.log('in map');
                return data;
            });
    }
/**
 * Get the transaction data for all docs
 * @param fromdate ;
 * @param todate ;
 * @param TableName ;
 */
    public getGridData(fromDate, toDate, tableName) {
        return this.http.get('https://akh9crh2x3.execute-api.us-east-1.amazonaws.com/2758_Dev_QlikSelfservice/getscoringdata?&fromdate='
            + fromDate + '&todate=' + toDate + '&TableName=' + tableName)
            .map(data => {
                return data;
            });
    }
}
