import { NewRequestService } from './../../../core/services/new-request.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-license-transfer-popup',
  templateUrl: './license-transfer-popup.component.html',
  styleUrls: ['./license-transfer-popup.component.css']
})
export class LicenseTransferPopUpComponent implements OnInit, OnChanges {
  @Input()
  RequestId: any;
  @Input()
  isView: number;
  isEdit: boolean;
  @Input()
  userType;
  issueDate = new Date();
  issueVal = '';
  // isView = false;
  txtfilname = ''; txtBody; filname = ''; body; filedata = ''; appDisable = true;
  loadingShow = false; applicationTeamName = ''; platform = '';  approver = '';
  owner = ''; newOwner = ''; hasAttachment = false; showLink = false; validateAttachment = false;
  comment = ''; opsComment = ''; opsCommentValMsg = ''; filePath = ''; approverValMSg = '';
  disableApprove = true; requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = '';  approverValMsg = '';
  ownerValMsg = ''; newOwnerValMsg = ''; regex = '[a-zA-Z.]*'; commentValMsg = ''; radioValMsg = ''; attachmentValMsg = ''; leaveOrg = 'Organization';
  @Output() passData: EventEmitter<any> = new EventEmitter();
  licId;
  licenseId;
  constructor(public activeModal: NgbActiveModal, private toaster: ToastrService, private toastr: ToastrService,
              private getLicenseService: GetLicenseRequestService,
              private modalService: NgbModal, private qlikGetService: GetRequestService, private newreqservice: NewRequestService,
              private getService: GetLicenseRequestService, private createService: CreateLicenseRequestService) {

              }

  popOpen(p: NgbPopover, val): void {

    if (val > 2000000) {
      p.open();
    } else {
      p.close();
    }
  }
  closePopOver(p: NgbPopover) {
    p.close();
  }
  setIssueDate() {
    this.issueVal = this.issueDate.getFullYear() + '-' + (this.issueDate.getMonth() + 1) + '-' + this.issueDate.getDate();
    console.log(this.issueVal);
  }
  ngOnInit() {
    this.isEdit = this.isView !== 2;
    this.disableApprove =  this.userType === 'OPS' ? true : false;
    this.loadingShow = true;
    this.getRequestById();

  }
  ngOnChanges() {
    this.isEdit = this.isView !== 2;
  }
  getRequestById() {
    this.loadingShow = true;
    this.getService.getRequestById(this.RequestId, 'Transfer')
      .take(1).
      subscribe(
        data1 => {
          this.applicationTeamName = data1[0][0].AppTeamName;
          this.platform = data1[0][0].PlatformId;
          this.owner = data1[0][0].License_Owner;
          this.newOwner = data1[0][0].New_License_Owner;
          this.hasAttachment = data1[0][0].HasEmployee_Attachment;
          this.comment = data1[0][0].Transfer_Release_Comment;
          this.approver = data1[0][0].WBSEApprovedBy;
          if (data1[1].length > 0) {
            this.licId = data1[1][0].Lic_ID;
            this.licenseId = data1[1][0].License_ID;
            if (data1[2].length > 0) {
              this.filePath = data1[2][0].s3path;
              this.showLink = true;
            } else {
              this.showLink = false;
            }
          }
          this.loadingShow = false;
        },
        error => {
          console.log(error);
          this.loadingShow = false;
        }
      );
  }
  approveReject(type) {
    if (this.approveValidation(type)) {
      this.loadingShow = true;
      const dataToSend = {
        Req_Flag: 'Transfer',
        AssignmentDate: null, approver: this.userType,
        licenseType_Id: null, Environment: null, OpsComment: this.opsComment,
        Req_ID: this.RequestId, EmpList: [], Status: type, Lic_ID: this.licId,
        New_License_Owner: this.newOwner, platform: this.platform, License_ID: this.licenseId
      };
      console.log(dataToSend);
      this.createService.approveReject(dataToSend).subscribe(
        res => {
          console.log(res);
          this.loadingShow = false;
          this.passData.emit('s');
          this.activeModal.close();
        },
        error => {
          this.loadingShow = false;
          this.passData.emit('f');
          this.activeModal.close();
          console.log(error);
        }
      );
    }
  }
  approverEid() {
    this.approver = this.approver.split('@')[0]
    this.approver =  this.approver;
    const checkEmail = this.approver.match(this.regex);   
    if(checkEmail[0] === this.approver)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.approverValMsg = ' '
    }  
    else
    {
      this.approverValMsg = 'Invalid EID,Please Enter valid EID'
      this.approver = '';
    }  
}

ownerEid() {
  this.owner = this.owner.split('@')[0]
  this.owner = this.owner;
  const checkEmail = this.owner.match(this.regex);   
    if(checkEmail[0] === this.owner)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.ownerValMsg=''
    }  
    else
    {
      this.ownerValMsg = 'Invalid EID,Please Enter valid EID';
      this.owner = '';
    }  
}

newOwnerEid(){
  this.newOwner = this.newOwner.split('@')[0]
  this.newOwner = this.newOwner;
  const checkEmail = this.newOwner.match(this.regex);   
    if(checkEmail[0] === this.newOwner)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.newOwnerValMsg=''
    }  
    else
    {
      this.newOwnerValMsg = 'Invalid EID,Please Enter valid EID';
      this.newOwner = '';
    }  

}
  approveValidation(type) {
    if (type === 'Approve') {
      return true;
    } else {
      if (this.opsComment === '') {
        this.opsCommentValMsg = 'Please Enter comment for Rejection';
        return false;
      }
      return true;
    }
  }
  downloadFile() {
    console.log(this.filePath)
    this.loadingShow = true;
    this.qlikGetService.downloadFile(this.filePath, '').subscribe((res: any) => {
      window.open(res, '_blank');
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
      console.log(error);
    });
  }
  reviewRequest() {
    this.loadingShow = true;
    this.getService.reviewRequest(this.platform, this.RequestId).subscribe(
      res => {
        console.log( res[0][0].Status);
        this.disableApprove = res[0][0].Status === 1 ? false : true;
        this.loadingShow = false;
        this.toaster.success('License are available.');
      },
      error => {
        this.loadingShow = false;
        this.toaster.error('Some error Occured, Please try again');
      }
    );
  }
  handleChange(value) {
    if (value === 'Project') {
      this.validateAttachment = true;
    } else {
      this.validateAttachment = false;
    }
  }
  validation() {
    if (this.approver === '') {
      this.approverValMsg = 'Please Enter Approver Name';
      return false;
    }
    if (this.owner === '') {
      this.ownerValMsg = 'Please Enter Current Owner EID';
      return false;
    }
    if (this.newOwner === '') {
      this.newOwnerValMsg = 'Please Enter New Owner EID';
      return false;
    }
    if (this.validateAttachment) {
      if (this.hasAttachment === false) {
        this.attachmentValMsg = 'Please attach necessary screenshot';
        return false;
      }
    }

    // Need to add validation for attachment
    if (this.comment === '') {
      this.commentValMsg = 'Please enter description comments';
      return false;
    }
    return true;
  }
  updateRequest() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        AppTeamName: this.applicationTeamName,
        PlatformId: this.platform,
        LicenseCategory: null,
        LicenseRequestType: null,
        RequesterOrganizationGroup: null,
        AIRID: null,
        NumberOfLicenses: null,
        WBSE: null,
        WBSEApprovedBy: this.approver,
        GeneralLedgerNo: null,
        Comments: null,
        CreatedDate: null,
        AssignmentDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        Status: null,
        Req_Flag: 'Transfer',
        License_Owner: this.owner,
        New_License_Owner: this.newOwner,
        HasEmployee_Attachment: this.leaveOrg,
        Attachment: null, // Add attachment
        Transfer_Release_Comment: this.comment,
        OpsComment: null,
        RequestId: this.RequestId,
        EmpList: [], filename: this.filname
      };
      console.log(dataToSend);
      this.createService.editLicenseRequest(dataToSend).subscribe(res => {
        if (this.filname !== '') {
          this.newreqservice.saveFile(this.body, res).subscribe(res1 => {
            this.toastr.success('Request updated successfully.');
            // this.clearAllData();
            this.loadingShow = false;
            this.activeModal.close();
          }, error => {
            this.toastr.error('Some error occured.');
            console.log(error);
            this.loadingShow = false;
            // this.activeModal.close();
            // this.clearAllData();
          });
        } else {
          this.toastr.success('Request  updated successfully.');
          // this.clearAllData();
          this.loadingShow = false;
          this.activeModal.close();
        }
      },
        error => {
          console.log(error);
          this.toastr.error('Some error occured.');
          // this.activeModal.close();
          this.loadingShow = false;
          // this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
  resetOwnerField() {
    this.owner = '';
    this.newOwner = '';
  }
  checkUser(type) {
    // if (!this.checkPlatform()) {
    //   return false;
    // }
    let eid;
    type === 'new' ? eid = this.newOwner : eid = this.owner;
    if ((type === 'new' && this.newOwner !== '') || (type === 'old' && this.owner !== '')) {
      this.loadingShow = true;
      let platformId;
      if (this.platform.toString() === '1') {
        platformId = 1001;
      } else {
        platformId = 1002;
      }
      this.getLicenseService.verifyUser(eid, platformId, type).subscribe(res => {
        if (type === 'old') {
          if (res[0][0].count < 1) {
            this.owner = '';
            this.ownerValMsg = 'No license issued to this user';
          }
        }
        if (type === 'new') {
          if (res[0][0].count > 0) {
            this.newOwner = '';
            this.newOwnerValMsg = 'Request already raised for this user';
          }
        }
        this.loadingShow = false;
      }, error => {
        console.log(error);
      });
    }
  }
  clearAllData() {
    this.platform = '';
    this.owner = ''; this.newOwner = ''; this.comment = '';
    this.filedata = '';
    this.hasAttachment = false; this.leaveOrg = 'Organization';
  }
  clearAllDatacancel() {
    this.appDisable = false;
    this.applicationTeamName = ''; this.platform = '';
    this.owner = ''; this.newOwner = ''; this.comment = '';
    this.filedata = '';
    this.hasAttachment = false; this.leaveOrg = 'Organization';
  }
}
