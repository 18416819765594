import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { GetRequestService } from '../../../core/services/get-request.service';
import { data } from '../../../core/model/New-Request.model';

import { NewRequestService } from '../../../core/services/new-request.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-request',
  templateUrl: './approve-request.component.html',
  styleUrls: ['./approve-request.component.css']
})
export class ApproveRequestComponent implements OnInit {
  // output field
  @Output() passData: EventEmitter<any> = new EventEmitter();
  // Input Field
  @Input()
  RequestId: any;
  @Input()
  approver: any;
  @Input()
  statuses: any;
  allData = data; orgrowData = []; rowData = []; comments = []; attachment = [];
  @Input()
  disableVal: boolean;
  @Input()
  popover: NgbPopover;
  AINamesList = [];
  showComment: boolean; loadingShow = false; EIIStatusVal = 0;
  comment = ''; commentValMsg = ''; add = true; indexVal = 0;
  report = ''; description = ''; dataSource = ''; keyMetrics = ''; dsother = ''; dataSourceArray = [];
  refreshFreq = ''; release = new Date(); timeRefreshSchedule = '';
  user = 0; concurrentUser = 0; showFilePath = true;
  filename = ''; fileBody;
  dataVolume = 0; yearToMaintain = ''; other = '';
  reportValMsg = ''; descriptionValMsg = ''; dataSourceValMsg = '';
  keyMetricsValMsg = ''; refreshFreqValMsg = ''; releaseValMsg = ''; timeRefreshScheduleValMsg = '';
  userValMsg = 0; concurrentUserValMsg = 0;
  dataVolumeValMsg = ''; yearToMaintainValMsg = '';
  disableApprove = false; noData = false;
  dasboardValMsg = ''; filePath = ''; AIFlag = false; AIRIDFlag = false;
  txtfilname = ''; txtBody; allDataArray = [];
  columnDefs = [
    { headerName: 'Reports', field: 'reports', width: 180 },
    { headerName: 'Refresh Frequency', field: 'refreshFreq', width: 150, filter: 'agTextColumnFilter' },
    {
      headerName: 'Time Schedule', field: 'timeRefreshSchedule', width: 160,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Planned Release', field: 'release', cellRenderer: (dataval) => {
        return this.datepipe.transform(dataval.value, 'yyyy-MM-dd');
      }, width: 160
    },
    {
      headerName: 'Ops Status', field: 'OpsStatus', width: 210, cellRenderer: (dataval) => {
        return dataval.value === 101 ? 'Pending' : (dataval.value === 104 ? 'Additional Information Needed' : 'Approved');
      }, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'AI Status', field: 'EIIStatus', width: 210, cellRenderer: (dataval) => {
        return dataval.value === 101 ? 'Pending' : dataval.value === 107 ? 'NA' :
        (dataval.value === 104 ? 'Additional Information Needed' : 'Approved');
      }, filter: 'agTextColumnFilter'
    }
  ];
  defaultColDef = { resizable: true };
  domainListArray = [];
  domainOther = '';
  roleListArray = [];
  roleOther = '';
  constructor(private AIservice: GetRequestService, public activeModal: NgbActiveModal, private modalService: NgbModal,
              private getService: GetRequestService, private requestService: NewRequestService,
              private datepipe: DatePipe, private toastr: ToastrService) {
              }
  ngOnInit() {
    this.loadingShow = true;
    this.getAllAILeadsNames();
  // this. getRequestById();
  }
  getRequestById() {
    this.getService.getRequestById(this.RequestId)
      .take(1).
      subscribe(
        data1 => {
          const datearray = data1[0][32].response.split('-');
          const date = new Date(datearray[0], datearray[1], datearray[2]);
          this.allData = data1[0];
          const demand = this.allData[2].response;
          this.changeofCIONonCIO(this.allData[6].response);
          this.changeOfAIOrNonAI(this.allData[48].response);
          // this.getAllAILeadsNames();
          var responseflag:boolean;
          //this.allData[2].response
          demand === '0' ? responseflag = false : responseflag = true;
          this.allData[2].response = responseflag;
          this.allData[32].response = new Date();
          this.rowData = data1[1];
          const constData =  data1[1];
          this.orgrowData = JSON.parse(JSON.stringify(constData));
          // this.rowData = [{reports: 'report', description: 'ds', dataSource: 'ds', keyMetrics: 'km',
          // refreshFreq: 'Daily', timeRefreshSchedule: 'tm', opsStatus: 'Approve', EIIStatus: 'pending' , release: new Date()}];
          this.attachment = data1[2];
          if (this.attachment.length > 0 && this.attachment[0].filename !== '') {
            this.attachment.forEach(row => {
              this.filePath = this.filePath +  row.s3path + ',';
            });
            this.showFilePath = true;
          } else {
            this.showFilePath = false;
          }
          this.filePath = this.filePath.substring(0, (this.filePath.length - 1));
          this.comments = data1[3];
          this.loadingShow = false;
        },
        error => {
          console.log(error);
          this.loadingShow = false;
        }
      );
  }
  getAllAILeadsNames() {
    this.AIservice.getAllRequest('AI', 'AI')
      .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
      .subscribe(
        data1 => {
          console.log(data1);
          this.AINamesList = data1[0];
          this.getRequestById();
          // this.rowData = [{RequestId: '1', platform: 'QlikSense', applicationname: 'Admin',
          // opsApprovalStatus: 'Pending', EiiApprovalStatus: 'Pending' }];
        },
        error => {
console.log('Error: Unable to find AI Names');
        }
      );
  }
  changeOfAIOrNonAI(item) {
   // this.allData[49].valMsg ='';
   // this.allData[49].response ='';
      if (item === 'AI' || item === 'EII') {
        this.AIFlag = false;
      } else {
        this.AIFlag = true;
        this.allData[49].valMsg = '';
        this.allData[49].response = '';
        this.AINamesList = this.AINamesList;
       // this.getAllAILeadsNames();
      }
  }

  changeofCIONonCIO(item) {
    this.allData[50].valMsg = '';
    if ((item === 'internal') || (item === 'Cio')) {
      this.allData[31].response = 'internal';
      this.AIRIDFlag = false;
    } else {
      this.allData[50].response = '';
      this.allData[31].response = 'external';
      this.AIRIDFlag = true;
    }
  }

  next(type, stepper: MatStepper) {
    if (type === 'Approve') {
      if (this.comment === '' &&
        ((this.rowData.filter(x => x.OpsStatus === 104 && x.isActive === 1).length > 0
          && this.approver === 'OPS') ||
          (this.rowData.filter(x => x.EIIStatus === 104 && x.isActive === 1).length > 0
            && this.approver === 'EII'))) {
        this.commentValMsg = 'Please Enter Comment if you need more information for any of the dashboard.';
        return false;
      } else {
        this.approveReject(type);
      }
    } else if (type === 'Save') {
      this.saveRequest();
    } else {
      if (!this.disableVal) {
        if (type === 'general') {
          if (!this.genralValidation()) {
            return false;
          }
        } else if (type === 'Dashboard') {
          if (!this.dasboardValidation()) {
            return false;
          }
        } else if (type === 'benefits') {
          if (!this.benifitsValidation()) {
            return false;
          }
        } else if (type === 'readiness') {
          if (!this.readynessValidation()) {
            return false;
          }
        } else {
          if (!this.dataPrivacyValidation()) {
            return false;
          }
        }
      } else if (this.approver !== 'User' && type === 'readiness') {
        if (!this.readynessValidation()) {
          return false;
        }
      }
      stepper.next();
    }
  }
  saveRequest() {
    this.loadingShow = true;
    if (this.allData[48].response === 'EII' && this.statuses[0].eiistatus === 107) {
     this.EIIStatusVal = 101;
    } else {
    this.EIIStatusVal = (this.statuses[0].eiistatus === 104) ? 101 : this.statuses[0].eiistatus;
    }
    const opsStatus = (this.statuses[0].opsstatus === 104) ? 101 : this.statuses[0].opsstatus;
    this.allData[32].response = this.datepipe.transform(this.allData[32].response.toString(), 'yyyy-MM-dd');
    this.allData[22].response = this.allData[22].response.toString();
    this.allData[13].response = this.allData[13].response.toString();
    for (let i = 0; this.rowData.length > i; i++) {
      if (!(this.rowData[i].dataSource instanceof Array)) {
        this.rowData[i].dataSource = this.rowData[i].dataSource.split(',');
        this.rowData[i].OpsStatus = (this.rowData[i].OpsStatus === 104 ? 101 :
          this.rowData[i].OpsStatus);
        this.rowData[i].EIIStatus = (this.rowData[i].EIIStatus === 104 ? 101 :
          this.rowData[i].EIIStatus);
      }
    }
    const dataToSend = {
      Req_ID: this.RequestId,
      RequestType: this.allData[0].response,
      AppName: this.allData[4].response,
      detailsData: this.rowData,
      filename: this.filename,
      filebody: this.fileBody,
      queAns: this.allData,
      comment: this.comment,
      eiistatus: this.EIIStatusVal,
      opsstatus: opsStatus,
      AIA: this.allData[48].response
    };
    console.log(dataToSend);
    this.requestService.UpdateReject(dataToSend).subscribe(res => {
      if (this.filename !== '') {
        this.requestService.saveFile(this.fileBody, res).subscribe(res2 => {
          this.loadingShow = false;
          this.passData.emit('s');
          this.activeModal.close();
        }, error => {
          this.loadingShow = false;
          this.passData.emit('f');
          this.activeModal.close();
        });
      } else {
        this.loadingShow = false;
        this.passData.emit('s');
        this.activeModal.close();
      }
    },
      error => {
        console.log(error);
        this.loadingShow = false;
        this.passData.emit('f');
        this.activeModal.close();
      });
  }
  selectFile(event) {
    if (event.target.files.length > 0) {
      this.filename = event.target.files[0].name;
      this.fileBody = event.target.files.item(0);

    } else {

    }
  }
  approveReject(type) {
    let approveType = type;
    if (type === 'Approve') {
      if (this.approver === 'OPS') {
        if (this.rowData.filter(x => x.OpsStatus === 101).length > 0) {
          this.commentValMsg = 'You have to Approve/Reject all Dashboard(s), To Submit This Request.';
          return false;
        } else {

        }
      } else {
        if (this.rowData.filter(x => x.EIIStatus === 101).length > 0) {
          this.commentValMsg = 'You have to Approve/Reject all Dashboard(s), To Submit This Request.';
          return false;
        }
      }
    }
    this.loadingShow = true;
    if (type === 'Approve') {
      if (this.approver === 'OPS') {
        if (this.rowData.filter(x => x.OpsStatus === 103).length !==
          this.rowData.filter(x => x.isActive === 1).length) {
          approveType = 'NeedMoreInfo';
        }
      }
      if (this.approver === 'EII') {
        if (this.rowData.filter(x => x.EIIStatus === 103).length !==
          this.rowData.filter(x => x.isActive === 1).length) {
          approveType = 'NeedMoreInfo';
        }
      }
    }
    const dataToSend = {
      reqID: this.RequestId, approverFlag: this.approver, Status: approveType,
      comments: this.comment, detailsData: this.rowData
    };
    console.log('approve');
    console.log(dataToSend);
    this.requestService.approveReject(dataToSend).subscribe(
      res => {
        console.log(res);
        this.loadingShow = false;
        this.passData.emit('s');
        this.activeModal.close();
      },
      error => {
        this.loadingShow = false;
        this.passData.emit('f');
        this.activeModal.close();
        console.log(error);
      }
    );
  }
  previous(stepper: MatStepper) {
    console.log(stepper);
    stepper.previous();
  }
  dropdownChange(p: NgbPopover, num) {
    if (this.allData[num].response === 'Other') {
      p.open();
    } else {
      this.allData[num].other = '';
    }
  }
  dropdownChangeFrequncy(p: NgbPopover, val) {
    console.log('frequncy' + val);
    if (val === 'Other') {
      p.open();
    } else {
      p.close();
    }
  }
  checkValue(p: NgbPopover, o: NgbPopover) {
    this.dataSource = '';
    const blankSpace = this.dataSourceArray.indexOf('') > -1 ? true : false;
    if (this.dataSourceArray.indexOf('') > -1) {
      this.dataSourceArray.splice(this.dataSourceArray.indexOf(''));
    }
    this.dataSourceArray.forEach((val, index) => {
      console.log(index);
      if (val !== '') {
        if ((index + 1) === this.dataSourceArray.length) {
          this.dataSource = this.dataSource + val;
        } else {
          this.dataSource = this.dataSource + val + ',';
        }
      }
    });
    if (blankSpace) {
      p.close();
      o.open();
    } else {
      this.dsother = '';
    }
  }
  addOtherDataSource(o: NgbPopover) {
    this.dataSource = this.dataSource === '' ? this.dataSource + this.dsother : this.dataSource + ',' + this.dsother;
    o.close();
  }

  checkValuealldata(p: NgbPopover, o: NgbPopover, num, val) {
    this.domainOther = '';
    this.roleOther = '';
    this.allData[num].response = '';
    const blankSpace = val.indexOf('') > -1 ? true : false;
    if (val.indexOf('') > -1) {
      val.splice(val.indexOf(''));
    }
    val.forEach((value, index) => {
      console.log(index);
      if (value !== '') {
        if ((index + 1) === val.length) {
          this.allData[num].response = this.allData[num].response + value;
        } else {
          this.allData[num].response = this.allData[num].response + value + ',';
        }
      }
    });
    if (blankSpace) {
      p.close();
      o.open();
    } else {

    }
  }
  addOtherDataSourcealldata(o: NgbPopover, num, val) {
    this.allData[num].response = this.allData[num].response === '' ?
    this.allData[num].response + val :
    this.allData[num].response + ',' + val;
    o.close();
  }
  refreshFocus(p: NgbPopover) {
    if (this.refreshFreq === 'Other') {
      p.open();
    } else {
      p.close();
    }
    this.refreshFreqValMsg = '';
  }
  resetDashboard() {
    console.log(this.orgrowData);
    // this.rowData = this.orgrowData;
    this.rowData = JSON.parse(JSON.stringify(this.orgrowData));
  }
  addRecord() {
    const releasedate = this.datepipe.transform(this.release, 'yyyy-MM-dd');
    if (this.validation()) {
      if (this.add) {
        const details = {
          Doc_ID: '',
          reports: this.report, dataSource: this.dataSource, description: this.description,
          keyMetrics: this.keyMetrics, timeRefreshSchedule: this.timeRefreshSchedule,
          refreshFreq: this.refreshFreq, release: releasedate, users: this.user,
          concurrentUser: this.concurrentUser,
          VolumeOfData: this.dataVolume, DataRetainPeriod: this.yearToMaintain, Other: this.other,
          EIIStatus: 101, OpsStatus: 101, isActive: 1
        };
        this.rowData.push(details);
        this.allData[38].response = this.rowData.filter(x => x.isActive === 1).length;
      } else {
        this.rowData[this.indexVal].reports = this.report;
        this.rowData[this.indexVal].dataSource = this.dataSource;
        this.rowData[this.indexVal].description = this.description;
        this.rowData[this.indexVal].keyMetrics = this.keyMetrics;
        this.rowData[this.indexVal].refreshFreq = this.refreshFreq;
        this.rowData[this.indexVal].timeRefreshSchedule = this.timeRefreshSchedule;
        this.rowData[this.indexVal].release = releasedate;
        this.rowData[this.indexVal].users = this.user;
        this.rowData[this.indexVal].concurrentUser = this.concurrentUser;
        this.rowData[this.indexVal].VolumeOfData = this.dataVolume;
        this.rowData[this.indexVal].DataRetainPeriod = this.yearToMaintain;
        this.rowData[this.indexVal].Other = this.other;
        this.rowData[this.indexVal].OpsStatus = (this.rowData[this.indexVal].OpsStatus === 104 ? 101 :
          this.rowData[this.indexVal].OpsStatus);
        this.rowData[this.indexVal].EIIStatus = (this.rowData[this.indexVal].EIIStatus === 104 ? 101 :
          this.rowData[this.indexVal].EIIStatus);
        this.indexVal = 0;
        console.log(this.rowData[this.indexVal].OpsStatus);
        console.log(this.rowData[this.indexVal].EIIStatus);
      }
      this.add = true;
      this.clearData();
    }
  }
  clearData() {
    this.report = ''; this.description = ''; this.dataSource = ''; this.other = '';
    this.keyMetrics = ''; this.refreshFreq = ''; this.release = new Date();
    this.timeRefreshSchedule = ''; this.dataVolume = 0; this.yearToMaintain = '';
    this.user = 0; this.concurrentUser = 0;
  }
  edit(index, operation) {
    console.log(this.orgrowData[0].details);
    if (operation === 'Edit') {
      this.add = false;
      this.indexVal = index;
      console.log(index);
      const datearray = this.rowData[index].release.split('-');
      const dateVal = new Date(datearray[0], datearray[1], datearray[2]);
      this.report = this.rowData[index].reports; this.description = this.rowData[index].description;
      this.dataSource = this.rowData[index].dataSource; this.keyMetrics = this.rowData[index].keyMetrics;
      this.refreshFreq = this.rowData[index].refreshFreq;
      this.release = this.rowData[index].release;
      this.user = this.rowData[index].users;
      this.concurrentUser = this.rowData[index].concurrentUser;
      this.dataVolume = this.rowData[index].VolumeOfData;
      this.yearToMaintain = this.rowData[index].DataRetainPeriod;
      this.other = this.rowData[index].Other;
      console.log(this.release);
      this.timeRefreshSchedule = this.rowData[index].timeRefreshSchedule;
    } else {
      this.commentValMsg = '';
      this.dasboardValMsg = '';
      this.approver === 'OPS' ? this.rowData[index].OpsStatus = operation :
        this.rowData[index].EIIStatus = operation;
      // if (this.approver === 'OPS') {
      //   this.rowData.filter(x => x.OpsStatus === 102).length === this.rowData.length ?
      //     this.disableApprove = true : this.disableApprove = false;
      // }
      // if (this.approver === 'EII') {
      //   this.rowData.filter(x => x.EIIStatus === 102).length === this.rowData.length ?
      //     this.disableApprove = true : this.disableApprove = false;
      // }
    }
  }
  delete(index) {
    this.rowData[index].isActive = 0;
    if (this.rowData.filter(x => x.isActive === 0).length === this.rowData.length) {
      this.noData = true;
    }
    this.allData[38].response = this.rowData.filter(x => x.isActive === 1).length;
  }
  rowColor(opsstatus, eiiStatus) {
    if (this.approver === 'OPS') {
      return opsstatus === 103 ? 'enableColor' : (opsstatus === 104 ? 'orangeColor' : '');
    }
    if (this.approver === 'EII') {
      return eiiStatus === 103 ? 'enableColor' : (eiiStatus === 104 ? 'orangeColor' : '');
    }
    if (this.approver === 'User') {
      return (eiiStatus === 104 || opsstatus === 104) ? 'orangeColor' : ((eiiStatus === 103 && opsstatus === 103) ? 'enableColor' : '');
    }
  }
  reset() {
    console.log(this.rowData);
    console.log(this.orgrowData);
    this.rowData = this.orgrowData[0].details;
  }
  downloadFile() {
    this.loadingShow = true;
    this.getService.downloadFile(this.filePath, '').subscribe((res: any) => {
      for (let i = 0; res.length > i; i++) {
        window.location.href = res[i];
      }
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
      console.log(error);
    });
  }
  // validations

  validation() {
    if (this.report === '') {
      this.reportValMsg = 'Enter value for report';
      return false;
    }
    if (this.description === '') {
      this.descriptionValMsg = 'Enter description';
      return false;
    }
    if (this.dataSource === '') {
      this.dataSourceValMsg = 'Enter  data Source';
      return false;
    }
    // if (this.keyMetrics === '') {
    //   this.keyMetricsValMsg = 'Enter key metrics';
    //   return false;
    // }
    if (this.refreshFreq === '') {
      this.refreshFreqValMsg = 'Enter refresh frequency';
      return false;
    }
    if (this.timeRefreshSchedule === '') {
      this.timeRefreshScheduleValMsg = 'Please Enter refresh frequency';
      return false;
    }
    if (this.yearToMaintain === '') {
      this.yearToMaintainValMsg = 'Please Enter period of data planned to retain';
      return false;
    }
    if (this.dataVolume === 0) {
      this.dataVolumeValMsg = 'Please Enter volume of data';
      return false;
    }
    return true;
  }

  genralValidation() {

    if (this.allData[0].response === '') {
      this.allData[0].valMsg = 'Please Select Platform';
      return false;
    }
    if (this.allData[1].response === '') {
      this.allData[1].valMsg = 'Please Enter Organization';
      return false;
    }
    if (((this.allData[2].response === true || this.allData[2].response === '') && this.allData[3].response === '')) {
      this.allData[2].valMsg = 'Please Enter demand id';
      return false;
    }
    if (this.allData[4].response === '') {
      this.allData[4].valMsg = 'Please Enter Application Name';
      return false;
    }
    if (this.allData[5].response === '') {
      this.allData[5].valMsg = 'Please Enter Project Name';
      return false;
    }
    if (this.allData[23].response === '') {
      this.allData[23].valMsg = 'Please Enter Sponsor';
      return false;
    }
    if (this.allData[24].response === '') {
      this.allData[24].valMsg = 'Please Enter Business Contact';
      return false;
    }
    if (this.allData[25].response === '') {
      this.allData[25].valMsg = 'Please Enter Delivery/Capability Lead';
      return false;
    }
    if (this.allData[26].response === '') {
      this.allData[26].valMsg = 'Please Enter Delivery /Project Manager';
      return false;
    }
    if (this.allData[27].response === '') {
      this.allData[27].valMsg = 'Please Enter Project POC';
      return false;
    }
    if (this.allData[28].response === '') {
      this.allData[28].valMsg = 'Please Enter Project Active Directory Mailing Group';
      return false;
    }
    if (this.allData[29].response === '') {
      this.allData[29].valMsg = 'Please Enter Group Location(s)';
      return false;
    }
    if (this.allData[30].response === '') {
      this.allData[30].valMsg = 'Please Enter CIO Contact';
      return false;
    }
    if (this.allData[31].response === '') {
      this.allData[31].valMsg = 'Please Enter Use';
      return false;
    }
    if (this.allData[49].response === '' || this.allData[49].response === '0') {
      if (this.allData[48].response === 'EII') {
      this.allData[49].valMsg = 'Please Select AI Lead';
      return false;
      }
    }
    if (this.allData[50].response === '') {
      if (this.allData[6].response === 'Cio') {
      this.allData[50].valMsg = 'Please Enter AIRID';
      return false;
      }
    }
    return true;
  }

  dasboardValidation() {
    if (this.allData[7].response === '') {
      this.allData[7].valMsg = 'Please Select value for existing asset checked';
      return false;
    }
    if (this.allData[8].response === '') {
      this.allData[8].valMsg = 'Please Enter value for business needs';
      return false;
    }
    if (this.allData[10].response === '') {
      this.allData[10].valMsg = 'Please Select QlikView/QlikSense usage aligns to their Business Case';
      return false;
    }
    if (this.allData[9].response === '') {
      this.allData[9].valMsg = 'Please Describe Why QlikSense or QlikView';
      return false;
    }
    if (this.allData[33].response === '') {
      this.allData[33].valMsg = 'Please Enter Business Case';
      return false;
    }
    if (this.allData[12].response === '') {
      this.allData[12].valMsg = 'Please Enter value for data source';
      return false;
    }
    if (this.allData[13].response === '') {
      this.allData[13].valMsg = 'Please List all domains of enterprise data used';
      return false;
    }
    if ((this.allData[14].response === true && this.allData[15].response === '')) {
      this.allData[14].valMsg = 'Please Enter EID of approval';
      return false;
    }
    // if (this.allData[16].response === '') {
    //   this.allData[16].valMsg = 'Please Enter period of data planned to retain';
    //   return false;
    // }
    // if (this.allData[19].response === 0) {
    //   this.allData[19].valMsg = 'Please Enter volume of data';
    //   return false;
    // }
    if (this.allData[47].response === '' && Number(this.allData[19].response) > 2000000) {
      this.allData[19].valMsg = 'Please Enter details';
      this.popover.open();
      return false;
    }
    if (this.allData[20].response === '') {
      this.allData[20].valMsg = 'Please Select how are we Planning to consume Qlik Dashboard';
      return false;
    }
    if (this.allData[21].response === '') {
      this.allData[21].valMsg = 'Please Enter Customers, End Users details';
      return false;
    }
    if (this.allData[22].response === '') {
      this.allData[22].valMsg = 'Please Select Role';
      return false;
    }
    return true;
  }

  benifitsValidation() {
    // if (this.allData[34].response === '') {
    //   this.allData[34].valMsg = 'Please Enter Financial - Cost Reduction';
    //   this.popover.open();
    //   return false;
    // }
    // if (this.allData[35].response === '') {
    //   this.allData[35].valMsg = 'Please Enter Financial - Cost Avoidance';
    //   return false;
    // }
    // if (this.allData[36].response === '') {
    //   this.allData[36].valMsg = 'Please Select Speed & Effectiveness';
    //   return false;
    // }
    // if (this.allData[37].response === '') {
    //   this.allData[37].valMsg = 'Please Select Strategic Impact';
    //   return false;
    // }

    return true;
  }
  readynessValidation() {
    if (this.approver === 'User') {
      if (this.allData[38].response === 0) {
        this.allData[38].valMsg = 'Please add dashboard details.';
        return false;
      }
      if (this.allData[41].response === '') {
        this.allData[41].valMsg = 'Please Select Developer Readiness';
        return false;
      }
      if (this.allData[42].response === '') {
        this.allData[42].valMsg = 'Please Select Scope';
        return false;
      }
      if (this.allData[43].response === '') {
        this.allData[43].valMsg = 'Please Select Data';
        return false;
      }
    } else {
      if (this.approver === 'OPS' && this.rowData.filter(x => x.OpsStatus === 101).length > 0) {
        this.dasboardValMsg = 'Please Review all Dashboards';
        return false;
      }
      if (this.approver === 'EII' && this.rowData.filter(x => x.EIIStatus === 101).length > 0) {
        this.dasboardValMsg = 'Please Review all Dashboards';
        return false;
      }
    }
    return true;
  }
  dataPrivacyValidation() {
    if (this.allData[44].response === '') {
      this.allData[44].valMsg = 'Please Select Report has PII Data?';
      return false;
    }
    if (this.allData[45].response === '') {
      this.allData[45].valMsg = 'Please Select Data Privacy Plan';
      return false;
    }
    if (!this.allData[46].response) {
      this.allData[46].valMsg = 'Please accept and then proceed next';
      return false;
    }
    return true;
  }
  popOpen(p: NgbPopover, val): void {
    this.popover = p;
    if (val > 2000000) {
      p.open();
    } else {
      p.close();
    }
  }
  closePopOver(p: NgbPopover) {
    p.close();
  }
}