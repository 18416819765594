import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
@Injectable({
    providedIn: 'root'
  })
  export class AccessGetRequestService {
    private serviceUrl = '';
    configData: any = null;
    constructor(private http: HttpClient, private config: AppConfigService) {
        this.configData = this.config.getConfig();
        this.serviceUrl = this.configData.ServiceUrl;
      }
      public getAllAccessRequest(fromDate, toDate) {
        const url = this.serviceUrl + 'access-getallrequest?fromdate='
        + fromDate + '&todate=' + toDate;
        return this.http.get(url).map(data => {
                return data;
            });
    }
    public getAccessRequestById(accessRequestId) {
      const url = this.serviceUrl  + 'access-getrequestbyid?requestid=' + accessRequestId;
      return this.http.get(url).map(data => {
              return data;
      });
    }
    }
