import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ConfirmationDialogService } from '../../../core/services/confirmation-dialog.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { ToastrService } from 'ngx-toastr';
import { PatternValidator } from '@angular/forms';
import { zipAll } from 'rxjs-compat/operator/zipAll';

@Component({
  selector: 'app-license-new-request',
  templateUrl: './license-new-request.component.html',
  styleUrls: ['./license-new-request.component.css'],
})
export class LicenseNewRequestComponent implements OnInit {
  @Input()
  appName;
  platformSelected = false; applicationTeamName = ''; platform = ''; licenseCategory = '';
  licenseReqType = ''; reqOrg = ''; numReqLicense = 0; appDisable = true;
  arid = ''; approver = ''; wbs = '';
  accNumber = ''; comment = '';
  requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = ''; licenseCategoryValMsg = '';
  licenseReqTypeValMsg = ''; reqOrgValMsg = ''; numReqLicenseValMsg = ''; aridValMsg = ''; approverValMsg = ''; wbsValMsg = '';
  accNumberValMsg = ''; commentValMsg = ''; requestedForValMsg = '';
  loadingShow = false; requestedForList = []; requestedFor=''; requestEid = '';
  count = 0; regex = '[a-zA-Z.]*';
  constructor(private createService: CreateLicenseRequestService, private toastr: ToastrService,
              private confirmationDialogService: ConfirmationDialogService,
              private getLicService: GetLicenseRequestService,
              private getService: GetRequestService) {
    const urlArray = [{ name: 'liscence', path: '/liscence/createrequest' }];
    this.getService.changeUrl(urlArray);
    
  }
  ngOnInit() {
    if (this.appName.Application_Name !== undefined) {
      this.applicationTeamName = this.appName.Application_Name;
      this.getApplicationData(this.applicationTeamName);
    } else {

      this.applicationTeamName = this.appName;
      console.log(this.appName);
    }
  }
  
  getApplicationData(appName) {
    this.loadingShow = true;
    this.getLicService.getApplicationDetails(appName, 'New_Req').subscribe((res: any) => {
      if (res[0].length > 0) {
        console.log(res[0]);
        this.platform = res[0][0].PlatformId;
        this.licenseCategory = res[0][0].LicenseCategory;
        this.licenseReqType = res[0][0].LicenseRequestType;
        this.reqOrg = res[0][0].RequesterOrganizationGroup;
        this.numReqLicense = res[0][0].NumberOfLicenses;
        this.approver = res[0][0].WBSEApprovedBy;
        this.wbs = res[0][0].WBSE;
        this.accNumber = res[0][0].GeneralLedgerNo;
        this.arid = res[0][0].AIRID;
      }
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
    });
  }
  validation() {
    if (this.applicationTeamName === '') {
      this.applicationTeamNameValMsg = 'Please Enter Application Team Name';
      return false;
    }
    if (this.platform === '') {
      this.platformValMsg = 'Please Select Platform';
      return false;
    }
    // if (this.licenseCategory === '') {
    //   this.licenseCategoryValMsg = 'Please Enter License Category';
    //   return false;
    // }
    // if (this.licenseReqType === '') {
    //   this.licenseReqTypeValMsg = 'Please enter license request type';
    //   return false;
    // }
    if (this.reqOrg === '') {
      this.reqOrgValMsg = 'Please Enter Requester' + '\'' + 's Organization / Group';
      return false;
    }
    if (this.numReqLicense === 0) {
      this.numReqLicenseValMsg = 'Please Enter Number of Licenses Requested New/Renewal';
      return false;
    }
    if (this.arid === '') {
      this.aridValMsg = 'Please Enter AIRID';
      return false;
    }
    if (this.approver === '') {
      this.approverValMsg = 'Please Enter WBS Approver Name';
      return false;
    }
    if (this.wbs === '') {
      this.wbsValMsg = 'Please Enter WBS/Cost Collector';
      return false;
    }
    if (this.accNumber === '') {
      this.accNumberValMsg = 'Please Enter General Ledger Account No.';
      return false;
    }
    if (this.requestedForList.length === 0) {
      this.requestedForValMsg = 'Please Enter Requested for EID'; // and then hit "Add"
      return false;
    }
    if (this.comment === '') {
      this.commentValMsg = 'Please Enter Description for Request';
      return false;
    }
    return true;
  }
  saveRequest() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        AppTeamName: this.applicationTeamName,
        PlatformId: this.platform,
        LicenseCategory: this.licenseCategory,
        LicenseRequestType: this.licenseReqType,
        RequesterOrganizationGroup: this.reqOrg,
        AIRID: this.arid,
        NumberOfLicenses: this.numReqLicense,
        WBSE: this.wbs,
        WBSEApprovedBy: this.approver,
        GeneralLedgerNo: this.accNumber,
        Comments: this.comment,
        CreatedDate: null,
        AssignmentDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        Status: null,
        Req_Flag: 'New_Req',
        License_Owner: null,
        New_License_Owner: null,
        HasEmployee_Attachment: null,
        Attachment: null,
        Transfer_Release_Comment: null,
        OpsComment: null,
        EmpList: this.requestedForList,
       
      };
      this.createService.saveData(dataToSend).subscribe(res => {
        this.toastr.success('Request saved successfully.');
        this.clearAllData();
        this.loadingShow = false;
        console.log(dataToSend);
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
  clearAllData() {
    this.platform = ''; this.licenseCategory = '';
    this.licenseReqType = ''; this.reqOrg = ''; this.numReqLicense = 0; this.arid = '';  this.approver = ''; this.wbs = '';
    this.accNumber = ''; this.requestedForList = []; this.comment = '';
  }
  addNames() {
    if (this.platform === '') {
      this.platformValMsg = 'Please select platform';
      this.requestEid = '';
      return false;
    } else if (this.platform !== '' && this.requestEid.trim() === '') {
      this.requestedForValMsg = 'Invalid EID,Please Enter valid EID';
      return false;
    }
     else if (this.requestedForList.some((item) => item.UserEID === this.requestEid)) {
      this.requestedForValMsg = 'Requested EID already present in below list.';
      this.requestEid = '';
      return false;
    } else {
      this.verifyUserId(this.requestEid);
    }
  }
  removeNames(eid) {
    this.requestedForList.forEach((item, index) => {
      if (item === eid) { this.requestedForList.splice(index, 1); }
    });
  }
  showAlert() {
    if (this.requestedForList.length > 0) {
      this.openConfirmationDialog();
    }

  }

  public openConfirmationDialog() {
    this.confirmationDialogService.confirm('Alert', 'Upadating Platform will clear "Requested For" entries. Do you want to proceed?')
      .then((confirmed) => this.recieveFlag(confirmed))
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking' +
        + 'the cross icon, or clicking outside the dialog)'));
  }
  recieveFlag($event) {
    if ($event === true) {
      this.requestedForList = [];
    }
  }
  updateEid() {
    if (this.platform === '') {
      this.platformValMsg = 'Please select platform';
      this.requestEid = '';
      return false;
    }
    return true;
  }
  
  approverEid() {
      this.approver = this.approver.split('@')[0]
      this.approver =  this.approver;
      const checkEmail = this.approver.match(this.regex);   
      if(checkEmail[0] === this.approver)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.approverValMsg=''
      }  
      else
      {
        this.approverValMsg = 'Invalid EID,Please Enter valid EID';
        this.approver = '';
      }  
  }
  

  requestedEid() {
      this.requestEid = this.requestEid.split('@')[0]
      this.requestEid =  this.requestEid;
      const checkEmail = this.requestEid.match(this.regex);   
      if(checkEmail[0] === this.requestEid)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.requestedForValMsg=''
      }  
      else
      {
        this.requestedForValMsg = 'Invalid EID,Please Enter valid EID';
        this.requestEid =  '';
      }  
   }

    
  
  numberOnly($event): boolean {
    const charCode = ($event.which) ? $event.which : $event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  verifyUserId(eid): any {
    this.loadingShow = true;
    let platformId;
    if (this.platform.toString() === '1') {
      platformId = 1001;
    } else {
      platformId = 1002;
    }
    this.getLicService.verifyUser(eid, platformId, 'new').subscribe(res => {
      if (res[0][0].count < 1) {
        const array = { UserEID: this.requestEid, AssignedDate: null, licenseType_Id: null };
        this.requestedForList.push(array);
        this.requestEid = '';
      } else {
        this.requestedForValMsg = 'Request already raised for this user';
      }
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
      this.toastr.error('Some error occured');
      return null;
    });
  } 
  clearAllDatacancel() {
    this.appDisable = false;
    this.applicationTeamName = ''; this.platform = ''; this.licenseCategory = '';
    this.licenseReqType = ''; this.reqOrg = ''; this.numReqLicense = 0; this.arid = '';  this.approver = ''; this.wbs = '';
    this.accNumber = ''; this.requestedForList = []; this.comment = '';
  }
}

