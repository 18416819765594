import { Component, OnInit } from '@angular/core';
import { AccessGetRequestService } from '../../../core/services/access-get-request.service';
import { ViewAccessRequest } from '../../../core/model/access-request.model';
import {ViewRequestType} from '../../../core/services/enums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-access-view-popup',
    templateUrl: './access-view-popup.component.html',
    styleUrls: ['./access-view-popup.component.css']
  })
  export class AccessViewPopupComponent implements OnInit {
    accessRequestId;
    platfromURL;
    platform;
    accessRequestDetails = new ViewAccessRequest();
    loadingShow = false;
    addRequest = false;
    RequestTypeEnum = ViewRequestType;
    enumKeys = [];
    constructor(private accessGetRequestService: AccessGetRequestService, public activeModal: NgbActiveModal) {
    }
    ngOnInit() {
      this.getRequestDetails(this.accessRequestId);
    }

    getRequestDetails(accessRequestId) {
      this.loadingShow = true;
      this.accessGetRequestService.getAccessRequestById(accessRequestId).subscribe(res => {
      this.accessRequestDetails = res[0][0];
      this.accessRequestDetails.RequestTypeId = Number(this.RequestTypeEnum[this.accessRequestDetails.RequestType]);
      if (this.accessRequestDetails.RemovedADGroups === '') {
        this.addRequest = true;
      }
      console.log('retrived access request details 1');
      console.log(this.accessRequestDetails);
      console.log(this.addRequest);
      console.log('retrived access request details 2');
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
     });
    }

  }
