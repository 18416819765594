import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.css']
})
export class FileDetailsComponent implements OnInit {
  @Input()
  inputRowdata;
  @Output() passData: EventEmitter<any> = new EventEmitter();
  report = ''; description = ''; dataSource = ''; keyMetrics = ''; dsother = ''; dataSourceArray = [];
  refreshFreq = ''; release = new Date(); timeRefreshSchedule = '';
  user = 0; concurrentUser = 0;
  dataVolume = 0; yearToMaintain = ''; other = '';
  reportValMsg = ''; descriptionValMsg = ''; dataSourceValMsg = '';
  keyMetricsValMsg = ''; refreshFreqValMsg = ''; releaseValMsg = ''; timeRefreshScheduleValMsg = '';
  userValMsg = ''; concurrentUserValMsg = '';
  dataVolumeValMsg = ''; yearToMaintainValMsg = '';
  txtfilname = []; txtBody: any; allDataArray = []; reportDetails;
  ascending = false;
  ascendingreport = false;
  sendDetailsValMsg = '';
  constructor(public activeModal: NgbActiveModal, private datepipe: DatePipe) {

  }
  rowData = [];

  ngOnInit() {
    if (this.inputRowdata.length > 0) {
      this.rowData = this.inputRowdata[0].details;
      this.txtfilname = this.inputRowdata[0].filename;
      this.txtBody = this.inputRowdata[0].fileBody;
    }
  }
  validation() {
    if (this.report === '') {
      this.reportValMsg = 'Please Enter Value for Report';
      return false;
    }
    if (this.description === '') {
      this.descriptionValMsg = 'Please Enter description';
      return false;
    }
    if (this.dataSource === '') {
      this.dataSourceValMsg = 'Please Select Data Source';
      return false;
    }
    // if (this.keyMetrics === '') {
    //   this.keyMetricsValMsg = 'Please Enter key metrics';
    //   return false;
    // }
    if (this.refreshFreq === '' || (this.refreshFreq === 'Other' && this.other === '')) {
      this.refreshFreqValMsg = 'Please Enter Refresh Frequency';
      return false;
    }
    if (this.timeRefreshSchedule === '') {
      this.timeRefreshScheduleValMsg = 'Please Enter Refresh Frequency';
      return false;
    }
    if (this.user === 0) {
      this.userValMsg = 'Please Enter Total Tumber of Users';
      return false;
    }
    if (this.concurrentUser === 0) {
      this.concurrentUserValMsg = 'Please Select Total Number of Concurrent Users';
      return false;
    }
    if (this.yearToMaintain === '') {
      this.yearToMaintainValMsg = 'Please Select Period of Data Planned to Retain';
      return false;
    }
    if (this.dataVolume === 0) {
      this.dataVolumeValMsg = 'Please Enter Volume of Data';
      return false;
    }
    return true;
  }
  addRecord() {
    if (this.validation()) {
      const releasedate = this.datepipe.transform(this.release, 'yyyy-MM-dd');
      const details = {
        reports: this.report, dataSource: this.dataSource.split(','), description: this.description,
        keyMetrics: this.keyMetrics,
        refreshFreq: this.refreshFreq, timeRefreshSchedule: this.timeRefreshSchedule,
        release: this.release, users: this.user, concurrentUser: this.concurrentUser,
        VolumeOfData: this.dataVolume, DataRetainPeriod: this.yearToMaintain, Other: this.other

      };
      this.rowData.push(details);
      this.clearData();
    }
  }
  selectFile(event) {
    this.txtBody = [];
    this.txtfilname = [];
    if (event.target.files.length > 0) {
      for (let i = 0; event.target.files.length > i; i++) {
        this.txtfilname.push(event.target.files[i].name);
        this.txtBody.push(event.target.files.item(i));
      }
      // this.txtfilname = event.target.files[0].name;
      // this.txtBody = event.target.files.item(0);
    } else {

    }
  }
  sendDetailsvalidation() {

    const gridcount = this.rowData.length;
    console.log(gridcount);
    // var allTableData = (<HTMLTableElement>document.getElementById("records"));
    // var totalNumbeOfRows = allTableData.rows.length;
    // console.log(totalNumbeOfRows);
    if (gridcount > 0) {
      return true;
    } else {
        this.sendDetailsValMsg = 'Please add atleast one Record';
        return false;
      }
  }
  sendDetails() {
    if (this.sendDetailsvalidation()) {
      const data = { details: this.rowData, filename: this.txtfilname, fileBody: this.txtBody };
      this.allDataArray.push(data);
      this.passData.emit(this.allDataArray);
      this.activeModal.close();
  }
  }
  clearData() {
    this.report = ''; this.description = ''; this.dataSource = ''; this.dataSourceArray = [];
    this.keyMetrics = ''; this.refreshFreq = ''; this.release = new Date();
    this.timeRefreshSchedule = '';
    this.user = 0; this.concurrentUser = 0;
    this.dataVolume = 0; this.yearToMaintain = '';
  }
  sortDateColumn() {
    this.ascending = !this.ascending;
    if (this.ascending) {

      return this.rowData.sort((a, b) => {
        const todate = new Date(b.release);
        const from = new Date(a.release);
        return todate.valueOf() - from.valueOf();
      }); // For ascending sort
    } else {
      return this.rowData.sort((a, b) => {
        const todate = new Date(a.release);
        const from = new Date(b.release);
        return todate.valueOf() - from.valueOf();
      }); // For descending sort
    }
  }

  sortReportColumn() {
    this.ascendingreport = !this.ascendingreport;
    if (this.ascendingreport) {

      return this.rowData.sort((a, b) => {
        if (b.reports > a.reports) {
          return 1;
        } else {
          return -1;
        }
      }); // For ascending sort
    } else {
      return this.rowData.sort((a, b) => {
        if (b.reports < a.reports) {
          return 1;
        } else {
          return -1;
        }
      }); // For descending sort
    }
  }
  closePopOver(p: NgbPopover) {
    p.close();
  }
  checkValue(p: NgbPopover, o: NgbPopover) {
    this.dataSource = '';
    const blankSpace = this.dataSourceArray.indexOf('') > -1 ? true : false;
    if (this.dataSourceArray.indexOf('') > -1) {
    this.dataSourceArray.splice(this.dataSourceArray.indexOf(''));
    }
    this.dataSourceArray.forEach((val, index) => {
      console.log(index);
      if (val !== '') {
        if ((index + 1 ) === this.dataSourceArray.length) {
          this.dataSource = this.dataSource + val;
        } else {
          this.dataSource = this.dataSource + val + ',';
        }
      }
    });
    if (blankSpace) {
      p.close();
      o.open();
    } else {

    }
  }
  addOtherDataSource(o: NgbPopover) {
      this.dataSource = this.dataSource === '' ? this.dataSource + this.dsother : this.dataSource + ',' + this.dsother;
      o.close();
  }
  dropdownChange(p: NgbPopover, val) {
    if (val === 'Other') {
      p.open();
    } else {
      p.close();
    }
  }
  refreshFocus(p: NgbPopover) {
    if (this.refreshFreq === 'Other') {
      p.open();
    } else {
      p.close();
    }
    this.refreshFreqValMsg = '';
  }
}
