
import { BrowserModule } from '@angular/platform-browser';
import {  NO_ERRORS_SCHEMA, APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { HeaderComponent } from './shared/header/header.component';
import { MsalModule } from '@azure/msal-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MenuComponent } from './shared/menu/menu.component';
import { MatInputModule } from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { UploaddocumentModule } from './feature/create-request/create-request.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import { ToastrModule } from 'ngx-toastr';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { NewRequestComponent } from './feature/create-request/new-request/new-request.component';
import { AgGridModule } from 'ag-grid-angular';
import { AllRequestComponent } from './feature/request-list/all-request/all-request.component';
import { ApproveButtonComponent } from './feature/request-list/approve-button/approve-button.component';
import { ApproveRequestComponent } from './feature/create-request/approve-request/approve-request.component';
import { FileDetailsComponent } from './feature/create-request/file-details/file-details.component';
import { ViewRequestComponent } from './feature/request-list/view-request/view-request.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { LicenseManageComponent } from './feature/license-management/license-manage/license-manage.component';
import { LicenseAllRequestComponent } from './feature/license-management/license-all-request/license-all-request.component';
import { LicenseNewRequestComponent } from './feature/license-management/license-new-request/license-new-request.component';
import { LicenseManageListComponent } from './feature/license-management/license-manage-list/license-manage-list.component';
import { LicenseManagePoolComponent } from './feature/license-management/license-manage-pool/license-manage-pool.component';
import { ActionButtonRequestComponent } from './feature/license-management/action-button/action-button.component';
import { LicenseApprovalPopUpComponent } from './feature/license-management/license-approval-popup/license-approval-popup.component';
import { ViewButtonRequestComponent } from './feature/license-management/view-button/view-button.component';
import { LicenseTransferPopUpComponent } from './feature/license-management/license-transfer-popup/license-transfer-popup.component';
import { LicenseReleasePopUpComponent } from './feature/license-management/license-release-popup/license-release-popup.component';
import { AddLicensePopUpComponent } from './feature/license-management/add-license-popup/add-license-popup.component';
import { ReportDetailsButtonComponent } from './feature/request-list/report-details-button/report-details-button.component';
import { ReportDetailsComponent } from './feature/request-list/report-details/report-details.component';
import { LicenseRequestComponent } from './feature/license-management/license-request/license-request.component';
import { LicenseTransferRequestComponent } from './feature/license-management/license-transfer-request/license-transfer-request.component';
import { LicenseReleaseRequestComponent } from './feature/license-management/license-release-request/license-release-request.component';
import { ConfirmationDialogComponent } from './feature/license-management/confirmation-dialog/confirmation-dialog.component';
import { LicenseViewPopupComponent } from './feature/license-management/license-view-popup/license-view-popup.component';
import { LicensePoolPopupComponent } from './feature/license-management/license-pool-popup/license-pool-popup.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LicenseDashboardComponent } from './feature/license-management/license-dashboard/license-dashboard.component';
import { LicenseOperationComponent } from './feature/license-management/license-operation/license-operation.component';
import {AccessDashboardComponent} from './feature/access-management/access-dashboard/access-dashboard.component';
import {AccessRequestComponent } from './feature/access-management/access-request/access-request.component';
import {StreamLevelAccessComponent } from './feature/access-management/stream-level-access/stream-level-access.component';
import {StreamAdminAccessComponent} from './feature/access-management/stream-admin-access/stream-admin-access.component';
import {AppLevelSecurityComponent } from './feature/access-management/app-level-security/app-level-security.component';
import {StreamLevelAdgroupComponent} from './feature/access-management/stream-level-adgroup/stream-level-adgroup.component';
import {StreamLevelFileshareComponent} from './feature/access-management/stream-level-fileshare/stream-level-fileshare.component';
import {AccessAllRequestComponent} from './feature/access-management/access-all-request/access-all-request.component';
import {AccessViewPopupComponent} from './feature/access-management/access-view-popup/access-view-popup.component';
import {AccessViewButtonComponent} from './feature/access-management/access-view-button/access-view-button.component';
import {AccessOPSReviewButtonComponent} from './feature/access-management/access-ops-review-button/access-ops-review-button.component';
import {AccessOPSReviewPopupComponent} from './feature/access-management/access-ops-review-popup/access-ops-review-popup.component';
import { AccessManageRequestComponent } from './feature/access-management/access-manage-request/access-manage-request.component';
import { LicenseQlikviewallComponent } from './feature/license-management/license-qlikviewall/license-qlikviewall.component';
import { DownloadButtonRequestComponent } from './feature/license-management/download-button/download-button.component';
import { DownloadLicensePopupComponent } from './feature/license-management/download-license-popup/download-license-popup.component';
import { EditButtonComponent } from './feature/license-management/edit-button/edit-button.component';
import { DatePipe } from '@angular/common';
import { LicenseDetailsEditButtonComponent
} from './feature/license-management/license-details-edit-button/license-details-edit-button.component';
import { LicenseDetailsEditPopupComponent
} from './feature/license-management/license-details-edit-popup/license-details-edit-popup.component';
import { LicenseAssignedComponent } from './feature/license-management/license-assigned/license-assigned.component'
import { CancelButtonComponent } from './feature/license-management/cancel-button/cancel-button.component';
import { CancelReviewComponent } from './feature/license-management/cancel-review/cancel-review.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { GridCheckboxComponent } from './feature/license-management/grid-checkbox/grid-checkbox.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HeaderComponent,
        MenuComponent,
        AllRequestComponent,
        ApproveButtonComponent,
        ViewRequestComponent,
        DashboardComponent,
        LicenseManageComponent,
        LicenseAllRequestComponent,
        LicenseNewRequestComponent,
        LicenseManageListComponent,
        LicenseManagePoolComponent,
        ActionButtonRequestComponent,
        LicenseApprovalPopUpComponent,
        ViewButtonRequestComponent,
        LicenseTransferPopUpComponent,
        LicenseReleasePopUpComponent,
        AddLicensePopUpComponent,
        ReportDetailsButtonComponent,
        ReportDetailsComponent,
        LicenseRequestComponent,
        LicenseTransferRequestComponent,
        LicenseReleaseRequestComponent,
        ConfirmationDialogComponent,
        LicenseViewPopupComponent,
        LicensePoolPopupComponent,
        LicenseDashboardComponent,
        LicenseOperationComponent,
        AccessDashboardComponent,
        AccessRequestComponent,
        StreamLevelAccessComponent,
        StreamAdminAccessComponent,
        AppLevelSecurityComponent,
        StreamLevelAdgroupComponent,
        StreamLevelFileshareComponent,
        AccessAllRequestComponent,
        AccessViewPopupComponent,
        AccessViewButtonComponent,
        AccessOPSReviewButtonComponent,
        AccessOPSReviewPopupComponent,
        AccessManageRequestComponent,
        LicenseQlikviewallComponent,
        DownloadButtonRequestComponent,
        DownloadLicensePopupComponent,
        EditButtonComponent,
        LicenseDetailsEditButtonComponent,
        LicenseDetailsEditPopupComponent,
        LicenseAssignedComponent,
        CancelButtonComponent,
        CancelReviewComponent,
        GridCheckboxComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    imports: [
        NgbModule, BrowserModule, FormsModule, MatRadioModule, InlineSVGModule, AutocompleteLibModule, MatTableModule,
        MatSidenavModule, MatStepperModule, AgGridModule.withComponents([]),
        MatAutocompleteModule, ReactiveFormsModule, ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            closeButton: true,
            progressBar: true,
            maxOpened: 100,
            autoDismiss: true,
            enableHtml: true
        }),
        HttpClientModule, routing, MatTabsModule, MatDatepickerModule, MatFormFieldModule,
        MatPaginatorModule, MatIconModule, MatNativeDateModule, MatRippleModule, MatButtonModule,
        UploaddocumentModule, RebarAuthModule.forRoot(), BrowserAnimationsModule, MatInputModule, AngularTreeGridModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService], multi: true
        }, MsalModule, DatePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
