import { Component, OnInit } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-license-manage',
  templateUrl: './license-manage.component.html',
  styleUrls: ['./license-manage.component.css']
})
export class LicenseManageComponent implements OnInit {
  private manageType = 'ManageLicenses';
  userType = '';
  qlikSenseData = ''; qlikViewData = ''; showDiv = true;
  setRadio(e: string): void {
        this.manageType = e;
  }
  isSelected(manageLicense: string): boolean {
    return (this.manageType === manageLicense);
  }
  constructor(private getService: GetRequestService, private getLicenseService: GetLicenseRequestService) {
    const urlArray = [{ name: 'Request New License', path: '/license/createrequest' }];
    this.getService.changeUrl(urlArray);
    this.userType = localStorage.getItem('userType');
    this.showDiv = this.userType === 'OPS' ? true : false;
   }

  ngOnInit() {
    this.getLicenseCount();
  }
  getLicenseCount() {
    this.getLicenseService.getLicenseCount().subscribe(res => {
      console.log(res);
      res[0].forEach(row => {
          this.qlikSenseData = row.LicenseCount + '/' + row.Total;
      });
    }, error => {
      console.log(error);
    });
  }
}
