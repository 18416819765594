import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GetRequestService } from '../../core/services/get-request.service';

import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  appOnboardingUrl = '';
  showDiv = true;
  disableApp = true;
  disbleLicense = true;
  subscription;
  interval;
  count = 0;
  user = [];
  userName = '';
  loadingShow = false;
  disableAccess = true;
  showOther = true;
  frameworkComponents: any;
  constructor(private router: Router, private toaster: ToastrService, private getService: GetRequestService,
              private zone: NgZone, private modalService: NgbModal, private location: Location) {
    const urlArray = [{ name: '', path: '' }];
    getService.changeUrl(urlArray);
    debugger;
    this.subscription = getService.userType.subscribe(userData => {
      this.zone.run(() => {
        this.userName = userData[0].EID;
      });
    });
  }
  ngOnInit() {
    debugger;
    if (this.location.path().indexOf('chatbot/faq') > -1) {
      this.showOther = false;
     // this.openChatPopUp();
    }
  }

  nevigate1(url) {
    // this.router.navigate([url]);
  }
  nevigate(app) {
      this.loadingShow = true;
      this.count = 1;
      this.getService.getRole().take(1).subscribe(data => {
        this.loadingShow = false;
        if (app === 'app') {
          if (data[0].length > 0) {
          (data[0][0].role === 'App' || data[0][0].role === 'Ops') ?
          this.appOnboardingUrl = 'applicationonbording/request' :
            this.appOnboardingUrl = 'applicationonbording/request';
          } else {
            this.appOnboardingUrl = 'applicationonbording/request';
          }
          this.router.navigate([this.appOnboardingUrl]);
          } else if (app === 'license') {
            this.appOnboardingUrl = 'license/dashboard';
            this.router.navigate([this.appOnboardingUrl]);
          } else if (app === 'access') {
            this.appOnboardingUrl = 'access/dashboard';
            this.router.navigate([this.appOnboardingUrl]);
          } else if (app === 'chatBotPopUp') {
            console.log('Routing to chat pop-up component');
            this.openChatPopUp();
          }
        localStorage.setItem('permissions', JSON.stringify(data[0]));
      },
        error => {
          this.loadingShow = false;
          this.toaster.error('Some error occured, please try again.');
          console.log(error);
        });
  }
  openChatPopUp() {
    this.popupwindow('https://baas.accenture.com/embed?baasid=8dd7e2b8-18d7-414a-b473-09747a7872ea&wmode=transparent',
     600, 400);
 // return false;d
  }
  popupwindow(url, w, h) {
    const left = (screen.width / 2) - (w / 2);
    const top = (screen.height / 2) - (h / 2);
    const prm = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width='
     + w + ', height=' + h + ', top=' + top + ', left=' + left;
    return window.open(url, 'targetWindow', prm);
  }
}
