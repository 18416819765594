import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GetRequestService } from '../../core/services/get-request.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, AfterViewInit {
  header = 'Analytics Platform Application Management System';
  homeLink = '/';
  showOther = true;
  constructor(private getService: GetRequestService, private router: Location) {
    this.getService.currentUrl.subscribe(url => {
      if (this.router.path() === '') {
        this.showOther = true;
        this.header = 'Analytics Platform Application Management System';
        } else if (this.router.path().indexOf('applicationonbording') > -1) {
          this.showOther = true;
          this.header = 'Application Onboarding';
          this.homeLink = '/';
        } else if (this.router.path().indexOf('license') > -1) {
          this.showOther = true;
          this.header = 'License Management';
          if (this.router.path().indexOf('dashboard') > -1) {
            this.homeLink = '/';
          } else {
            this.homeLink = 'license/dashboard';
          }
         } else if (this.router.path().indexOf('access') > -1) {
          this.showOther = true;
          this.header = 'Access Management';
          if (this.router.path().indexOf('dashboard') > -1) {
            this.homeLink = '/';
          } else {
            this.homeLink = 'access/dashboard';
          }
         } else if (this.router.path().indexOf('chatbot') > -1) {
          this.showOther = false;
          this.header = 'Analytics Platform Application FAQ Chat Bot';
        }
     });
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
   // document.getElementById('home').setAttribute('class', 'nav-link active');
  }

}
