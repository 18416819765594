import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { ActionButtonRequestComponent } from '../action-button/action-button.component';
import { ViewButtonRequestComponent } from '../view-button/view-button.component';
import { LicenseViewPopupComponent } from '../license-view-popup/license-view-popup.component';
import { LicenseTransferPopUpComponent } from '../license-transfer-popup/license-transfer-popup.component';
import { LicenseReleasePopUpComponent } from '../license-release-popup/license-release-popup.component';
import { LicenseApprovalPopUpComponent } from '../license-approval-popup/license-approval-popup.component';
import {GetLicenseRequestService} from '../../../core/services/get-license-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
import {DatePipe} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CancelReviewComponent } from '../cancel-review/cancel-review.component';
import { CancelButtonComponent } from '../cancel-button/cancel-button.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-license-manage-list',
  templateUrl: './license-manage-list.component.html',
  styleUrls: ['./license-manage-list.component.css']
})
export class LicenseManageListComponent implements OnInit {
  columnDefs;
  userType = '';
  filterData: any;
  frameworkComponents: any;
  toDate = new Date();
  fromDate = new Date(this.toDate.getFullYear(), (this.toDate.getMonth()), this.toDate.getDate() - 30);
  defaultColDef = { resizable: true };
  fromval = ''; toval = '';
  rowData = []; loadingShow = false;
  @Output() countChange = new EventEmitter();
  constructor(private modalService: NgbModal,  private getLicenseService: GetLicenseRequestService,
              private getLicService: GetLicenseRequestService,
              private getService: GetRequestService,  private datepipe: DatePipe, private toastr: ToastrService ) {
    this.frameworkComponents = {
      cancel : CancelButtonComponent,
      action: ActionButtonRequestComponent,
      view : ViewButtonRequestComponent,
    };
    this.userType = localStorage.getItem('userType');
    console.log(this.userType);
    this.columnDefs = [
      {headerName: 'ID', field: 'Req_ID', width: 80},
      {headerName: 'Platform', field: 'Platform', width: 100, filter: 'agTextColumnFilter'},
      {headerName: 'Team Name', field: 'AppTeamName', width: 160 , filter: 'agTextColumnFilter'},
      {headerName: 'Requested By', field: 'RequesterName', width: 160, filter: 'agTextColumnFilter'},
      {headerName: 'Created On', field: 'CreatedDate', width: 110 , cellRenderer: (data) => {
        return this.datepipe.transform(data.value, 'yyyy-MM-dd');
      }},
      {headerName: 'Request Type', field: 'Req_Flag', width: 110 , filter: 'agTextColumnFilter',
      cellRenderer: (data) => {
        return data.value === 'New_Req' ? 'Procure' : data.value;
      }
    },
    {headerName: 'Manager Status', field: 'ManagersApproval', width: 130 ,
      filter: 'agTextColumnFilter', cellClass: (data) => this.statusColor(data.value) },
      {headerName: 'Ops Status', field: 'Status', width: 130 ,
      filter: 'agTextColumnFilter', cellClass: (data) => this.statusColor(data.value) },
        {headerName: 'OPS Approval', width: 100, cellRenderer: 'action',
        cellRendererParams: {
          onClick: this.licenseAction.bind(this),
          label: 'OPS Approval'
        }, cellClass: 'grid-cell-centered', hide: this.userType !== 'OPS' },
        {headerName: 'Manager Approval', width: 100, cellRenderer: 'action',
        cellRendererParams: {
          onClick: this.managerAction.bind(this),
          label: 'Manager Approval'
        }, cellClass: 'grid-cell-centered', hide: this.userType !== 'Manager' },
        {headerName: 'Cancel', width: 80, cellRenderer: 'cancel',
        cellRendererParams: {
          onClick: this.licenseRequestCancel.bind(this),
          label: 'Cancel'
        }, cellClass: 'grid-cell-centered', },
        {headerName: 'View', width: 89, cellRenderer: 'view',
        cellRendererParams: {
          onClick: this.licenseView.bind(this),
          label: 'View'
        }, cellClass: 'grid-cell-centered' }
        ];
  }


  licenseRequestCancel(e) {
    console.log(e);
    const modelRef = this.modalService.open(CancelReviewComponent,  { size: 'medium' });
    modelRef.componentInstance.isCancel = 1;
     modelRef.componentInstance.RequestId = e.rowData.Req_ID ; 
     modelRef.result.then((result) => {
      const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
      const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
     this.getAllLicenseRequest(fromInput, to);
      //console.log(this.fromDate+ "---" + this.toDate);
    });
     //modelRef.closed.subscribe(res=>{

     // setTimeout(() =>{ this.reportList() }, 1000);

    //})
  }


  licenseAction(e) {
    if (e.rowData.Req_Flag === 'New_Req' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseApprovalPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
          this.getAllLicenseRequest(this.fromval, this.toval);
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          console.log('Here to emiit')
          this.countChange.emit();
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
    if (e.rowData.Req_Flag === 'Transfer' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseTransferPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
    if (e.rowData.Req_Flag === 'Release' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseReleasePopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
  }
  managerAction(e) {
    if (e.rowData.Req_Flag === 'New_Req' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseApprovalPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
          this.getAllLicenseRequest(this.fromval, this.toval);
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
    if (e.rowData.Req_Flag === 'Transfer' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseTransferPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
    if (e.rowData.Req_Flag === 'Release' && e.rowData.Status === 'Pending') {
      const modelRef = this.modalService.open(LicenseReleasePopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 3;
      modelRef.componentInstance.userType = this.userType;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
      modelRef.componentInstance.passData.subscribe((res) => {
        if (res === 'f') {
          this.toastr.error('Some Error Occured');
        } else if (res === 's') {
          this.getAllLicenseRequest(this.fromval, this.toval);
          this.toastr.success('Record updated successfully.');
        } else {
        }
      });
    }
  }
  licenseView(e) {
    if (e.rowData.Req_Flag === 'New_Req') {
      const modelRef = this.modalService.open(LicenseViewPopupComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Transfer') {
      const modelRef = this.modalService.open(LicenseTransferPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Release') {
      const modelRef = this.modalService.open(LicenseReleasePopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
  }
  ngOnInit() {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    this.toval = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllLicenseRequest(this.fromval, this.toval);
  }
  searchByDate() {
    const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllLicenseRequest(fromInput, to);
    }

  setFromDate() {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    console.log(this.fromval);
  }
  setToDate() {
    this.toval = this.toDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    console.log(this.toval);
  }
  getAllLicenseRequest(fromDate, toDate) {
    this.loadingShow = true;
    this.getLicService.getAllRequest(fromDate, toDate, this.userType)
    .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
    .subscribe(
      data1 => {
        this.rowData = data1[0];
        this.loadingShow = false;
      },
      error => {
        this.loadingShow = false;
        this.rowData = [];
        console.log(error);
      }
    );
  }
  statusColor(status) {
    return (status === 'Approved' || status === 'Released') ? 'enableColor' : (status === 'Rejected' ? 'cancelColor' : (status === 'Pending' ? 'pendingColor' : 'pendingColor' ));
  }
    getRole(app) {
      this.loadingShow = true;
      this.getService.getRole().take(1).subscribe(res => {
        this.loadingShow = false;
        if (res[0].length > 0) {
          if (res[0][0].role === 'Ops') {
          this.userType = 'OPS';
          } else {
            this.userType = 'Manager';
          }
        } else {
          this.userType = 'Manager';
        }
      },
        error => {
          this.loadingShow = false;
          console.log(error);
        });

  }
}
