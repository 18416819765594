import { Component, OnInit } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-license-dashboard',
  templateUrl: './license-dashboard.component.html',
  styleUrls: ['./license-dashboard.component.css']
})
export class LicenseDashboardComponent implements OnInit {
  loadingShow = false;
  allowApproval = false;
  allowOperation = false;
  allowlicenseuser = false;
  disableApp = true;

  constructor(private getService: GetRequestService, private getLicenseReq: GetLicenseRequestService) {
    const urlArray = [{ name: 'dashboard', path: 'license/dashboard' }];
    getService.changeUrl(urlArray);
  }

  ngOnInit() {
    this.loadingShow = true;
    this.getService.getRole().subscribe(res => {
      console.log(res);
      if (res[0].length > 0) {
        // write condition here
        if (res[0][0].role === 'Ops') {
          if (res[1][0].LicensedUserCount > 0) {
            this.allowlicenseuser = true;
          } else {
            this.allowlicenseuser = false;
          }
          this.allowApproval = true;
          this.allowOperation = true;
          this.loadingShow = false;
          localStorage.setItem('userType', 'OPS');
        } else {
          this.allowOperation = false;
          if (res[1][0].LicensedUserCount > 0) {
            this.allowlicenseuser = true;
          } else {
            this.allowlicenseuser = false;
          }
          this.managerApproval();
        }
      } else {
        if (res[1][0].LicensedUserCount > 0) {
          this.allowlicenseuser = true;
        }
        this.managerApproval();
      }
    }, error => {
      this.loadingShow = false;
      console.log(error);
    });
  }
  managerApproval() {
    this.getLicenseReq.getRequestCount().subscribe(res => {
      if (res[0][0].ManagersApprovalPending > 0) {
        this.allowApproval = true;
        localStorage.setItem('userType', 'Manager');
      } else {
        localStorage.setItem('userType', 'App');
      }
      this.loadingShow = false;
    }, error2 => {
      this.loadingShow = false;
      console.log(error2);
    });
  }
  nevigate(app) {
    this.loadingShow = true;
    this.getService.getRole().take(1).subscribe(data => {
      this.loadingShow = false;
      if (data[0].length > 0) {

      } else {

      }
    },
      error => {
        this.loadingShow = false;
        console.log(error);
      });
  }

}
