import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { DataService } from '../../../core/services/DataService';
import {DropDownRequestType} from '../../../core/services/enums';
import {Environment} from '../../../core/services/enums';
import { AccessRequestData } from '../../../core/model/access-request.model';
import { AccessRequestService } from '../../../core/services/access-request.service';
@Component({
  selector: 'app-stream-level-access',
  templateUrl: './stream-level-access.component.html',
  styleUrls: ['./stream-level-access.component.css'],
})
export class StreamLevelAccessComponent implements OnInit {
  RequestTypeEnum = DropDownRequestType;
  EnvironmentEnum = Environment;
  environmentname = '';
  selectedstreamName = '';
  enumKeys = [];

  constructor(private getDataService: DataService , private accessRequestService: AccessRequestService) {
    this.enumKeys =  Object.keys( this.RequestTypeEnum ).filter(r => !isNaN(Number(r)));
    }

@Input() DetailsFromAccessRequest: AccessRequestData ;
@Output() backtoRaiserequest: EventEmitter<any> = new EventEmitter();
selectedRequestType = '0';
RequestTypes;

  ngOnInit() {
    console.log( this.DetailsFromAccessRequest);
    if ( this.DetailsFromAccessRequest.Environment === this.EnvironmentEnum.Production.toString()) {
       this.enumKeys = this.enumKeys.filter(e => e === '3' );
    }


    this.getRequestTypes();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    console.log('chenge event');
    console.log(changes);
    this.environmentname = this.DetailsFromAccessRequest.Environment;
    this.selectedstreamName = this.DetailsFromAccessRequest.selectedstreamName;
  }
  OnChange() {
    console.log(this.selectedRequestType);
    this.DetailsFromAccessRequest.RequestTypeId =  this.RequestTypeEnum[this.selectedRequestType] ;
  }


  getRequestTypes() {
    this.getDataService.getRequestTypes ().subscribe(res => {
      this.RequestTypes = res;
    }, error => {
      console.log(error);
    });
  }
//   backtoraiseRequest(event){
// this.backtoRaiserequest.emit(true);
// this.selectedRequestType='0';
//   }

}
