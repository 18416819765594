import { Component, OnInit } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-access-dashboard',
  templateUrl: './access-dashboard.component.html',
  styleUrls: ['./access-dashboard.component.css']
})
export class AccessDashboardComponent implements OnInit {
  loadingShow = false;
  allowApproval = false;
  allowOperation = false;
  disableApp = true;

  constructor(private getService: GetRequestService, private getLicenseReq: GetLicenseRequestService) {
    const urlArray = [{ name: 'dashboard', path: 'access/dashboard' }];
    getService.changeUrl(urlArray);
  }

  ngOnInit() {
    this.loadingShow = true;
    this.getService.getRole().subscribe(res => {
      if (res[0].length > 0) {
        if (res[0][0].role === 'Ops') {
          this.allowApproval = true;
          this.allowOperation = true;
          this.loadingShow = false;
          localStorage.setItem('userType', 'OPS');
        } else {
          this.allowOperation = false;
          this.managerApproval();
        }
      } else {
        this.managerApproval();
      }
    }, error => {
      this.loadingShow = false;
      console.log(error);
    });
  }
  managerApproval() {
    this.getLicenseReq.getRequestCount().subscribe(res => {
      if (res[0][0].ManagersApprovalPending > 0) {
        // debugger;
        this.allowApproval = true;
        localStorage.setItem('userType', 'Manager');
      } else {
        localStorage.setItem('userType', 'App');
      }
      this.loadingShow = false;
    }, error2 => {
      this.loadingShow = false;
      console.log(error2);
    });
  }
  nevigate(app) {
    this.loadingShow = true;
    this.getService.getRole().take(1).subscribe(data => {
      this.loadingShow = false;
      if (data[0].length > 0) {

      } else {

      }
    },
      error => {
        this.loadingShow = false;
        console.log(error);
      });
  }

}
