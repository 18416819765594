import { Component, OnInit } from '@angular/core';

import { AccessGetRequestService } from '../../../core/services/access-get-request.service';
import {AccessViewPopupComponent } from '../access-view-popup/access-view-popup.component';
import {AccessViewButtonComponent} from '../access-view-button/access-view-button.component';
import {AccessOPSReviewPopupComponent } from '../access-ops-review-popup/access-ops-review-popup.component';
import {AccessOPSReviewButtonComponent} from '../access-ops-review-button/access-ops-review-button.component';
import {DatePipe} from '@angular/common';
import { GetRequestService } from '../../../core/services/get-request.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-access-all-request',
    templateUrl: './access-all-request.component.html',
    styleUrls: ['./access-all-request.component.css']
  })
  export class AccessAllRequestComponent implements OnInit {
    accessLinks = [{name: 'Request List', path: '/access/allrequest', show: true },
    {name: 'New Request', path: '/access/raiserequest', show: true  }];
  accessactiveLink = this.accessLinks[0];
    loadingShow = false;
    rowData = [];
    columnDefs;
    defaultColDef = { resizable: true };
    frameworkComponents: any;
    toDate = new Date(); fromDate = new Date(this.toDate.getFullYear(), (this.toDate.getMonth()), this.toDate.getDate() - 30);
    fromval = '';
    toval = '';
    isOpsHide = true;
    ngOnInit() {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    this.toval = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllAccessRequests(this.fromval, this.toval);
    }
    constructor(private accessGetRequestService: AccessGetRequestService,  private service: GetRequestService,
                private modalService: NgbModal , private datepipe: DatePipe) {
      const urlArray = [{ name: 'request', path: '/access/allrequest' }];
      this.service.changeUrl(urlArray);
      this.service.currentUrl.subscribe(url => {
       this.getRole();
      });
      service.userType.subscribe(userData => {
      // this.userName = userData[0].EID;
      });

      this.frameworkComponents = {
        view : AccessViewButtonComponent,
        review : AccessOPSReviewButtonComponent
        };
    }

    getRole() {
      this.service.getRole().take(1).subscribe(data1 => {
        if (data1[0].length > 0) {
          this.isOpsHide = true;
          data1[0][0].role === 'Ops' ? this.isOpsHide = false : this.isOpsHide = true ;
        } else {
          this.isOpsHide = true;
        }
        this.columnDefs = [
          {headerName: 'ID', field: 'AccessRequestId', width: 80},
          {headerName: 'Platform', field: 'Platform', width: 120, filter: 'agTextColumnFilter'},
          {headerName: 'Environment', field: 'Environment', width: 135, filter: 'agTextColumnFilter' },
          {headerName: 'Platform URL', field: 'PlatformURL', width: 120,  filter: 'agTextColumnFilter'},
          {headerName: 'Stream Name', field: 'AppName', width: 190,  filter: 'agTextColumnFilter'},
          {headerName: 'Request Type', field: 'RequestType', width: 220,  filter: 'agTextColumnFilter'},
          {headerName: 'Status', field: 'Status', width: 110, filter: 'agTextColumnFilter',
          cellClass: (data) => this.statusColor(data.value)},
          {headerName: 'Created On', field: 'CreatedDate', width: 130 , cellRenderer: (data) => {
            return this.datepipe.transform(data.value, 'yyyy-MM-dd');
           }},
           {headerName: 'View', width: 90, cellRenderer: 'view',
          cellRendererParams: {
            onClick: this.accessRequestView.bind(this),
            label: 'View'
          }, cellClass: 'grid-cell-centered' },
          //  {headerName: 'OPS Review', width: 80, cellRenderer: 'review',
          // cellRendererParams: {
          //   onClick: this.accessRequestOPSReview.bind(this),
          //   label: 'Review'
          // }, cellClass: 'grid-cell-centered', hide: this.isOpsHide }
          ];
      },
        error => {
          console.log(error);
        });
    }
    getAllAccessRequests(fromDate, toDate)  {
      this.loadingShow = true;
      this.accessGetRequestService.getAllAccessRequest(fromDate, toDate)
      .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
      .subscribe(
        data1 => {
          this.rowData = data1[0];
          this.loadingShow = false;
        },
        error => {
          this.rowData = [];
          this.loadingShow = false;
        }
      );
    }
    accessRequestView(e) {
      const modelRef = this.modalService.open(AccessViewPopupComponent,  { size: 'xl' });
      modelRef.componentInstance.accessRequestId = e.rowData.AccessRequestId;
    }

    accessRequestOPSReview(e) {
      const modelRef = this.modalService.open(AccessOPSReviewPopupComponent,  { size: 'xl' });
      modelRef.componentInstance.accessRequestId = e.rowData.AccessRequestId;
      modelRef.componentInstance.dataToAllRequest.subscribe((res) => {
        if (res === 1) {
          this.getAllAccessRequests(this.fromval, this.toval);
        }
      });

    }
    statusColor(status) {
       if (status === 'Approved' || status === 'Released') {
         return 'enableColor';
        } else if (status === 'Rejected' || status === 'Failed') {
          return 'cancelColor';
        } else if (status === 'Completed' ) {
        return 'completedColor';
       }
      }
    searchByDate() {
        const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
        const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
        this.getAllAccessRequests(this.fromval, this.toval);
        // this.getAllRequest(fromInput, to);
    }
    setFromDate() {
        this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
        console.log(this.fromval);
    }
    setToDate() {
        this.toval = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
        console.log(this.toval);
      }
  }
