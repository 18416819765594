import { GetRequestService } from './../../../core/services/get-request.service';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-rebar-license-details-edit-button',
  templateUrl: './license-details-edit-button.component.html',
  styleUrls: ['./license-details-edit-button.component.css']
})
export class LicenseDetailsEditButtonComponent implements ICellRendererAngularComp {
  isoperation: boolean;
  constructor(private getService: GetRequestService) {
    this.getService.getRole().subscribe(res => {
         this.role = res[0][0].role;
         this.isoperation = this.role === 'Ops';
    }, );
  }
  userType;
  rowData;
  params;
  label: string;
  role;
  agInit(params): void {
      this.params = params;
      this.rowData = params.data;
      this.label = this.params.label || null;
  }
  refresh(params?: any): boolean {
      return true;
  }
  onClick($event) {
      if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
      event: $event,
      rowData: this.params.node.data
      // ...something
    };
      this.params.onClick(params);
  }
}

}



