import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { DataService } from '../../../core/services/DataService';
import { ToastrService } from 'ngx-toastr';
import { AccessRequestData } from '../../../core/model/access-request.model';
import {Router} from '@angular/router';
import {NewRequestType} from '../../../core/services/enums';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-stream-admin-access',
  templateUrl: './stream-admin-access.component.html',
  styleUrls: ['./stream-admin-access.component.css'],
})
export class StreamAdminAccessComponent implements OnInit {
  admins = [];
  removedAdmins: any[] = [];
  removedAdminsText = '';
  removedAdminslist;
  newAdmin = '';
  submitValMsg  = '';
  loadingShow = false;
  comments = '';
  removedAdminCount = 0;
  environmentname = '';
  selectedstreamName = '';
  RequestTypeEnum = NewRequestType;
  @Input() DetailsFromAccessRequest: AccessRequestData;
  @Output() backtoRaiserequest: EventEmitter<any> = new EventEmitter();
  constructor(private getDataService: DataService , private accessRequestService: AccessRequestService, private toastr: ToastrService,
              private modalService: NgbModal , private route: Router ) {

  }
  ngOnInit() {
    this.getAdminEIDsByAppId(this.DetailsFromAccessRequest.AccessApplicationTeamId);
    this.environmentname = this.DetailsFromAccessRequest.Environment;
    this.selectedstreamName = this.DetailsFromAccessRequest.selectedstreamName;
  }

  validation() {
    const specialcharacter = /[ `!@#$%^&*()_+\-=\[\]{}':"\\|,<>\/?~]/;
    const newAdminCount = this.newAdmin.split(';');
    let inputs = 0;
    let eidCount = 0;
    if (newAdminCount[1] === '' ) {
      eidCount = 1;
    }
    if (newAdminCount[0] === '' ) {
      inputs = 0;
    } else {
      inputs = newAdminCount.length;
    }
    if (this.removedAdminsText === '' && this.newAdmin === '' ) {
      this.submitValMsg  = ' Atleast One Admin EID is mandatory.(Add New or remove from Existing AD Admin EID(s))';
      // this.submitValMsg  = 'Add atleast one Admin EID or remove from existing Admin EID(s) to submit a request.';
      return false;
    } else if (this.newAdmin !== '' &&  specialcharacter.test(this.newAdmin) ) {
      this.submitValMsg  = 'Special character can not be allowed except (. and ;)';
      return false;
    } else if (this.newAdmin !== '' && newAdminCount.length > 2 || eidCount === 1  ) {
      if (newAdminCount.length > 2) {
      this.submitValMsg  = 'Add only two AD group to submit a request.';
      return false;
    } else if (eidCount === 1) {
        this.submitValMsg  = 'Empty Admin EID can not be submitted.';
        return false;
      }
    } else if (this.comments === '') {
     console.log('Total AD Count =' + this.admins.length + ' + ' + inputs + ' - ' +
      this.removedAdminCount + ' = ' + ((this.admins.length + (this.newAdmin !== '' ? 1 : 0)) - this.removedAdminCount));
     if (((this.admins.length + inputs) - this.removedAdminCount) > 2) {
        this.submitValMsg  = 'Please enter Business Justification for having more than two Admin EIDs.';
        return false;
      }
    }
    // else if(this.newAdmin === ''){
    //   this.submitValMsg  = 'Add  atleast one EID to submit a request.';
    //   return false;
    // }
    return true;
  }
  // Check box check/ uncheck enent capture for removing the selected item
  OnChange(e , selectedvalue) {
    console.log('selectedvalue' + selectedvalue);
    if (e.target.checked) {
      console.log('if');
      this.removedAdmins.push(selectedvalue);
    } else {
      console.log('else');
      this.removedAdmins.splice(this.removedAdmins.indexOf(selectedvalue), 1);
    }
    if (this.removedAdmins.length <= 0) {
      this.removedAdminsText = '';
      this.removedAdminCount = 0;
    }

    console.log('removedAdmins length' + this.removedAdmins.length);
  }
  getAdminEIDsByAppId(appId) {
    console.log('App ID :' + appId);
    this.loadingShow = true;
    this.accessRequestService.getAdminEIDsByAppId(appId).subscribe(res => {
      console.log('Response :' + res[0]);
      if (res[0] !== '') {
        this.admins = res[0];
      } else {
        this.admins = [];
      }
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
  });

  }

  removeSelected_click()  {
    console.log('Remove click event triggered');
    this.submitValMsg = '';
    this.removedAdminCount = 0;
    if (this.removedAdmins.length > 0) {
      this.removedAdminsText = '';
      this.removedAdminslist =  this.admins.filter(a => this.removedAdmins.indexOf(a.Name) > -1);
      this.removedAdminslist.forEach(element => {
      this.removedAdminsText += element.Name +  ';';
      this.removedAdminCount++;
      });
      this.removedAdminsText = this.removedAdminsText.slice(0, -1);
  } else  {
    this.removedAdminsText = '';
    this.removedAdminCount = 0;
  }

  }
  // openConfirmBox(content){
  //   if (this.validation () === true) {
  //     if (((this.admins.length + (this.newAdmin !== '' ? 1 : 0)) - this.removedAdminCount) > 2) {
  //   const activeModal = this.modalService.open(content, { size: 'lg' });
  //     } else {
  //       this.Submit_Click(content);
  //     }
  //   }
  // }
  Submit_Click() {
    if (this.validation()) {
    this.loadingShow = true;
    this.DetailsFromAccessRequest.RequestTypeId = this.RequestTypeEnum.StreamAdminAcess;
    this.DetailsFromAccessRequest.AddedAdmins = this.newAdmin;
    this.DetailsFromAccessRequest.RemovedAdmins = this.removedAdminsText;
    this.DetailsFromAccessRequest.RequesterComments = this.comments;
    console.log(this.DetailsFromAccessRequest);
    this.accessRequestService.saveAccessRequest(this.DetailsFromAccessRequest).subscribe((res: any) => {
      if (res[0].Res === 'Success') {
      this.toastr.success('Access Request Submitted.');
      // this.backtoRaiserequest.emit(true);
      this.route.navigate(['/access/allrequest']);
      // window.location.pathname='/access/raiserequest';
      } else {
        this.toastr.error('Some error occured.');
      }
      // this.modalService.dismissAll(content);
      this.clearAllData();
      this.loadingShow = false;
    },
      error => {
        console.log(error);
        // this.modalService.dismissAll(content);
        this.loadingShow = false;
        this.toastr.error('Some error occured.');
        this.clearAllData();
      });
  }
  }
  clearAllData() {
  }
}


