import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GetRequestService } from '../../core/services/get-request.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
  links = [{name: 'New Request', path: '/applicationonbording/createrequest',
  show: false  },
         {name: 'Request List', path: '/applicationonbording/request', show: true }];
  liscenselinks = [{name: 'Request New Liscense', path: '/license/createrequest', show: true},
         {name: 'List', path: '/license/allrequest', show: true},
         {name: 'Manage', path: '/license/manage', show: false}];
  accessLinks = [{name: 'Request List', path: '/access/allrequest', show: true },
                 {name: 'New Request', path: '/access/raiserequest', show: true  }];
  activeLink = this.links[0];
  accessactiveLink = this.accessLinks[0];
appOnBoardingMenu = false;
licenseMenu = false;
accessMenu = false;
user: any;
  constructor(private router: Location, private getservice: GetRequestService) {
    getservice.userType.subscribe(userData => {
      this.user = userData;
      this.links = [{name: 'Request List', path: '/applicationonbording/request', show: true },
      {name: 'New Request', path: '/applicationonbording/createrequest',
    show: (this.user[0].role === 'App' || this.user[0].role === 'Ops')  }
           ];
      this.liscenselinks = [{name: 'Request New License', path: '/license/createrequest', show: true},
           {name: 'List', path: '/license/allrequest', show: true},
           {name: 'Manage', path: '/license/manage', show: (this.user[0].role === 'Ops')}];
      if (this.router.path() === '') {
            this.appOnBoardingMenu = false;
            this.licenseMenu = false;
            this.accessMenu = false;
            } else if (this.router.path().indexOf('applicationonbording') > -1) {
             this.appOnBoardingMenu = true;
             this.licenseMenu = false;
             this.accessMenu = false;
             this.activeLink = this.links.filter(x => x.path === this.router.path())[0];
             console.log(this.activeLink);
            } else if (this.router.path().indexOf('license') > -1) {
              this.licenseMenu = false;
              this.appOnBoardingMenu = false;
              this.activeLink = this.liscenselinks.filter(x => x.path === this.router.path())[0];
             } else if (this.router.path().indexOf('access') > -1) {
              this.appOnBoardingMenu = false;
              this.licenseMenu = false;
              this.accessMenu = true;
              this.accessactiveLink = this.accessLinks.filter(x => x.path === this.router.path())[0];
              console.log(this.accessactiveLink);
             }
    });
}

  ngOnInit() {
    this.getservice.currentUrl.subscribe(url => {
            this.getRole();
           });
    // this.user = localStorage.getItem('permission');
    // this.links = [{name: 'New Request', path: '/applicationonbording/createrequest',
    // show: (this.user[0].role === 'App' || this.user[0].role === 'Ops')  },
    //        {name: 'Request List', path: '/applicationonbording/request', show: true }];
    // this.liscenselinks = [{name: 'Request New License', path: '/license/createrequest', show: true},
    //        {name: 'List', path: '/license/allrequest', show: true},
    //        {name: 'Manage', path: '/license/manage', show: (this.user[0].role === 'Ops')}];
  }
  getRole() {
    this.getservice.getRole().take(1).subscribe(data => {
      if (data[0].length > 0) {
        this.links = [{name: 'Request List', path: '/applicationonbording/request', show: true },
        {name: 'New Request', path: '/applicationonbording/createrequest',
    show: (data[0][0].role === 'App' || data[0][0].role === 'Ops')  }
           ];
        this.liscenselinks = [{name: 'Request New License', path: '/license/createrequest', show: true},
           {name: 'List', path: '/license/allrequest', show: true},
           {name: 'Manage', path: '/license/manage', show: (data[0][0].role === 'Ops')}];
      } else {
        this.links = [{name: 'New Request', path: '/applicationonbording/createrequest',
    show: true  },
           {name: 'Request List', path: '/applicationonbording/request', show: true }];
      }
      if (this.router.path() === '') {
        this.appOnBoardingMenu = false;
        this.licenseMenu = false;
        this.accessMenu = false;
        } else if (this.router.path().indexOf('applicationonbording') > -1) {
         this.appOnBoardingMenu = true;
         this.licenseMenu = false;
         this.accessMenu = false;
         this.activeLink = this.links.filter(x => x.path === this.router.path())[0];
         console.log(this.activeLink);
        } else if (this.router.path().indexOf('license') > -1) {
          this.licenseMenu = false;
          this.appOnBoardingMenu = false;
          this.accessMenu = false;
          this.activeLink = this.liscenselinks.filter(x => x.path === this.router.path())[0];
         } else if (this.router.path().indexOf('access') > -1) {
          this.appOnBoardingMenu = false;
          this.licenseMenu = false;
          this.accessMenu = true;
          this.accessactiveLink = this.accessLinks.filter(x => x.path === this.router.path())[0];
          console.log(this.accessactiveLink);
         }
    },
      error => {
        console.log(error); 
      });
}
}
