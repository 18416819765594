import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { data } from '../../../core/model/New-Request.model';
import { FileDetailsComponent } from '../../create-request/file-details/file-details.component';

import { NewRequestService } from '../../../core/services/new-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgbModal,NgbPopover } from '@ng-bootstrap/ng-bootstrap';

// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.css']
})
export class NewRequestComponent implements OnInit {
  @ViewChild('pdata', { static: false }) pdata: any;
  isLinear = true;
  // General
  platformValMsg = '';
  // Benifit variable declaration
  finCostDeductionValMsg = ''; finCostavoidanceValMsg = ''; speedEffectivenessValMsg = '';
  strategicImapctValMsg = '';
  benifitFormGroup: FormGroup;
  // Readiness and Risk
  allData = data;
  AINamesList = [];
  numnerOfRequestValMsg = '';
  numberOfResultValMsg = '';
  // newRequest: NewRequest = new  NewRequest();
  datatoSave = [];
  detailsArray = [];
  datepicker = new Date();
  dataClear = data;
  loadingShow = false;
  popover: NgbPopover;
  domainListArray = [];
  domainOther = '';
  roleListArray = [];
  roleOther = '';
  AIFlag = false;
  AIRIDFlag = false;
  constructor(private AIservice: GetRequestService, private toastr: ToastrService,
              private modalService: NgbModal, private datepipe: DatePipe,
              private service: NewRequestService, private el: ElementRef, private getService: GetRequestService) {
    const urlArray = [{ name: 'applicationonbording', path: '/applicationonbording/createrequest' }];
    this.getService.changeUrl(urlArray);
    this.getAllAILeadsNames();
  }

  ngOnInit() {
  }
  next(type, stepper: MatStepper) {
    if (type === 'general') {
      if (!this.genralValidation()) {
        return false;
      }
    } else if (type === 'Dashboard') {
      if (!this.dasboardValidation()) {
        return false;
      }
    }  else if (type === 'benefits') {
      if (!this.benifitsValidation()) {
        return false;
      }
    } else if (type === 'readiness') {
      if (!this.readynessValidation()) {
        return false;
      }
    } else {
      if (this.dataPrivacyValidation()) {
        this.saveRequest(stepper);
        return true;
      } else {
        return false;
      }
      stepper.next();
    }
    stepper.next();
  }
  previous(stepper: MatStepper) {
    console.log(stepper);
    stepper.previous();
  }

  getAllAILeadsNames() {
    this.AIservice.getAllRequest('AI', 'AI')
      .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
      .subscribe(
        data1 => {
          console.log(data1);
          this.AINamesList = data1[0];
          // this.rowData = [{RequestId: '1', platform: 'QlikSense', applicationname: 'Admin',
          // opsApprovalStatus: 'Pending', EiiApprovalStatus: 'Pending' }];
        },
        error => {
console.log('Error: Unable to find AI Names');
        }
      );
  }

  changeOfAIOrNonAI(item) {
    this.allData[49].valMsg = '';
    this.allData[49].response = '';
    if (item === 'AI') {
        this.AIFlag = false;
      } else {
        this.AIFlag = true;
        this.getAllAILeadsNames();
      }
  }

  changeofCIONonCIO(item) {
    this.allData[50].valMsg = '';
    if (item === 'internal') {
      this.allData[31].response = 'internal';
      this.AIRIDFlag = false;
    } else {
      this.allData[50].response = '';
      this.allData[31].response = 'external';
      this.AIRIDFlag = true;
    }
  }

  genralValidation() {
if (this.allData[0].response === '') {
      this.allData[0].valMsg = 'Please Select Platform';
      return false;
    }
if (this.allData[1].response === '') {
      this.allData[1].valMsg = 'Please Enter Organization';
      return false;
    }
if ((this.allData[2].response === true && this.allData[3].response === '')) {
      this.allData[2].valMsg = 'Please Enter demand id';
      return false;
    }
if (this.allData[4].response === '') {
      this.allData[4].valMsg = 'Please Enter Application Name';
      return false;
    }
if (this.allData[5].response === '') {
      this.allData[5].valMsg = 'Please Enter Project Name';
      return false;
    }
if (this.allData[31].response === '') {
      this.allData[31].valMsg = 'Please Select Use';
      return false;
    }

if (this.allData[50].response === '') {
        if (this.allData[6].response === 'Cio') {
        this.allData[50].valMsg = 'Please Enter AIRID';
        return false;
        }
      }
if ((this.allData[49].response === '') || (this.allData[49].response === '0')) {
        if (this.allData[48].response === 'EII') {
        this.allData[49].valMsg = 'Please Select AI Lead';
        return false;
        }
      }
if (this.allData[23].response === '') {
      this.allData[23].valMsg = 'Please Enter Sponsor';
      return false;
    }
if (this.allData[24].response === '') {
      this.allData[24].valMsg = 'Please Enter Business Contact';
      return false;
    }
if (this.allData[25].response === '') {
      this.allData[25].valMsg = 'Please Enter Delivery/Capability Lead';
      return false;
    }
if (this.allData[26].response === '') {
      this.allData[26].valMsg = 'Please Enter Delivery /Project Manager';
      return false;
    }
if (this.allData[27].response === '') {
      this.allData[27].valMsg = 'Please Enter Project POC';
      return false;
    }
if (this.allData[28].response === '') {
      this.allData[28].valMsg = 'Please Enter Project Active Directory Mailing Group';
      return false;
    }
if (this.allData[29].response === '') {
      this.allData[29].valMsg = 'Please Enter Group Location(s)';
      return false;
    }
if (this.allData[30].response === '') {
      this.allData[30].valMsg = 'Please Enter CIO Contact';
      return false;
    }

return true;
  }

  dasboardValidation() {
    if (this.allData[7].response === '') {
      this.allData[7].valMsg = 'Please Select value for existing asset checked';
      return false;
    }
    if (this.allData[8].response === '') {
      this.allData[8].valMsg = 'Please Enter value for business needs';
      return false;
    }
    if (this.allData[10].response === '') {
      this.allData[10].valMsg = 'Please Select QlikView/Qlik Sense usage aligns to their Business Case';
      return false;
    }
    if (this.allData[9].response === '') {
      this.allData[9].valMsg = 'Please Describe Why Qlik Sense or QlikView';
      return false;
    }
    if (this.allData[33].response === '') {
      this.allData[33].valMsg = 'Please Enter Business Case';
      return false;
    }
    if (this.allData[12].response === '') {
      this.allData[12].valMsg = 'Please Enter value for data source';
      return false;
    }
    if (this.allData[13].response.toString() === '') {
      this.allData[13].valMsg = 'Please List all domains of enterprise data used';
      return false;
    }
    if ((this.allData[14].response === true && this.allData[15].response === '')) {
      this.allData[14].valMsg = 'Please Enter EID of approval';
      return false;
    }
    // if (this.allData[16].response === '') {
    //   this.allData[16].valMsg = 'Please Enter period of data planned to retain';
    //   return false;
    // }
    // if (this.allData[19].response === 0 ) {
    //   this.allData[19].valMsg = 'Please Enter volume of data';
    //   return false;
    // }
    if (this.allData[47].response === '' && Number(this.allData[19].response) > 2000000 ) {
      this.allData[19].valMsg = 'Please Enter details';
      this.popover.open();
      return false;
    }
    if (this.allData[20].response === '') {
      this.allData[20].valMsg = 'Please Select how are we Planning to consume Qlik Dashboard';
      return false;
    }
    if (this.allData[21].response === '') {
      this.allData[21].valMsg = 'Please Enter Customers, End Users details';
      return false;
    }
    if (this.allData[22].response.toString() === '') {
      this.allData[22].valMsg = 'Please Select Role';
      return false;
    }

    return true;
  }

  benifitsValidation() {
    // if (this.allData[34].response === '' ) {
    //   this.allData[34].valMsg = 'Please Enter Financial - Cost Reduction';
    //   this.popover.open();
    //   return false;
    // }
    // if (this.allData[35].response === '') {
    //   this.allData[35].valMsg = 'Please Enter Financial - Cost Avoidance';
    //   return false;
    // }
    // if (this.allData[36].response === '') {
    //   this.allData[36].valMsg = 'Please Select Speed & Effectiveness';
    //   return false;
    // }
    // if (this.allData[37].response === '') {
    //   this.allData[37].valMsg = 'Please Select Strategic Impact';
    //   return false;
    // }

    return true;
  }
  readynessValidation() {
    if (this.allData[38].response === '' || this.allData[38].response === '0') {
      this.allData[38].valMsg = 'Please add details for dashboard';
      return false;
    }
    // if (this.allData[39].response === '') {
    //   this.allData[39].valMsg = 'Please Enter Value For Number Of user.';
    //   return false;
    // }
    // if (this.allData[40].response === '') {
    //   this.allData[40].valMsg = 'Please Enter Value For Number Of Concurrent Users.';
    //   return false;
    // }
    if (this.allData[41].response === '') {
      this.allData[41].valMsg = 'Please Select Developer Readiness';
      return false;
    }
    if (this.allData[42].response === '') {
      this.allData[42].valMsg = 'Please Select Scope';
      return false;
    }
    if (this.allData[43].response === '') {
      this.allData[43].valMsg = 'Please Select Data';
      return false;
    }
    return true;
  }
  dataPrivacyValidation() {
    if (this.allData[44].response === '') {
      this.allData[44].valMsg = 'Please Select Report has PII Data?';
      return false;
    }
    if (this.allData[45].response === '') {
      this.allData[45].valMsg = 'Please Select Data Privacy Plan';
      return false;
    }
    if (!this.allData[46].response) {
      this.allData[46].valMsg = 'Please accept and then submit';
      return false;
    }
    return true;
  }
  saveRequest(stepper: MatStepper) {
    this.loadingShow = true;
    this.allData[32].response = this.datepipe.transform(this.allData[32].response.toString(), 'yyyy-MM-dd');
    this.allData[22].response = this.allData[22].response.toString();
    this.allData[13].response = this.allData[13].response.toString();
    const dataToSend = {
      RequestType: this.allData[0].response,
      AppName: this.allData[4].response,
      detailsData: this.detailsArray.length === 0 ? [] : this.detailsArray[0].details,
      filename: this.detailsArray.length === 0 ? '' : this.detailsArray[0].filename,
      filebody: this.detailsArray.length === 0 ? [] : this.detailsArray[0].fileBody,
      queAns: this.allData,
      AIA: this.allData[48].response
    };

    this.service.saveData(dataToSend).subscribe((res: any) => {
      // if (dataToSend.filename !== '')
      if (dataToSend.filename.length > 0) {
      for (let i = 0; res.length > i; i++) {
        this.service.saveFile(dataToSend.filebody[i], res[i]).subscribe(res3 => {
          if (i === (res.length - 1)) {
          this.toastr.success('Request saved successfully.');
          this.clearAllData();
          stepper.reset();
          this.loadingShow = false;
          }
        }, error => {
          console.log(error);
          this.toastr.error('Failed to deploy files.');
          this.clearAllData();
          stepper.reset();
          this.loadingShow = false;
        });
      }
    } else {
      this.toastr.success('Request saved successfully.');
      this.clearAllData();
      stepper.reset();
      this.loadingShow = false;
    }
    },
      error => {
        console.log(error);
        this.toastr.error('Failed to deploy files.');
        this.clearAllData();
        stepper.reset();
        this.loadingShow = false;
      });
  }

  clearAllData() {
    for (let i = 0; this.allData.length > i; i++) {
      this.allData[i].response = '';
      this.allData[i].other = '';
    }
    this.allData[6].response = 'Cio';
    this.allData[2].response = false;
    this.allData[13].response = [];
    this.allData[22].response = [];
    this.allData[48].response = 'EII';
    this.allData[46].response = true;
    this.allData[32].response = new Date();
    this.allData[19].response = 0;
    this.detailsArray = [];
  }
  dropdownChange(p: NgbPopover, num) {
    if (this.allData[num].response === 'Other') {
      p.open();
    } else {
      this.allData[num].other = '';
    }
  }
  checkValue(p: NgbPopover, o: NgbPopover, num, val) {
    this.domainOther = '';
    this.roleOther = '';
    this.allData[num].response = '';
    const blankSpace = val.indexOf('') > -1 ? true : false;
    if (val.indexOf('') > -1) {
      val.splice(val.indexOf(''));
    }
    val.forEach((value, index) => {
      console.log(index);
      if (value !== '') {
        if ((index + 1) === val.length) {
          this.allData[num].response = this.allData[num].response + value;
        } else {
          this.allData[num].response = this.allData[num].response + value + ',';
        }
      }
    });
    if (blankSpace) {
      p.close();
      o.open();
    } else {

    }
  }
  addOtherDataSource(o: NgbPopover, num, val) {
    this.allData[num].response = this.allData[num].response === '' ?
    this.allData[num].response + val :
    this.allData[num].response + ',' + val;
    o.close();
  }
  addDetails() {
    this.allData[38].valMsg = '';
    const activeModal = this.modalService.open(FileDetailsComponent, { size: 'xl' });
    activeModal.componentInstance.inputRowdata = this.detailsArray;
    activeModal.componentInstance.passData.subscribe((receivedEntry) => {
      this.detailsArray = receivedEntry;
      this.allData[38].response = this.detailsArray[0].details.length.toString();
      console.log(this.detailsArray);
    });

  }

  popOpen(p: NgbPopover, val): void {
    this.popover = p;
    if (val > 2000000) {
      p.open();
    } else {
      p.close();
    }
  }
  closePopOver(p: NgbPopover) {
    p.close();
  }
//   uploadFile(file) {
//     const contentType = file.type;
//     const bucket = new S3(
//           {
//               accessKeyId: 'AKIA35QV547XLF2X5G7I',
//               secretAccessKey: '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',
//               region: 'us-east-1'
//           }
//       );
//     const params = {
//           Bucket: '4834-analyticsplatform',
//           Key:  file.name,
//           Body: file,
//           ACL: 'public-read',
//           ContentType: contentType
//       };
//     bucket.upload(params, (err, data2) => {
//           if (err) {
//               console.log('There was an error uploading your file: ', err);
//               return false;
//           }
//           console.log('Successfully uploaded file.', data2);
//           return true;
//           this.toastr.success('Request saved successfully.');
//           // this.clearAllData();
//           // stepper.reset();
//           this.loadingShow = false;
//       });
// }
}
