import { Component, OnInit } from '@angular/core';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { ToastrService } from 'ngx-toastr';
import {GetLicenseRequestService} from '../../../core/services/get-license-request.service';
import {DatePipe} from '@angular/common';
import { GetRequestService } from '../../../core/services/get-request.service';

@Component({
  selector: 'app-license-operation',
  templateUrl: './license-operation.component.html',
  styleUrls: ['./license-operation.component.css']
})
export class LicenseOperationComponent implements OnInit {
  platform = '1'; price = 0; 
  toDate = new Date();
  frmDate = new Date(this.toDate.getFullYear(), (this.toDate.getMonth()), this.toDate.getDate() - 30);
   description = ''; fromval = '';
  toVal = ''; licenseCount = 0; poNumber = ''; showDiv = true;
  qlikSenseCount = 0; qlikSensePrice = 0;
  qlikViewCount = 0; qlikViewPrice = 0;
  platformValMsg = ''; priceValMsg = ''; frmDateValMsg = ''; toDateValMsg = ''; descriptionValMsg = '';
  loadingShow = false; rowData = [];  licenseCountValMsg = ''; poNumberValMsg = '';
  defaultColDef = { resizable: true };
  columnDefs = [
    // {headerName: 'Platform', field: 'Platform', width: 130, filter: 'agTextColumnFilter'},
    {headerName: 'Price(USD)', field: 'PerUnitCost', width: 200 , filter: 'agTextColumnFilter'},
    {headerName: 'Count', field: 'LicenseCount', width: 100 , filter: 'agTextColumnFilter'},
    {headerName: 'PO Number', field: 'PoNumber', width: 160 , filter: 'agTextColumnFilter'},
    {headerName: 'From Date', field: 'StartDate', width: 150, cellRenderer: (data) => {
      return this.datepipe.transform(data.value, 'yyyy-MM-dd');
    }},
    {headerName: 'To Date', field: 'EndDate', width: 150 , cellRenderer: (data) => {
      return this.datepipe.transform(data.value, 'yyyy-MM-dd');
    }},
    {headerName: 'Description', field: 'Description', width: 195 , filter: 'agTextColumnFilter'},
    {headerName: 'Status', field: 'ActiveStatus', width: 100 , filter: 'agTextColumnFilter', cellRenderer: (data) => {
      return data.value === 1 ? 'Active' : 'InActive';
    }}
      ];
  constructor(private createService: CreateLicenseRequestService, private datepipe: DatePipe, private toastr: ToastrService,
              private getLicense: GetLicenseRequestService, private service: GetRequestService) {
                const urlArray = [{ name: 'Request New License', path: '/license/createrequest' }];
                this.service.changeUrl(urlArray);
              }

  ngOnInit() {
    this.toVal = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + this.toDate.getDate();
    this.fromval = this.frmDate.getFullYear() + '-' + (this.frmDate.getMonth() + 1) + '-' + this.frmDate.getDate();
    this.getOperationsData();
    this.getLicenseDetails();
  }
  getOperationsData() {
    this.loadingShow = true;
    this.getLicense.getOperationsData()
    .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
    .subscribe(
      data1 => {
        console.log(data1[0]);
        this.rowData = data1[0];
        this.loadingShow = false;
      },
      error => {
        this.loadingShow = false;
        this.rowData = [];
        console.log(error);
      }
    );
  }
  getLicenseDetails() {
    this.loadingShow = true;
    this.getLicense.getLicenseCount()
    .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
    .subscribe(
      res => {
        console.log(res);
        res[0].forEach(row => {
            this.qlikSenseCount = row.LicenseCount;
        });
        res[1].forEach(row => {
            this.qlikSensePrice = row.PerUnitCost;
        });

      },
      error => {
        this.loadingShow = false;
        this.rowData = [];
        console.log(error);
      }
    );
  }
  clearAllData() {
    this.platform = ''; this.price = 0; this.frmDate = new Date();
    this.toDate = new Date(); this.description = ''; this.poNumber = '';
    this.licenseCount = 0;
  }
  validation() {
    // if (this.platform === '') {
    //   this.platformValMsg = 'Please select platform.';
    //   return false;
    // }
    if (this.price === 0) {
      this.priceValMsg = 'Please enter price';
      return false;
    }
    if (this.poNumber === '') {
      this.poNumberValMsg = 'Please enter PO Number';
      return false;
    }
    if (this.licenseCount === 0) {
      this.licenseCountValMsg = 'Please enter license count';
      return false;
    }
    if (this.frmDate.getTime() > this.toDate.getTime() || this.frmDate.getTime() === this.toDate.getTime() ) {
      this.frmDateValMsg = 'From Date should be less than to date';
      return false;
    }
    if (this.description === '') {
      this.descriptionValMsg = 'Please enter description';
      return false;
    }
    return true;
  }
  saveData() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        platform: this.platform, price: this.price, frmDate: this.fromval,
        toDate: this.toVal, description: this.description, activeStatus: true,
        licenseCount: this.licenseCount, poNumber: this.poNumber
       };
      console.log(dataToSend);
      this.createService.saveOperations(dataToSend).subscribe((res: any) => {
        if (res === 'Success') {
        this.toastr.success('Record saved successfully.');
        } else {
          this.toastr.error('Some error occured.');
        }
        this.clearAllData();
        this.loadingShow = false;
        this.getLicenseDetails();
        this.getOperationsData();
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.toastr.error('Some error occured.');
          this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
  setFromDate() {
    this.frmDateValMsg = '';
    this.fromval = this.frmDate.getFullYear() + '-' + (this.frmDate.getMonth() + 1) + '-' + this.frmDate.getDate();
    console.log(this.fromval);
  }
  setToDate() {
    this.frmDateValMsg = '';
    this.toVal = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + this.toDate.getDate();
    console.log(this.toVal);
  }

}
