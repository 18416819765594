import { Component, OnInit } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { MatStepper } from '@angular/material/stepper';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-license-request',
  templateUrl: './license-request.component.html',
  styleUrls: ['./license-request.component.css']
})
export class LicenseRequestComponent implements OnInit {
  constructor(private getService: GetRequestService, private getLicenseService: GetLicenseRequestService) {
    const urlArray = [{ name: 'Request New License', path: '/license/createrequest' }];
    this.getService.changeUrl(urlArray);
   }
  manageType = '';
  Appnames;
  keyword;
  appNameValMessage = '';
  isApplicationDisabled;
  appName = '';
  loadingShow = false;
  ActiveBIAccess = true;
  biAccess = ''; biAccessValMsg = '';
  setRadio(e: string): void {
        this.manageType = e;
  }
  isSelected(manageRequestType: string): boolean {
    return (this.manageType === manageRequestType);
  }

  ngOnInit() {
    this.getAppName();
  }
  next(type, stepper: MatStepper) {
    if (this.validation(type)) {
      stepper.next();
    } else  {
      return false;
    }
    if (type === 'BI') {
      this.manageType = 'NewLicenseRequest';
    }
  }
  previous(stepper: MatStepper) {
    console.log(stepper);
    stepper.previous();
    this.manageType = '';
    this.biAccess = '';
  }
  SelectEvent(item) {
    this.appName = item;
  }
  ClearTextbox() {

  }
  validation(type) {
    if (type === 'App') {
      if (this.appName === '') {
        this.appNameValMessage = 'Please enter application name';
        return false;
      }
    } else if (type === 'BI') {
      if (this.biAccess === '') {
        this.biAccessValMsg = 'Please select any of the below options (yes or no)';
        return false;
      }
      if (this.biAccess === 'No') {
        this.biAccessValMsg = 'If you do not have access to below URL' + '\'' + 's, you will not be able to proceed further.';
        return false;
      }
    }
    return true;
  }

  handleChange(value) {
    if (value === 'No') {
      this.biAccess = 'No';
      this.ActiveBIAccess = true;
      this.validation('BI');
    } else {
      this.ActiveBIAccess = false;
      return false;
    }
  }

  getAppName() {
    this.loadingShow = true;
    this.getLicenseService.getapplicationName().subscribe(res => {
      this.Appnames = res[0];
      this.keyword = 'Application_Name';
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }
}
