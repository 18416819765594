import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { DataService } from '../../../core/services/DataService';
import { AccessRequestData } from '../../../core/model/access-request.model';
import { GetRequestService } from '../../../core/services/get-request.service';
import {Environment} from '../../../core/services/enums';
import {Platform} from '../../../core/services/enums';

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.css']
})
export class AccessRequestComponent implements OnInit {
  @ViewChild('stepper', { static: false }) myStepper: MatStepper;
  constructor(private accessRequestService: AccessRequestService, private service: GetRequestService,
              private getDataService: DataService ) {
    const urlArray = [{ name: 'applicationonbording', path: '/applicationonbording/request' }];
    this.service.changeUrl(urlArray);
  }
  accessLinks = [{name: 'Request List', path: '/access/allrequest', show: true },
  {name: 'New Request', path: '/access/raiserequest', show: true  }];
accessactiveLink = this.accessLinks[1];
  manageType = '';
  MasterTablesData;
  Platforms;
  PlatformsUrls;
  PlatformsUrlsOfSelectedEnvironment;
  selectedPlatform = 0;
  selectedPlatformsUrlId = 0;
  selectedPlatformsUrl: any;
  Environments;
  StreamNames;
  selectedEnvironment = 0 ;
  keywordstream;
  EnvironmentValMessage = '';
  PlatformValMessage = '';
  PlatformURLValMessage = '';
  selectedStreamId: any;
  StreamValMessage = '';
  isApplicationDisabled = true;
  streamName = '';
  selectedstreamName = '';
  Name: any;
  loadingShow = false;
  accessRequest: AccessRequestData = new AccessRequestData();
  EnvironmentEnum = Environment;
  platform = '';
  PlatformEnum = Platform;
  ClearTextbox() {
  this.streamName = '';
  }
  ngOnInit() {
    this.getMasterTablesData();
  }
  next(stepper: MatStepper) {
    if (this.validation()) {
      // debugger;
      this.accessRequest = new AccessRequestData();
      // this.accessRequest.RequestTypeId = 3;
      this.accessRequest.PlatformId = this.selectedPlatform;
      this.accessRequest.PlatformURLId = this.selectedPlatformsUrlId;
      this.accessRequest.AccessApplicationTeamId = this.selectedStreamId;
      this.accessRequest.selectedstreamName = this.selectedstreamName;
      this.accessRequest.AddedADGroups =  '';
      this.accessRequest.RemovedADGroups = '';
      this.accessRequest.RequesterComments = '';
      this.accessRequest.Environment = this.Environments.filter(e => e.EnvironmentId === Number(this.selectedEnvironment))[0].Name ;
      console.log(this.accessRequest);
      this.platform = this.PlatformEnum[this.accessRequest.PlatformId];
      console.log(this.platform);
      // tslint:disable-next-line: radix
      if (parseInt(this.selectedPlatformsUrlId.toString()) === 2 || parseInt(this.selectedPlatformsUrlId.toString()) === 4) {
      this.manageType = 'AppLevelSecurity';
    } else {
      this.manageType = 'StreamLevelAccess';
    }
      stepper.next();
    } else  {
      return false;
    }
  }
  backtoRaiserequest(event) {
    this.myStepper.previous();
  }
  previous(stepper: MatStepper) {
    this.manageType = '';
    console.log(stepper);
    stepper.previous();
  }
  SelectStreamEvent(item) {
    this.streamName = item;
    this.Name = item;
    this.selectedstreamName = item.Name;
    this.selectedStreamId = item.AccessApplicationTeamId;
    console.log('streamName' +  this.streamName);
  }

  CleargTextbox() {

  }
  validation() {
    if ( this.selectedPlatform <= 0 ) {
       this.PlatformValMessage = 'Please select platform';
       return false;
    } else if (this.selectedEnvironment <= 0 ) {
      this.EnvironmentValMessage = 'Please select environment';
      return false;
     } else if (this.selectedPlatformsUrlId <= 0 ) {
      this.PlatformURLValMessage = 'Please select platform URL';
      return false;
     } else if (this.Name === '' ) {
      this.StreamValMessage = 'Please select stream name';
      return false;
     } else if (this.StreamNames.filter(x => x.Name === this.Name.Name).length < 1) {
      this.StreamValMessage = 'Please select stream name from list';
      return false;
     }
    return true;
  }
  OnChange(selectedPlatformsUrlId) {
    this.Name = '';
    console.log('selectedvalue' + selectedPlatformsUrlId);
    if (selectedPlatformsUrlId > 0) {
      this.selectedPlatformsUrl = this.PlatformsUrls.filter(p => p.PlatformURLId === Number(selectedPlatformsUrlId))[0].URL;
      this.PlatformURLValMessage = '';
    } else {
      this.selectedPlatformsUrl = '';
    }
  }
  OnEnvironmentChange(selectedEnvironmentValue) {
    this.Name = '';
    this.getApplicationTeamsByEnvironment(selectedEnvironmentValue);
    if (selectedEnvironmentValue > 0 ) {
        this.PlatformsUrlsOfSelectedEnvironment = this.PlatformsUrls.filter(p => p.EnvironmentId === Number(selectedEnvironmentValue) );
       } else {
        this.PlatformsUrlsOfSelectedEnvironment = [];
      }
    this.selectedPlatformsUrl = '';
  }


  getMasterTablesData() {
    this.loadingShow = true;
    this.accessRequestService.getMasterTables().subscribe(res => {
      this.MasterTablesData = res;
      console.log('retrived the master tables data');
      console.log(res);
      this.Environments = res[0];
      this.Platforms = res[1].filter(r => r.IsVisibleToAccessMgt === 1);
      this.PlatformsUrls = res[2];
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }
  getApplicationTeamsByEnvironment(environmentId) {
    this.loadingShow = true;
    this.accessRequestService.getApplicationTeamsByEnvironment(environmentId).subscribe(res => {
      this.StreamNames = res[0];
      console.log('streams');
      console.log(this.StreamNames);
      this.keywordstream = 'Name';
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }

}
