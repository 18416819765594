import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector : 'app-action-request',
    templateUrl : './action-button.component.html',
    styleUrls : ['./action-button.component.css']
})

export class ActionButtonRequestComponent implements ICellRendererAngularComp {

    constructor() {
      this.userType = localStorage.getItem('userType');
    }
    rowData;
    userType;
    params;
    label: string;
    agInit(params): void {
        this.params = params;
        this.rowData = params.data;
        this.label = this.params.label || null;
    }
    refresh(params?: any): boolean {
        return true;
    }
    onClick($event) {
        if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      };
        this.params.onClick(params);

    }
  }
  enableDisable() {
    if ((this.rowData.ManagersApproval === 'Pending' && this.userType === 'Manager') ||
        (this.userType === 'OPS' && (this.rowData.ManagersApproval === 'Approved' || this.rowData.ManagersApproval === 'Released')
        && this.rowData.Status === 'Pending' )) {
      return true;
    } else { return false; }
}
}
