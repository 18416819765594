import { Component, OnInit} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'rebar-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})
export class CancelButtonComponent implements ICellRendererAngularComp {

  constructor() {}
  userType = '';
  rowData;
  params;
  label: string;
  
  agInit(params): void {
      this.userType = localStorage.getItem('userType');
      this.params = params;
      this.rowData = params.data;
      this.label = this.params.label || null;
  }
  refresh(params?: any): boolean {
      return true;
  }
  onClick($event) {
      if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
      event: $event,
      rowData: this.params.node.data
      // ...something
    };
      this.params.onClick(params);

  }
}
enableDisable() {
  if (localStorage.getItem('userType') == 'Manager'){
    if (this.rowData.ManagersApproval == 'Pending') {
      return true;
    } else { return false; }
  }
  if (localStorage.getItem('userType') == 'App'){
    if (this.rowData.ManagersApproval == 'Pending' || this.rowData.ManagersApproval == 'Approved' 
    && this.rowData.Status == 'Pending') {
      return true;
    } else { return false; }
  }
  if (localStorage.getItem('userType') == 'OPS'){
    if (this.rowData.Status == 'Pending') {
      return true;
    } else { return false; }
  }

}
}
