import { NewRequestService } from './../../../core/services/new-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {GetLicenseRequestService} from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-rebar-license-details-edit-popup',
  templateUrl: './license-details-edit-popup.component.html',
  styleUrls: ['./license-details-edit-popup.component.css']
})
export class LicenseDetailsEditPopupComponent implements OnInit {
  @Output() updategetlicensedetails: EventEmitter<any> = new EventEmitter();
  @Output() passData: EventEmitter<any> = new EventEmitter();
  @Input()
  licenseDetails: any;
  @Input()
  maxLicId: any;
  Edit: any;
  loadingShow = false; license: any; Lid: any; ProductLicenseValMsg = ''; DescriptionValMsg = '';
  ExpiredDateValMsg = '';
  Attachment: any; attachmentValMsg = ''; licenseType = '';
  ProductLicense = ''; Description = ''; ExpiredOn = '';  rowData = []; allDataArray = [];
  filname = ''; body;  filedata = ''; maxNewLid = '';
  constructor(public activeModal: NgbActiveModal, private getService: GetRequestService, private toastr: ToastrService,
              private datepipe: DatePipe, private getLicense: GetLicenseRequestService, private newreqservice: NewRequestService) {
  }

  ngOnInit() {
    this.Lid = this.licenseDetails.LID;
    this.licenseType = this.licenseDetails.License_Type;
    this.ProductLicense = this.licenseDetails.License;
    this.Description = this.licenseDetails.Description;
    const latestDate = this.datepipe.transform(this.licenseDetails[`End Date`], 'yyyy-MM-dd');
    this.ExpiredOn = latestDate;
    this.maxNewLid = this.maxLicId[0].New_LicID;

  }
  selectFile(event) {
    if (event.target.files.length > 0) {
      this.filname = event.target.files[0].name;
      this.body = event.target.files.item(0);
    }
  }
  sendDetails() {
    const data = { details: this.rowData, filename: this.filname, fileBody: this.body };
    this.allDataArray.push(data);
    this.passData.emit(this.allDataArray);
    // this.activeModal.close();
  }
  validation() {
    if (this.ProductLicense === '') {
      this.ProductLicenseValMsg = 'Please Enter Product License ';
      return false;
    }
    if (this.Description === '') {
      this.DescriptionValMsg = 'Please Enter Description';
      return false;
    }
    if (this.ExpiredOn === '') {
      this.ExpiredDateValMsg = 'Please Select Date';
      return false;
    }
    if (this.filname === '') {
      this.attachmentValMsg = 'Please Choose a File To Upload';
      return false;
    }
    // Need to add validation for attachment
    return true;
  }
  updateDetails() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
       Lid : this.Lid,
       License_Type : this.licenseType,
       ProductLicense : this.ProductLicense,
       Description : this.Description,
       ExpiredOn : this.ExpiredOn,
       Attachment: this.filedata,
       filename: this.filname,
       max_NewLid : this.maxLicId[0].New_LicID
      };
      console.log(dataToSend);
      const extension = this.filname.split('.');
      console.log('Extension=' + extension[1]);

      this.getService.editLicenseDetails(dataToSend).subscribe(res => {
        if (this.filname !== '') {
          if (extension[1] === 'json') {

            this.newreqservice.saveFile_json(this.body, res).subscribe(res1 => {
              this.toastr.success('License Details updated successfully.');
              this.loadingShow = false;
              this.updategetlicensedetails.next(true);
              this.activeModal.close();
            }, error => {
              this.toastr.error('Some error occured.');
              console.log(error);
              this.loadingShow = false;
              // this.clearAllData();
            });
        } else {

            this.newreqservice.saveFile(this.body, res).subscribe(res1 => {
              this.toastr.success('License Details updated successfully.');
              this.loadingShow = false;
              this.updategetlicensedetails.next(true);
              this.activeModal.close();
            }, error => {
              this.toastr.error('Some error occured.');
              console.log(error);
              this.loadingShow = false;
              // this.clearAllData();
            });
        }

        } else {
          this.toastr.success('License Details updated successfully.');
          this.loadingShow = false;
          // this.updategetlicensedetails.next(true);
          this.activeModal.close();
        }
      },
        error => {
          console.log(error);
          this.toastr.error('Some error occured.');
          this.loadingShow = false;
        });
    } else {
      this.loadingShow = false;
    }
  }
}
