import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewRequestService {
  private serviceUrl = '';
  configData: any = null;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.configData = this.config.getConfig();
    // this.serviceUrl = this.configData.ServiceUrl;
    this.serviceUrl = this.configData.ServiceUrl;
  }
  public saveData(requestData) {
    const fullserviceUrl = this.serviceUrl + 'qlik-createrequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public saveFile(filebody, url) {
    const fullserviceUrl = url;
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain', 'Content-Disposition': 'attachment' });
    return this.http.put(fullserviceUrl, filebody, { headers }).
    map((response: Response) => response);
  }
  public saveFile_json(filebody, url) {
    const fullserviceUrl = url;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Disposition': 'attachment' });
    return this.http.put(fullserviceUrl, filebody, { headers }).
    map((response: Response) => response);
  }
  public approveReject(requestData) {
    const fullserviceUrl = this.serviceUrl + 'qlik-approverequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public UpdateReject(requestData) {
    const fullserviceUrl = this.serviceUrl + 'qlik-editapprequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public uploads3(filename) {
    const url = this.serviceUrl + 'qlik-uploadtos3?filename=' + filename;
    return this.http.get(url).map(data => {
            return data;
        });
  }
}
