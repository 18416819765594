import { Component, OnInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../core/services/DataService';
import { AccessRequestData } from '../../../core/model/access-request.model';

import {NewRequestType} from '../../../core/services/enums';
import {Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-stream-level-fileshare',
  templateUrl: './stream-level-fileshare.component.html',
  styleUrls: ['./stream-level-fileshare.component.css'],
})
export class StreamLevelFileshareComponent implements OnInit {
  adgroups = [];
  removedadGroups: any[] = [];
  removedadGroupsText = '';
  removedadGrouplist;
  removedADCount = 0;
  newAdGroup = '';
  submitValMsg  = '';
  loadingShow = false;
  comments = '';
  environmentname = '';
  selectedstreamName = '';
  RequestTypeEnum = NewRequestType;
  @Input() DetailsFromStreamLevelAccess: AccessRequestData;
  @Output() backtoraiseRequest: EventEmitter<any> = new EventEmitter();
  constructor(private getDataService: DataService, private accessRequestService: AccessRequestService,
              private toastr: ToastrService , private route: Router, private modalService: NgbModal) {

  }
  ngOnInit() {
    this.getFileShareADGroupsByAppId(this.DetailsFromStreamLevelAccess.AccessApplicationTeamId);
    console.log (this.DetailsFromStreamLevelAccess);
    this.environmentname = this.DetailsFromStreamLevelAccess.Environment;
    this.selectedstreamName = this.DetailsFromStreamLevelAccess.selectedstreamName;
  }

  validation() {
    if (this.newAdGroup !== '' && (this.newAdGroup.includes(',') || this.newAdGroup.includes(';'))) {
      this.submitValMsg  = 'Add only one AD group to submit a request.';
      return false;
    }
    if (this.removedadGroupsText === '' && this.newAdGroup === '' ) {
      this.submitValMsg  = ' Atleast One AD group is mandatory.(Add New or remove from Existing AD group(s))';
      // this.submitValMsg  = 'Add an AD group or remove from existing AD group(s) to submit a request.';
      return false;
    } else if (this.comments === '') {
      console.log('Total AD Count =' + this.adgroups.length + ' + ' + (this.newAdGroup !== '' ? 1 : 0) +
      ' - ' + this.removedADCount + ' = ' + ((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount));
      if (((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount) > 1) {
        this.submitValMsg  = 'Please provide Business Justification for having more than one AD group';
        return false;
      }
    }
    // else if( this.newAdGroup === ''){
    //   this.submitValMsg  = 'Add atleast one AD group to submit a request.';
    //   return false;
    // }
    return true;
  }
  // openConfirmBox(content){
  //   if (this.validation () === true) {
  //     if (((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount) > 1) {
  //   const activeModal = this.modalService.open(content, { size: 'lg' });
  //     } else {
  //       this.Submit_Click(content);
  //     }
  //   }
  // }
  Submit_Click() {
    if (this.validation()) {
    this.loadingShow = true;
    if (this.validation () === true) {
      this.DetailsFromStreamLevelAccess.RequestTypeId = this.RequestTypeEnum.StreamFileShareAccess;
      if (this.newAdGroup !== '') {
        this.DetailsFromStreamLevelAccess.AddedADGroups = 'DIR\\' + this.newAdGroup;
      } else {
        this.DetailsFromStreamLevelAccess.AddedADGroups = '';
      }
      // Set "DIR\" at the begining
      if (this.removedadGroupsText !== '') {
        this.removedadGroupsText = this.removedadGroupsText.replace(/;/g, ';DIR\\');
        this.removedadGroupsText = 'DIR\\' + this.removedadGroupsText;
      }
      this.DetailsFromStreamLevelAccess.RemovedADGroups = this.removedadGroupsText;
      this.DetailsFromStreamLevelAccess.RequesterComments = this.comments;
      console.log(this.DetailsFromStreamLevelAccess);
      this.accessRequestService.saveAccessRequest(this.DetailsFromStreamLevelAccess).subscribe((res: any) => {
        if (res[0].Res === 'Success') {
        this.toastr.success('Access Request Submitted.');
        // window.location.pathname='/access/raiserequest';
        this.route.navigate(['/access/allrequest']);
        // debugger;
        // this.backtoraiseRequest.emit(true);
        } else {
          this.toastr.error('Some error occured.');
        }
        // this.modalService.dismissAll(content);
        this.clearAllData();
        this.loadingShow = false;
      },
        error => {
          console.log(error);
          // this.modalService.dismissAll(content);
          this.loadingShow = false;
          this.toastr.error('Some error occured.');
          this.clearAllData();
        });

    } } else {
      this.loadingShow = false;
    }
  }

  clearAllData() {
  }
  getFileShareADGroupsByAppId(appId) {
    this.loadingShow = true;
    this.accessRequestService.getFileShareADGroupsByAppId(appId).subscribe(res => {
      this.adgroups = res[0];
      this.adgroups.forEach(element => {
        if (element.name.includes('DIR\\')) {
          element.name = element.name.replace('DIR\\', '');
        }
      });
      console.log('retrived the add groups for the app id');
      console.log(res);
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }
  // Check box check/ uncheck enent capture for removing the selected item
  OnChange(e , selectedvalue) {
    console.log('selectedvalue' + selectedvalue);
    if (e.target.checked) {
      console.log('if');
      this.removedadGroups.push(selectedvalue);
    } else {
      console.log('else');
      this.removedadGroups.splice(this.removedadGroups.indexOf(selectedvalue), 1);
    }
    if (this.removedadGroups.length <= 0) {
      this.removedadGroupsText = '';
      this.removedADCount = 0;
    }
    this.submitValMsg  = '';
    console.log('removedadGroups length' + this.removedadGroups.length);
  }

  removeSelected_click()  {
    if (this.removedadGroups.length > 0) {
      this.removedADCount = 0;
      this.removedadGroupsText = '';
      this.removedadGrouplist =  this.adgroups.filter(a => this.removedadGroups.indexOf(a.AccessApplicationTeamFileShareADGroupId) > -1);
      this.removedadGrouplist.forEach(element => {
      this.removedadGroupsText += element.name +  ';';
      this.removedADCount++;
      });
      this.removedadGroupsText = this.removedadGroupsText.slice(0, -1);
  } else  {
    this.removedadGroupsText = '';
    this.removedADCount = 0;
  }
    this.submitValMsg  = '';
  }

}
