export class AccessRequestData {
    public RequestTypeId: number;
    public PlatformId: number;
    public PlatformURLId: number;
    public AccessApplicationTeamId: number;
    public AddedADGroups: string;
    public RemovedADGroups: string;
    public RequesterComments: string;
    public StreamDashboard: string;
    public Environment: string;
    public AddedAdmins: string;
    public RemovedAdmins: string;
    public ApplicationTeamDashboardId: string;
    public selectedstreamName: string;
  }
export class ViewAccessRequest {
    public Platform: any;
    public PlatformURL: any;
    public Environment: any;
    public AppName: any;
    public AddedADGroups: any;
    public RemovedADGroups: any;
    public RequestType: any;
    public Status: any;
    public RequestedBy: any;
    public RequesterComments: any;
    public ReviewedBy: any;
    public ReviewComments: any;
    public AccessApplicationTeamId: number;
    public RequestTypeId: number;
    public AccessRequestId: number;
    public AddedStreamAdmins: any;
    public RemovedStreamAdmins: any;
    public CurrentAdmins: any;
    public ApplicationTeamDashboards: string;
    public ApplicationTeamDashboardId: string;
  }
export class RequestReviewData {
    public RequestId: any;
    public StatusId: any;
    public Comments: any;

  }

