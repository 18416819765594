export enum ViewRequestType {
'Stream Level Access AD Group' =  3,
'Stream Level Access File Share AD Group' = 4,
'Stream Level Admin Access' = 5,
'App Level Security' = 6
}

export enum DropDownRequestType {
    'Stream Level Access AD Group' =  3,
'Stream Level Access File Share AD Group' = 4
}
export enum Environment {
    Stage = 'Stage',
    Production = 'Production'
}

export enum NewRequestType {
    StreamADAccess = 3,
    StreamFileShareAccess = 4,
    StreamAdminAcess = 5,
    AppLevelSecurity = 6
}

export enum ApprovalStatus {
    Pending = 101,
    Rejected = 102,
    Approved = 103,
    AdditionalInformationNeeded = 104,
    Completed  = 105,
    Failed = 106
}

export enum Platform {
    'Qlik Sense' = 2,
    'SSRS' = 3
}

