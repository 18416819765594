import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, ViewChild, SimpleChanges, EventEmitter } from '@angular/core';
import {NewRequestType} from '../../../core/services/enums';
import { AccessRequestData } from '../../../core/model/access-request.model';
import { DataService } from '../../../core/services/DataService';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-app-level-security',
  templateUrl: './app-level-security.component.html',
  styleUrls: ['./app-level-security.component.css'],
})

export class AppLevelSecurityComponent implements OnInit {
  @Input()  DetailsFromAccessRequest: AccessRequestData ;
  @Output() backtoRaiserequest: EventEmitter<any> = new EventEmitter();
  loadingShow = false;
  newAdGroup = '';
  adGroups = [];
  manageType = '';
  comments = '';
  commentsforRemove = '';
  submitValMsg = '';
  submitValMsg2 = '';
  submitValMsg1 = '';
  submitRemoveValMsg = '';
  dashboard;
  allAdGroup = [];
  allDashboard = [];
  removedadGroup = '0';
  dashboardForAd = [];
  selectedDashboard: any[] = [];
  selectedDashboardGuid: any[] = [];
  selectedDashboardRemoveGuid: any[] = [];
  selectedDashboardRemove: any[] = [];
  selectedRequestType = '0';
  environmentname = '';
  selectedstreamName = '';
  RequestTypeEnum: any = NewRequestType;
  RequestTypes;
  constructor(private getDataService: DataService, private toastr: ToastrService, private modalService: NgbModal,
              private accessRequestService: AccessRequestService,  private route: Router ) {
  }
  ngOnInit() {
    console.log( 'onin');
    console.log( this.DetailsFromAccessRequest);
    this.getDashboardDetails(this.DetailsFromAccessRequest.AccessApplicationTeamId, this.selectedRequestType);
    console.log (this.DetailsFromAccessRequest);
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    console.log('chenge event');
    console.log(changes);
    this.environmentname = this.DetailsFromAccessRequest.Environment;
    this.selectedstreamName = this.DetailsFromAccessRequest.selectedstreamName;
  }

  OnChange() {
    this.dashboardForAd = this.dashboard.filter(x => x.AdGroupName === this.removedadGroup);
    this.selectedDashboardRemove = [];
  }

  OnChangecheckbox(e , selectedvalue, selectedguid) {

    console.log('selectedvalue' + selectedvalue);
    console.log('selectedvalue' + selectedguid);
    if (e.target.checked) {
      console.log('if');
      this.selectedDashboard.push(selectedvalue);
      this.selectedDashboardGuid.push(selectedguid);
    } else {
      console.log('else');
      this.selectedDashboard.splice(this.selectedDashboard.indexOf(selectedvalue), 1);
      this.selectedDashboardGuid.splice(this.selectedDashboardGuid.indexOf(selectedvalue), 1);
    }
    if (this.selectedDashboard.length >= 1) {
      this.submitValMsg = '';
    }

    console.log('dashboard selected count' + this.selectedDashboard.length);
  }
  OnChangecheckboxRemove(e , selectedvalue, selectedremoveguid) {
    console.log('selectedvalue' + selectedvalue);
    if (e.target.checked) {
      console.log('if');
      this.selectedDashboardRemove.push(selectedvalue);
      this.selectedDashboardRemoveGuid.push(selectedremoveguid);
    } else {
      console.log('else');
      this.selectedDashboardRemove.splice(this.selectedDashboardRemove.indexOf(selectedvalue), 1);
      this.selectedDashboardRemoveGuid.splice(this.selectedDashboardRemoveGuid.indexOf(selectedremoveguid), 1);
    }
    if (this.selectedDashboardRemove.length >= 1) {
      this.submitRemoveValMsg = '';
    }

    console.log('removedadGroups length' + this.selectedDashboard.length);
  }
  getDashboardDetails(appId, requestType) {
    if (requestType === 'Add') {
     console.log('Add Selected');
    } else if (requestType === 'Remove') {
     console.log('Remove Selected');
 }
    this.loadingShow = true;
    const param = appId + '_' + requestType;
    this.accessRequestService.getDashBoardDetailsByAppId(param).subscribe(res => {
      this.dashboard = res[0];
      console.log(res[0]);
      console.log('retrived the add groups for the app id');
      this.allAdGroup = this.dashboard.filter(
        (thing, i, arr) => arr.findIndex(t => t.AdGroupName === thing.AdGroupName) === i
      );
      this.allDashboard = this.dashboard.filter(
        (thing, i, arr) => arr.findIndex(t => t.DashboardName === thing.DashboardName) === i
      );
      console.log(this.allAdGroup);
      console.log(this.allDashboard);
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }
  validationforAddADGroup() {
    if (this.selectedDashboard.length <= 0  ) {
      this.submitValMsg  = 'Select atleast one dashboard to submit a request.';

      return false;
    } else if (this.adGroups.length < 1 ) {
      this.submitValMsg  = 'Add atleast one AD group to submit a request.';
      return false;
    }

    return true;
  }
  validationforRemoveADgroup() {
    if (this.dashboardForAd.length <= 0) {
      this.submitRemoveValMsg  = 'Select atleast one AD group to submit a request.';
      return false;
    }
    if (this.selectedDashboardRemove.length <= 0 ) {
      this.submitRemoveValMsg  = 'Select atleast one dashboard to submit a request.';

      return false;
    }
    return true;
  }
  addAdGroup() {
    if (this.adGroups.indexOf(this.newAdGroup) < 0 && this.newAdGroup.trim() !== '') {
    this.adGroups.push(this.newAdGroup);
    }
    this.newAdGroup = '';
  }
  removeAdGroup(val) {
    this.adGroups.splice(this.adGroups.indexOf(val), 1);
  }
  submitClickAddGroup() {
    if (this.validationforAddADGroup()) {
      this.loadingShow = true;
      console.log(JSON.stringify(this.selectedDashboard));
      const selectedDashboardText = JSON.stringify(this.selectedDashboard).replace(/['"]+/g, '').
      replace(/,/g, ';').replace('[', '').replace(']', '');
      const selectedDashboardGuidText = JSON.stringify(this.selectedDashboardGuid).replace(/['"]+/g, '').
      replace(/,/g, ';').replace('[', '').replace(']', '');
      console.log('selectedDashboardText : ' + selectedDashboardText);
      const newAdGroupsText =   JSON.stringify(this.adGroups).replace(/['"]+/g, '').replace(/,/g, ';').replace('[', '').replace(']', '');
      console.log('newAdGroupsText : ' + newAdGroupsText);
      this.DetailsFromAccessRequest.AddedADGroups = newAdGroupsText;
      this.DetailsFromAccessRequest.StreamDashboard = selectedDashboardText;
      this.DetailsFromAccessRequest.ApplicationTeamDashboardId = selectedDashboardGuidText;
      this.DetailsFromAccessRequest.RemovedADGroups = '';
      this.DetailsFromAccessRequest.RequestTypeId = 6;
      this.DetailsFromAccessRequest.RequesterComments = this.comments;
      console.log(this.DetailsFromAccessRequest);
      this.accessRequestService.saveAllSecurityRequest(this.DetailsFromAccessRequest).subscribe((res: any) => {
        if (res[0].Res === 'Success') {
        this.toastr.success('Access Request Submitted.');
        // this.backtoRaiserequest.emit(true);
        this.route.navigate(['/access/allrequest']);
        this.resetForAddAdGroup();
        } else {
          this.toastr.error('Some error occured.');
        }
        this.route.navigate(['/access/allrequest']);
        // window.location.pathname='/access/raiserequest';
        this.clearAllData();
        this.loadingShow = false;
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.toastr.error('Some error occured.');
          this.route.navigate(['/access/raiserequest']);
          this.clearAllData();

        });

    } else {
      this.loadingShow = false;
    }
  }

  submitClickRemovGroup() {
    if (this.validationforRemoveADgroup()) {
      this.loadingShow = true;
      const selectedDashboardRemoveText = JSON.stringify(this.selectedDashboardRemove).replace(/['"]+/g, '').
      replace(/,/g, ';').replace('[', '').replace(']', '');
      const selectedDashboardRemoveGuidText = JSON.stringify(this.selectedDashboardRemoveGuid).replace(/['"]+/g, '').
      replace(/,/g, ';').replace('[', '').replace(']', '');
      this.DetailsFromAccessRequest.StreamDashboard = selectedDashboardRemoveText;
      this.DetailsFromAccessRequest.ApplicationTeamDashboardId =  selectedDashboardRemoveGuidText ;
      this.DetailsFromAccessRequest.RemovedADGroups = this.removedadGroup;
      this.DetailsFromAccessRequest.RequesterComments = this.commentsforRemove;
      this.DetailsFromAccessRequest.RequestTypeId = this.RequestTypeEnum.AppLevelSecurity;
      console.log(this.DetailsFromAccessRequest);
      this.accessRequestService.saveAllSecurityRequest(this.DetailsFromAccessRequest).subscribe((res: any) => {
        if (res[0].Res === 'Success') {
        this.toastr.success('Access Request Submitted.');
        // this.backtoRaiserequest.emit(true);
        this.route.navigate(['/access/allrequest']);
        this.resetForRemoveAdGroup();
        } else {
          this.toastr.error('Some error occured.');
        }
        this.clearAllData();
        // window.location.pathname='/access/raiserequest';
        this.route.navigate(['/access/allrequest']);

        this.loadingShow = false;
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.toastr.error('Some error occured.');
          this.route.navigate(['/access/raiserequest']);
          this.clearAllData();
        });

    } else {
      this.loadingShow = false;
    }
  }

  clearAllData() {
    this.submitRemoveValMsg = '';
    this.comments = '';
    this.newAdGroup = '';
    this.selectedDashboard = [];
    this.submitValMsg = '';
  }
  OnChangeReset() {
    this.comments = '';
    this.removedadGroup = '0';
    this.newAdGroup = '';
    this.selectedDashboard = [];
    this.selectedDashboardRemove = [];
    this.commentsforRemove = '';
    this.adGroups = [];
    this.submitRemoveValMsg = '';
    this.submitValMsg = '';
    this.getDashboardDetails(this.DetailsFromAccessRequest.AccessApplicationTeamId, this.selectedRequestType);
  }
  resetForRemoveAdGroup() {
    this.selectedRequestType = '0';
    this.selectedDashboardRemove = [];
    this.removedadGroup = '0';
    this.OnChange();

  }
  resetForAddAdGroup() {
    this.selectedRequestType = '0';
  }
}
