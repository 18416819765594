import { GetRequestService } from './../../../core/services/get-request.service';
import { LicenseDetailsEditButtonComponent } from '../license-details-edit-button/license-details-edit-button.component';
import { DownloadLicensePopupComponent } from './../download-license-popup/download-license-popup.component';
import { Component, OnInit } from '@angular/core';
import {GetLicenseRequestService} from '../../../core/services/get-license-request.service';
import { Router } from '@angular/router';
import {DatePipe} from '@angular/common';
import { DownloadButtonRequestComponent } from '../download-button/download-button.component';

import { LicenseDetailsEditPopupComponent } from '../license-details-edit-popup/license-details-edit-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rebar-license-qlikviewall',
  templateUrl: './license-qlikviewall.component.html',
  styleUrls: ['./license-qlikviewall.component.css']
})
export class LicenseQlikviewallComponent {
  columnDefs;
  params;
  maxNewLid: any;
  frameworkComponents: any;
  userType = 'allow';
  role = '';
  isoperation: boolean;
  rowData = []; loadingShow = false;

  constructor(private getLicense: GetLicenseRequestService, private modalService: NgbModal,
              private router: Router, private datepipe: DatePipe, private getService: GetRequestService) {
      this.getService.getRole().subscribe(res => {
           this.role = res[0][0].role;
           this.isoperation = this.role === 'Ops';
      }, );
      this.frameworkComponents = {
      download : DownloadButtonRequestComponent,
      edit: LicenseDetailsEditButtonComponent
    };
      this.columnDefs = [
    {headerName: 'Product License', field: 'License', width: 350, resizable: true},
    {headerName: 'Description', field: 'Description', width: 250, resizable: true},
    {headerName: 'End Date', field: 'End Date', width: 250 , resizable: true, cellRenderer: (data) => {
      return this.datepipe.transform(data.value, 'yyyy-MM-dd');
    }},
    {headerName: 'Download', field: 'DownloadPath', width: 120, resizable: true, cellRenderer: 'download',
        cellRendererParams: {
          onClick: this.licensedownload.bind(this),
          label: 'Download'
        }, cellClass: 'grid-cell-centered' },
        {headerName: 'Edit', field: 'Edit', width: 110, cellRenderer: 'edit',
      cellRendererParams: {
        onClick: this.licenseEdit.bind(this),
        label: 'Edit'
      }, cellClass: 'grid-cell-centered' , hide: this.isoperation },
];

  const urlArray = [{ name: 'View All', path: 'license/qlikviewall' }];
  getService.changeUrl(urlArray);
}
// tslint:disable-next-line: use-lifecycle-interface
ngOnInit() {
  this.getlicensedetails();
}

getlicensedetails() {
  this.loadingShow = true;
  this.getLicense.getOperationsData()
  .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
  .subscribe(
    data1 => {
      console.log(data1[1]);
      this.rowData = data1[1];
      this.maxNewLid = data1[2];
      this.loadingShow = false;
      console.log('rowdata =' + this.rowData );
    },
    error => {
      this.loadingShow = false;
      this.rowData = [];
      console.log(error);
    }
  );
}


licensedownload(e) {
  const modelRef = this.modalService.open(DownloadLicensePopupComponent,  { size: 'xm' });
   // modelRef.componentInstance.isView = true;
  modelRef.componentInstance.DownloadPathfile = e.rowData.DownloadPath;
  modelRef.componentInstance.licenseType = e.rowData.License_Type;


}
licenseEdit(e) {
    const modelRef = this.modalService.open(LicenseDetailsEditPopupComponent,  { size: 'xl' });
    modelRef.componentInstance.licenseDetails = e.rowData;
    modelRef.componentInstance.maxLicId = this.maxNewLid;
    modelRef.componentInstance.updategetlicensedetails.subscribe((emmitedValue) => {
      this.getlicensedetails();
  });

}

}
