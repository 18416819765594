import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {NewRequestComponent} from './new-request/new-request.component';
import { ApproveRequestComponent } from './approve-request/approve-request.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FileDetailsComponent } from './file-details/file-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';


@NgModule({
    declarations: [NewRequestComponent, ApproveRequestComponent, FileDetailsComponent
    ],
    imports: [
        CommonModule, FormsModule, NgbModule, MatAutocompleteModule, AgGridModule,
        MatStepperModule, MatDatepickerModule,
        MatInputModule, MatFormFieldModule, ReactiveFormsModule, AutocompleteLibModule
    ],
    providers: [DatePipe],
    exports: []
})
export class UploaddocumentModule { }
