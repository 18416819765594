import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';
import {throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GetRequestService {

  private url = new BehaviorSubject([{name: '', path: 'dashboard'}]);
  currentUrl = this.url.asObservable();

  private user = new BehaviorSubject([]);
  userType = this.user.asObservable();

  private authUser = new BehaviorSubject(true);
  useraccess = this.authUser.asObservable();

  private serviceUrl = '';
  private peoplePickerUrl = '';
  configData: any = null;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.configData = this.config.getConfig();
   // this.serviceUrl = this.configData.ServiceUrl;
    this.serviceUrl = this.configData.ServiceUrl;
  //  this.peoplePickerUrl = this.configData.PeoplePickerUrl;
  }
  public getAllRequest(fromDate, toDate) {
    const url = this.serviceUrl + 'qlikonboardinggetallrequest?fromdate='
    + fromDate + '&todate=' + toDate;
    return this.http.get(url).map(data => {
            return data;
        });
}
changeUrl(url: any) {
  this.url.next(url);
}
getUserType(user: any) {
  this.user.next(user);
}
authoriseUser(User: any) {
  this.authUser.next(User);
}

public getRequestById(requestId) {
  const url = this.serviceUrl + 'qlikgetrequestbyid?&requestid=' + requestId;
  return this.http.get(url).map(data => {
          return data;
      });
}
public getDashboardDetails(requestId) {
  const url = this.serviceUrl + 'qlik-getdashboard?&requestid=' + requestId;
  return this.http.get(url).map(data => {
          return data;
      });
}
public getPeople() {
  const url = this.peoplePickerUrl;
  return this.http.get(url).map(data => {
          return data;
      });
}
public editLicenseDetails(requestData) {
  const fullserviceUrl = this.serviceUrl + 'license-editlicense';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.post(fullserviceUrl, requestData, { headers }).
  map((response: Response) => response);
}
public getRole() {
  const url = this.serviceUrl + 'qlikgetuserroles?requestid=pawan.kumar.pal' ;
  return this.http.get(url).map(data => {
          return data;
      }, catchError(this.handleError));
}
public downloadFile(reqId, licenseType) {

  const url = this.serviceUrl + 'qlik-uploadtos3?filename=' + reqId + '&License_Type=' + licenseType;
  return this.http.get(url).map(data => {
          return data;
      });
}

handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // client-side error
    errorMessage = `Error: ${error.error.message}`;
  } else {
    // server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  window.alert(errorMessage);
  return throwError(errorMessage);
}
}
