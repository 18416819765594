export class NewRequest {
    public questionId: number;
    public question: string;
    public response;
    public valMsg: string;
  }
export const data = [{ other: '', response: '', questionId: 1, question: 'Platform', valMsg: ''},
  {other: '', response: '', questionId: 2, question: 'Organization', valMsg: ''},
  {other: '', response: false, questionId: 3, question: 'Are you Onboarded to CAP/Data Lake Platform', valMsg: ''},
  {other: '', response: '', questionId: 4, question: 'Demand ID', valMsg: ''},
  {other: '', response: '', questionId: 5, question: 'Team Name', valMsg: ''},
  {other: '', response: '', questionId: 6, question: 'Project Name', valMsg: ''},
  {other: '', response: 'Cio', questionId: 7, question: 'Group', valMsg: ''},
  {other: '', response: '', questionId: 8, question: 'Have you checked our existing assets', valMsg: ''},
  {other: '', response: '', questionId: 9, question: 'Explain why an existing standard solution won’t meet the business needs', valMsg: ''},
  {other: '', response: '', questionId: 10, question: 'Why QlikSense or QlikView', valMsg: ''},
  {other: '', response: '', questionId: 11, question: 'QlikView/QlikSense usage aligns to their Business Case', valMsg: ''},
  {other: '', response: '', questionId: 12, question: 'Type of Data Source', valMsg: ''},
  {other: '', response: '', questionId: 13,
   question: 'Data sources the data is extracted from to create the visualization', valMsg: ''},
  {other: '', response: [], questionId: 14, question: 'List all Domains of Enterprise Data used', valMsg: ''},
  {other: '', response: '', questionId: 15, question: 'Do you have Data Owner s Approval? If Yes EID of who approved.', valMsg: ''},
  {other: '', response: '', questionId: 16, question: 'EID of approver', valMsg: ''},
  {other: '', response: '', questionId: 17, question: 'Period of data planned to retain', valMsg: ''},
  {other: '', response: '', questionId: 18, question: 'Frequency of Data Refresh', valMsg: ''},
  {other: '', response: '', questionId: 19, question: 'Time Refresh will be scheduled', valMsg: ''},
  {other: '', response: 0 , questionId: 20, question: 'Volume of Data Anticipated in production', valMsg: ''},
  {other: '', response: '', questionId: 21, question: 'Plan to consume the Qlik Dashboard', valMsg: ''},
  {other: '', response: '', questionId: 22, question: 'Who are the Customers, End Users', valMsg: ''},
  {other: '', response: [], questionId: 23, question: 'Your Team and Role with the Project', valMsg: ''},
  {other: '', response: '', questionId: 24, question: 'Sponsor', valMsg: ''},
  {other: '', response: '', questionId: 25, question: 'Business Contact', valMsg: ''},
  {other: '', response: '', questionId: 26, question: 'Delivery/Capability Lead ', valMsg: ''},
  {other: '', response: '', questionId: 27, question: 'Delivery /Project Manager', valMsg: ''},
  {other: '', response: '', questionId: 28, question: 'Project POC', valMsg: ''},
  {other: '', response: '', questionId: 29, question: 'Project Active Directory Mailing Group', valMsg: ''},
  {other: '', response: '', questionId: 30, question: 'Group Location(s)', valMsg: ''},
  {other: '', response: '', questionId: 31, question: 'CIO Contact', valMsg: ''},
  {other: '', response: 'internal', questionId: 32, question: 'Use (Internal / External)', valMsg: ''},
  {other: '', response: new Date(), questionId: 33, question: 'Target Deployment Date', valMsg: ''},
  {other: '', response: '', questionId: 34, question: 'Business Case', valMsg: ''},
  {other: '', response: '', questionId: 35, question: 'Financial - Cost Reduction', valMsg: ''},
  {other: '', response: '', questionId: 36, question: 'Financial - Cost Avoidance', valMsg: ''},
  {other: '', response: '', questionId: 37, question: 'Speed & Effectiveness', valMsg: ''},
  {other: '', response: '', questionId: 38, question: 'Strategic Impact', valMsg: ''},
  {other: '', response: '', questionId: 39, question: 'Number of Reports (QlikSense: Apps or QlikView: QVWs)', valMsg: ''},
  {other: '', response: '', questionId: 40, question: 'Number of Users(Total)', valMsg: ''},
  {other: '', response: '', questionId: 41, question: 'Number of Concurrent Users', valMsg: ''},
  {other: '', response: '', questionId: 42, question: 'Developer Readiness', valMsg: ''},
  {other: '', response: '', questionId: 43, question: 'Scope', valMsg: ''},
  {other: '', response: '', questionId: 44, question: 'Data', valMsg: ''},
  {other: '', response: '', questionId: 45, question: 'Does your Report has PII Data?', valMsg: ''},
  {other: '', response: '', questionId: 46, question: 'Do you have Data Privacy Plan?', valMsg: ''},
  {other: '', response: true, questionId: 47, question: 'You Team will be responsible for Data Privacy Review and to stay complaint' +
            +'as per Accentures Complaince Policies', valMsg: ''},
  {other: '', response: '', questionId: 48, question: 'Why volume data is more than 2 million', valMsg: ''},
  {other: '', response: 'EII', questionId: 49, question: 'EII or Non-EII', valMsg: ''},
  {other: '', response: '', questionId: 50, question: 'AI Leads', valMsg: ''},
  {other: '', response: '', questionId: 51, question: 'AIRID', valMsg: ''}  ];
