import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector : 'app-access-ops-review-button',
    templateUrl : './access-ops-review-button.component.html',
    styleUrls : ['./access-ops-review-button.component.css']
})

export class AccessOPSReviewButtonComponent implements ICellRendererAngularComp {

    constructor() {}

    rowData;
    params;
    label: string;
    agInit(params): void {
        this.params = params;
        this.rowData = params.data;
        this.label = this.params.label || null;
    }
    refresh(params?: any): boolean {
        return true;
    }
    onClick($event) {
        if (this.params.onClick instanceof Function) {
        const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      };
        this.params.onClick(params);

    }
  }
  isEnable() {
    if (this.rowData.Status === 'Pending') {
    return true;
    } else {
        return false;
    }
}
}
