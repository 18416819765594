import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { CancelReviewService } from '../../../core/services/cancel-review.service';


@Component({
  selector: 'rebar-cancel-review',
  templateUrl: './cancel-review.component.html',
  styleUrls: ['./cancel-review.component.css']
})
export class CancelReviewComponent implements OnInit {

  @Input()
  RequestId: any;
  

  constructor( private toastr: ToastrService, public activeModal: NgbActiveModal,private cancelReviewService: CancelReviewService) { }

  ngOnInit() {
   
  }

  closeBox() {
    this.activeModal.close();
  }

  /**
   * cancel the whole process of review and deply of the requested docs
   */
   public cancelReviewData() {
    this.cancelReviewService.cancelReview(this.RequestId)
    .subscribe((data) => {
       
          this.toastr.success('Review cancelled successfully');
        
         
        this.activeModal.close("Cross click");
        this.cancelReviewService.changeMessage('');
      },
     // error: error => {
      //  this.toastr.error('Error Occured');
        

     // }
      );
  }
}
