import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CancelReviewService {
  private serviceUrl = '';
    configData: any = null;
    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();
    constructor(public http: HttpClient, private config: AppConfigService) {
      this.configData = this.config.getConfig();
      this.serviceUrl = this.configData.ServiceUrl;
    }
  /**
   * for grid refresh after cancel
   * @param message ;
   */
    changeMessage(message: string) {
      this.messageSource.next(message);
    }
    /**
     * cancel review of the doc
     * @param docId ;
     * @param reqId ;
     * @param tblName ;
     */
    cancelReview(RequestId) {
      
      const fullserviceUrl = this.serviceUrl + 'license-cancelrequest';
       const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(fullserviceUrl, RequestId, { headers }).

    map((response: Response) => response);
    }
  
 
}
