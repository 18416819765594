import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-license-add-popup',
  templateUrl: './add-license-popup.component.html',
  styleUrls: ['./add-license-popup.component.css']
})

export class AddLicensePopUpComponent implements OnInit {

    expiryDate = new Date();
    expireVal = '';
    constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }
    popOpen(p: NgbPopover, val): void {
      if (val > 2000000) {
        p.open();
      } else {
        p.close();
      }
    }
    closePopOver(p: NgbPopover) {
      p.close();
    }
    ngOnInit() {
    }
    setExpiryDate() {
      this.expireVal = this.expiryDate.getFullYear() + '-' + (this.expiryDate.getMonth() + 1) + '-' + this.expiryDate.getDate();
      console.log(this.expireVal);
    }
  }
