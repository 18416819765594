import { Component, OnInit, Input, Pipe } from '@angular/core';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { LicenseHistoryRecord } from './licenseHistory';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-license-pool-popup',
  templateUrl: './license-pool-popup.component.html',
  styleUrls: ['./license-pool-popup.component.css']
})
export class LicensePoolPopupComponent implements OnInit {
  licId;
  loadingShow = false;
  licenseHistory: Array<LicenseHistoryRecord> = [];
  @Input()
  LicenseID: any;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
              private datepipe: DatePipe, private getService: GetLicenseRequestService) { }

  ngOnInit() {
    this.loadingShow = true;
    this.getRequestById();
  }
  getRequestById() {
    this.loadingShow = true;
    this.licenseHistory = [];
    this.getService.getLicenseHistoryById(this.LicenseID)
      .take(1).
      subscribe(
        data1 => {
          console.log(data1[0]);
          data1[0].forEach(element => {
            const historyObj = new LicenseHistoryRecord();
            historyObj.owner = element.LicenseOwner;
            historyObj.approvalDate = this.datepipe.transform(element.ApprovedDate, 'yyyy-MM-dd');
            this.licenseHistory.push(historyObj);
          });
          console.log(this.licenseHistory);
          this.loadingShow = false;
        },
        error => {
          console.log(error);
          this.loadingShow = false;
        }
      );
  }
}
