import { Component, OnInit, Input } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { ToastrService } from 'ngx-toastr';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';

@Component({
  selector: 'app-license-release-request',
  templateUrl: './license-release-request.component.html',
  styleUrls: ['./license-release-request.component.css']
})
export class LicenseReleaseRequestComponent implements OnInit {
  @Input()
  appName;
  txtfilname = ''; txtBody; applicationTeamName = ''; platform = ''; licenseOwner = '';
  comment = ''; approver = ''; appDisable = true;
  requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = ''; licenseOwnerValMsg = ''; commentValMsg = '';
  loadingShow = false; regex = '[a-zA-Z.]*'; approverValMsg = '';
  constructor(private createSerive: CreateLicenseRequestService,
              private getService: GetRequestService, private getLicenseService: GetLicenseRequestService,
              private toastr: ToastrService) {
    const urlArray = [{ name: 'liscence', path: '/liscence/createrequest' }];
    this.getService.changeUrl(urlArray);
   }
   ngOnInit() {
    if (this.appName.Application_Name !== undefined) {
      this.applicationTeamName = this.appName.Application_Name;
      this.getApplicationData(this.applicationTeamName);
    } else {

      this.applicationTeamName = this.appName;
      console.log(this.appName);
    }
  }
  getApplicationData(appName) {
    this.loadingShow = true;
    this.getLicenseService.getApplicationDetails(appName, 'Release').subscribe((res: any) => {
      if (res[0].length > 0) {
        this.platform = res[0][0].PlatformId;
        this.approver = res[0][0].WBSEApprovedBy;
      }
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
    });
  }
  validation() {
    if (this.applicationTeamName === '') {
      this.applicationTeamNameValMsg = 'Please enter Application team name';
      return false;
    }
    if (this.platform === '') {
      this.platformValMsg = 'Please Select platform';
      return false;
    }
    if (this.licenseOwner === '') {
      this.licenseOwnerValMsg = 'Please Enter Current Owner EID';
      return false;
    }
    if (this.approver === '') {
      this.approverValMsg = 'Please Enter Approver Name';
      return false;
    }
    if (this.comment === '') {
      this.commentValMsg = 'Please Enter Description';
      return false;
    }
    return true;
   }

   approverEid() {
    this.approver = this.approver.split('@')[0]
    this.approver =  this.approver;
    const checkEmail = this.approver.match(this.regex);   
    if(checkEmail[0] === this.approver)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.approverValMsg=''
    }  
    else
    {
      this.approverValMsg = 'Invalid EID,Please Enter valid EID'
      this.approver = '';
    }  
}
  
licenseOwnerEid(){
  this.licenseOwner = this.licenseOwner.split('@')[0]
    this.licenseOwner =  this.licenseOwner;
    const checkEmail = this.licenseOwner.match(this.regex);   
    if(checkEmail[0] === this.licenseOwner)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.licenseOwnerValMsg=''
    }  
    else
    {
      this.licenseOwnerValMsg = 'Invalid EID,Please Enter valid EID'
      this.licenseOwner = '';
    } 
}


   saveRequest() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        AppTeamName: this.applicationTeamName,
        PlatformId: this.platform,
        LicenseCategory: null,
        LicenseRequestType: null,
        RequesterOrganizationGroup: null,
        AIRID: null,
        NumberOfLicenses: null,
        WBSE: null,
        WBSEApprovedBy: this.approver,
        GeneralLedgerNo: null,
        Comments: this.comment,
        CreatedDate: null,
        AssignmentDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        Status: null,
        Req_Flag: 'Release',
        License_Owner : this.licenseOwner,
        New_License_Owner : null,
        HasEmployee_Attachment : null,
        Attachment : null,
        Transfer_Release_Comment : this.comment,
        OpsComment : null
      };
      this.createSerive.saveData(dataToSend).subscribe(res => {
        this.toastr.success('Request saved successfully.');
        this.clearAllData();
        this.loadingShow = false;
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
   clearAllData() {
     // this.applicationTeamName = '';
     this.platform = ''; this.licenseOwner = '';
     this.comment = '';
   }
   clearAllDatacancel() {
    this.appDisable = false;
    this.applicationTeamName = ''; this.platform = '';
    this.licenseOwner = '';
    this.comment = '';
  }
   checkPlatform() {
    if (this.platform === '') {
      this.platformValMsg = 'Please select platform';
      this.licenseOwner = '';
      return false;
   }
    return true;
  }
  resetOwnerField() {
    this.licenseOwner = '';
  }
  checkUser(type) {
    if (!this.checkPlatform()) {
      return false;
    }
    if (type === 'old' && this.licenseOwner !== '') {
    this.loadingShow = true;
    let platformId;
    if (this.platform.toString() === '1' ) {
      platformId = 1001;
    } else {
      platformId = 1002;
    }
    this.getLicenseService.verifyUser(this.licenseOwner, platformId, type).subscribe(res => {
      if (res[0][0].count < 1) {
            this.licenseOwner = '';
            this.licenseOwnerValMsg = 'No license issued to this user';
        }
      this.loadingShow = false;
    }, error => {
console.log(error);
    });
  }
  }
}
