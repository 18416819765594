import { GetRequestService } from './../../../core/services/get-request.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-rebar-download-license-popup',
  templateUrl: './download-license-popup.component.html',
  styleUrls: ['./download-license-popup.component.css']
})
export class DownloadLicensePopupComponent implements OnInit {
loadingShow = false;
@Input()
DownloadPathfile: any;
@Input()
licenseType: any;
   x = '' ;
  // @Input()

  constructor(public activeModal: NgbActiveModal, private getService: GetRequestService) { }

  ngOnInit() {
   // this.downloadFile();
  }

   downloadFile() {
     console.log(this.DownloadPathfile);
     this.loadingShow = true;
     this.getService.downloadFile(this.DownloadPathfile, this.licenseType).subscribe((res: any) => {
      for (let i = 0; res.length > i; i++) {
        window.location.href = res[i];
       // window.location.href =x;
      }
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
      console.log(error);
    });
    // this.getService.UpdateDownloadhistory(this.License_Type).subscribe((res: any) => {
    // });
  }


}
