import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditButtonComponent } from './../edit-button/edit-button.component';
import { Component, OnInit } from '@angular/core';

import { ViewButtonRequestComponent } from '../view-button/view-button.component';
import { LicenseViewPopupComponent } from '../license-view-popup/license-view-popup.component';
import { LicenseTransferPopUpComponent } from '../license-transfer-popup/license-transfer-popup.component';
import { LicenseApprovalPopUpComponent } from '../license-approval-popup/license-approval-popup.component';
import { LicenseReleasePopUpComponent } from '../license-release-popup/license-release-popup.component';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import {DatePipe} from '@angular/common';
import {GetRequestService} from '../../../core/services/get-request.service';
import { CancelButtonComponent } from '../cancel-button/cancel-button.component';
import { CancelReviewComponent } from '../cancel-review/cancel-review.component';



@Component({
  selector: 'app-license-all-request',
  templateUrl: './license-all-request.component.html',
  styleUrls: ['./license-all-request.component.css']
})
export class LicenseAllRequestComponent implements OnInit {
  userType = '';
  columnDefs;
  filterData: any; displayedColumns = '';
  public childGrid: any; public allData: any; public parentData: any;  parentChildData: any;
  toDate = new Date(); fromDate = new Date(this.toDate.getFullYear(), (this.toDate.getMonth()), this.toDate.getDate() - 30);
  frameworkComponents: any;
  fromval = ''; toval = '';
  rowData = []; loadingShow = false;
  defaultColDef = { resizable: true };
  constructor(private modalService: NgbModal, private getService: GetLicenseRequestService,
              private datepipe: DatePipe, private service: GetRequestService) {

    this.frameworkComponents = {
      cancel : CancelButtonComponent,
      view : ViewButtonRequestComponent,
      edit : EditButtonComponent
     
    };

    this.userType = localStorage.getItem('userType');
    const urlArray = [{ name: 'Request New License', path: '/license/createrequest' }];
    this.service.changeUrl(urlArray);
    this.columnDefs = [
      {headerName: 'ID', field: 'Req_ID', width: 80},
      {headerName: 'Platform', field: 'Platform', width: 100, filter: 'agTextColumnFilter'},
      {headerName: 'Team Name', field: 'AppTeamName', width: 175 , filter: 'agTextColumnFilter'},
      {headerName: 'Requested By', field: 'RequesterName', width: 160, filter: 'agTextColumnFilter'},
      {headerName: 'Created On', field: 'CreatedDate', width: 120 , cellRenderer: (data) => {
        return this.datepipe.transform(data.value, 'yyyy-MM-dd');
      }},
      {headerName: 'Request Type', field: 'Req_Flag', width: 120 , filter: 'agTextColumnFilter',
      cellRenderer: (data) => {
        return data.value === 'New_Req' ? 'Procure' : data.value;
      }
    },
      {headerName: 'Manager Status', field: 'ManagersApproval', width: 180 , filter: 'agTextColumnFilter',
      cellClass: (data) => this.statusColor(data.value)},
      {headerName: 'Ops Status', field: 'Status', width: 180 , filter: 'agTextColumnFilter',
      cellClass: (data) => this.statusColor(data.value)},

      {headerName: 'Cancel', width: 80, cellRenderer: 'cancel',
      cellRendererParams: {
        onClick: this.licenseRequestCancel.bind(this),
        label: 'Cancel'
      }, cellClass: 'grid-cell-centered', },

      {headerName: 'View', width: 80, cellRenderer: 'view',
      cellRendererParams: {
        onClick: this.licenseRequestView.bind(this),
        label: 'View'
      }, cellClass: 'grid-cell-centered' },
      
      {headerName: 'Edit', width: 80, cellRenderer: 'edit',
      cellRendererParams: {
        onClick: this.licenseRequestEdit.bind(this),
        label: 'Edit'
      }, cellClass: 'grid-cell-centered', },
      
      ];
  }

  ngOnInit() {
    const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllLicenseRequest(fromInput, to);
  }
  searchByDate() {
    const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllLicenseRequest(fromInput, to);
    }

  setFromDate() {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    console.log(this.fromval);
  }
  setToDate() {
    this.toval = this.toDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    console.log(this.toval);
  }
  getAllLicenseRequest(fromDate, toDate) {
    this.loadingShow = true;
    this.getService.getAllRequest(fromDate, toDate, this.userType)
    .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
    .subscribe(
      data1 => {
        this.rowData = data1[0];
        this.loadingShow = false;
      },
      error => {
        this.parentData = error;
        this.rowData = [];
        this.loadingShow = false;
      }
    );
  }

  licenseRequestCancel(e) {
    console.log(e);
    const modelRef = this.modalService.open(CancelReviewComponent,  { size: 'medium' });
    modelRef.componentInstance.isCancel = 1;
     modelRef.componentInstance.RequestId = e.rowData.Req_ID ; 
     modelRef.result.then((result) => {
      const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
      const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
     this.getAllLicenseRequest(fromInput, to);
      //console.log(this.fromDate+ "---" + this.toDate);
    });
     //modelRef.closed.subscribe(res=>{

     // setTimeout(() =>{ this.reportList() }, 1000);

    //})
  }
  
  licenseRequestView(e) {
    if (e.rowData.Req_Flag === 'New_Req') {
      const modelRef = this.modalService.open(LicenseViewPopupComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Transfer') {
      const modelRef = this.modalService.open(LicenseTransferPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Release') {
      const modelRef = this.modalService.open(LicenseReleasePopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 1;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
  }
  licenseRequestEdit(e) {
    if (e.rowData.Req_Flag === 'New_Req') {
      const modelRef = this.modalService.open(LicenseViewPopupComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 2;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Transfer') {
      const modelRef = this.modalService.open(LicenseTransferPopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 2;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
    if (e.rowData.Req_Flag === 'Release') {
      const modelRef = this.modalService.open(LicenseReleasePopUpComponent,  { size: 'xl' });
      modelRef.componentInstance.isView = 2;
      modelRef.componentInstance.RequestId = e.rowData.Req_ID;
    }
  }
  
  statusColor(status) {
    return (status === 'Approved' || status === 'Released') ? 'enableColor' : (status === 'Rejected' ? 'cancelColor' : (status === 'Pending' ? 'pendingColor' : 'pendingColor' ));
    }
}

