import { Component, OnInit , Output , EventEmitter } from '@angular/core';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { AccessGetRequestService } from '../../../core/services/access-get-request.service';
import { ViewAccessRequest } from '../../../core/model/access-request.model';
import { RequestReviewData } from '../../../core/model/access-request.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import {ViewRequestType} from '../../../core/services/enums';
import {ApprovalStatus} from '../../../core/services/enums';

@Component({
    selector: 'app-access-ops-review-popup',
    templateUrl: './access-ops-review-popup.component.html',
    styleUrls: ['./access-ops-review-popup.component.css']
  })
  export class AccessOPSReviewPopupComponent implements OnInit {
    accessRequestId;
    comments = '';
    platfromURL;
    platform;
    requestReviewData = new RequestReviewData();
    accessRequestDetails = new ViewAccessRequest();
    loadingShow = false;
    submitValMsg = '';
    RequestTypeEnum = ViewRequestType;
    ApprovalStatusEnum = ApprovalStatus;
    @Output() dataToAllRequest: EventEmitter<any> = new EventEmitter();
    constructor(private accessRequestService: AccessRequestService, public activeModal: NgbActiveModal,
                private toastr: ToastrService, private accessGetRequestService: AccessGetRequestService) {

    }
    ngOnInit() {
      this.getRequestDetails(this.accessRequestId);
    }

    validation() {
      if (this.comments === '' ) {
        this.submitValMsg  = 'Please provide Review comments for any actions.';
        return false;
      }
      return true;
    }
    approveReject(review) {
      // if ( review === 'Rejected' ) {
      if (this.validation() === false) {
          return false;
        }
      // }
      this.requestReviewData.RequestId = this.accessRequestId;
      this.requestReviewData.Comments = this.comments;
      this.requestReviewData.StatusId = this.ApprovalStatusEnum[review];
      this.loadingShow = true;
      console.log(this.requestReviewData);
      this.accessRequestService.reviewRequest(this.requestReviewData).subscribe((res: any) => {
        if (res[0].Res === 'Success') {
        this.toastr.success('Access Request Submitted.');
        this.dataToAllRequest.emit(1);
        } else {
          this.toastr.error('Some error occured.');
          this.dataToAllRequest.emit(0);
        }
        // this.clearAllData();
        this.loadingShow = false;
        this.activeModal.close();
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          this.dataToAllRequest.emit(0);
          this.toastr.error('Some error occured.');
          // this.clearAllData();
        });
    }

    getRequestDetails(accessRequestId) {
      this.loadingShow = true;
      this.accessGetRequestService.getAccessRequestById(accessRequestId).subscribe(res => {
        console.log(res[0][0]);
        this.accessRequestDetails = res[0][0];
        this.accessRequestDetails.RequestTypeId = Number(this.RequestTypeEnum[this.accessRequestDetails.RequestType]);
        console.log('retrived access request details 1');
        console.log(this.accessRequestDetails);
        console.log('retrived access request details 2');
        this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
     });
    }
    next(type, stepper: MatStepper) {
      stepper.next();
    }
    previous(stepper: MatStepper) {
      console.log(stepper);
      this.comments = '';
      stepper.previous();
      this.submitValMsg = '';
    }
  }
