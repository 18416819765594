import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetLicenseRequestService {
  private serviceUrl = '';
  configData: any = null;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.configData = this.config.getConfig();
   // this.serviceUrl = this.configData.ServiceUrl;
    this.serviceUrl = this.configData.ServiceUrl;
  }
  public getAllRequest(fromDate, toDate, userType) {  
    const url = this.serviceUrl + 'license-allrequest?fromdate='
    + fromDate + '&todate=' + toDate + '&usertype=' + userType;
    return this.http.get(url).map(data => {
            return data;
        });
  }
public getOperationsData() {
  const url = this.serviceUrl + 'license-getoperationdetails';
  return this.http.get(url).map(data => {
          return data;
      });
}
public getRequestById(requestId, type) {
  const url = this.serviceUrl + 'license-getrequestbyid?&requestid=' + requestId + '&req_type=' + type;
  return this.http.get(url).map(data => {
          return data;
      });
}
public verifyUser(eid, platform, type) {
  const url = this.serviceUrl + 'license-verifyuser?&eid=' + eid + '&platform=' + platform + '&type=' + type;
  return this.http.get(url).map(data => {
          return data;
      });
}
public getLicenseHistoryById(licid) {
  const url = this.serviceUrl + 'license-getlicensehistory?&requestid=' + licid;
  return this.http.get(url).map(data => {
          return data;
      });
}
public getAllAsignedRequest() {
  const url = this.serviceUrl + 'license-getallassignedlicense';
  return this.http.get(url).map(data => {
          return data;
      });
}
public getLicenseCount() {
  const url = this.serviceUrl + 'license-getlicensecount';
  return this.http.get(url).map(data => {
          return data;
      });
}
public getapplicationName() {
  const url = this.serviceUrl + 'license-getapplications';
  return this.http.get(url).map(data => {
          return data;
      });
}
public getApplicationDetails(appName, reqtype) {
  const url = this.serviceUrl + 'license-getrecentapplicationrecord?AppName=' + appName + '&reqtype=' + reqtype;
  return this.http.get(url).map(data => {
          return data;
      });
}
public getRequestCount() {
  const url = this.serviceUrl + 'license-getpendingcounts?EID=pawan.kumar.pal' ;
  return this.http.get(url).map(data => {
          return data;
      });
}
public reviewRequest(platformId, reqId) {
  const url = this.serviceUrl + 'license-getreviewedstatus?Platform=' + platformId + '&ReqID=' + reqId ;
  return this.http.get(url).map(data => {
          return data;
      });
}
public downloadFile(reqId) {
  const url = this.serviceUrl + 'qlik-uploadtos3?filename=' + reqId;
  return this.http.get(url).map(data => {
          return data;
      });
}

public getLicenseAssigned(appName) {  
  const url = this.serviceUrl + 'license_getlicenseassignedappusers?appname=' + appName;

  return this.http.get(url).map(data => {
          return data;
      });
}

}
