import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from './core/services/app-service';
import { GetRequestService } from './core/services/get-request.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-rebar',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  isVisible = true; loadingShow = false;
  contentToDisplay = '';
  constructor(private router: Router, private getService: GetRequestService, private auth: RebarAuthService, private ref: ApplicationRef,
              private toastr: ToastrService, public appService: AppService, private authService: MsalService) {
                const permissionArray = [{
                  EID: 'ajay.shelke', role: 'EII', appOnboarding: 0,
                  licensemanagement: 0, accessManagement: 0
                }];
                this.getService.getUserType(permissionArray);
              }

  ngOnInit() {
debugger;
console.log('App component called 1');
this.auth.authObserver$.subscribe((authStatus: boolean) => {
//Call function if user has loggedin
debugger;
console.log('App component rebar auth returned 1',authStatus);
if (authStatus) {
  if (this.auth.authenticationEnabled() && this.auth.IsUserAuthenticated()) {
    debugger;
        console.log('Authentication done');
     //   console.log('Logged in User ID  from the Token ' + this.auth.getUser());
        const enterpriseId = this.auth.getUser();
        console.log('Enterprise Id' + enterpriseId);
        // this.getService.getUserType(permissionArray);
      } else {
        console.log('User not autorise');
        // this.getService.getUserType(permissionArray);
        
      }
}

});
  //   const permissionArray = [{
  //     EID: 'ajay.shelke', role: 'EII', appOnboarding: 0,
  //     licensemanagement: 0, accessManagement: 0
  //   }];
  //   debugger;
  //   this.getService.getUserType(permissionArray);
  //   // this.loadingShow = true;
  //   this.toastr.success('Analytics Platform Application Management System loaded!');
  //   debugger;
  //   if (!this.auth.authenticationEnabled()) {
  //     this.isVisible = true;
  //   }
  //   console.log('before Authenticate');
  //   if (this.auth.authenticationEnabled() && this.auth.IsUserAuthenticated()) {
  //     debugger;
  //     console.log('Authentication done');
  //     console.log('Logged in User ID  from the Token ' + this.auth.getUser());
  //     const enterpriseId = this.auth.getUser();
  //     debugger;
  //     console.log('Enterprise Id' + enterpriseId);
  //     this.getService.getUserType(permissionArray);
  //   } else {
  //     console.log('User not autorise');
  //     debugger;
  //     this.getService.getUserType(permissionArray);
  //   }
  // }
}
}