import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { ToastrService } from 'ngx-toastr';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { NewRequestService } from '../../../core/services/new-request.service';

@Component({
  selector: 'app-license-transfer-request',
  templateUrl: './license-transfer-request.component.html',
  styleUrls: ['./license-transfer-request.component.css']
})
export class LicenseTransferRequestComponent implements OnInit {
  @Output() passData: EventEmitter<any> = new EventEmitter();
  @Input()
  appName;
  rowData = []; hasAttachment = false;
  filname = ''; body; allDataArray = []; applicationTeamName = ''; platform = '';
  owner = ''; newOwner = ''; comment = ''; validateAttachment = false; filedata = '';
  loadingShow = false;  approver = ''; appDisable = true;
  requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = '';  approverValMsg = '';
  ownerValMsg = ''; newOwnerValMsg = ''; commentValMsg = ''; regex = '[a-zA-Z.]*'; radioValMsg = ''; attachmentValMsg = ''; leaveOrg = 'Organization';
  constructor(private newreqservice: NewRequestService, private getService: GetRequestService,
              private getLicenseService: GetLicenseRequestService,
              private createService: CreateLicenseRequestService, private toastr: ToastrService) {
    const urlArray = [{ name: 'liscence', path: '/liscence/createrequest' }];
    this.getService.changeUrl(urlArray);
  }
  ngOnInit() {
    if (this.appName.Application_Name !== undefined) {
      this.applicationTeamName = this.appName.Application_Name;
      this.getApplicationData(this.applicationTeamName);
    } else {

      this.applicationTeamName = this.appName;
      console.log(this.appName);
    }
  }
  getApplicationData(appName) {
    this.loadingShow = true;
    this.getLicenseService.getApplicationDetails(appName, 'Transfer').subscribe((res: any) => {
      if (res[0].length > 0) {
        this.platform = res[0][0].PlatformId;
        this.approver = res[0][0].WBSEApprovedBy;
      }
      this.loadingShow = false;
    }, error => {
      this.loadingShow = false;
    });
  }
  selectFile(event) {
    if (event.target.files.length > 0) {
      this.filname = event.target.files[0].name;
      this.body = event.target.files.item(0);
      this.hasAttachment = true;
    } else {
      this.hasAttachment = false;
    }
  }
  sendDetails() {
    const data = { details: this.rowData, filename: this.filname, fileBody: this.body };
    this.allDataArray.push(data);
    this.passData.emit(this.allDataArray);
    // this.activeModal.close();
  }
  validation() {
    if (this.applicationTeamName === '') {
      this.applicationTeamNameValMsg = 'Please Enter Application Team Name';
      return false;
    }
    if (this.platform === '') {
      this.platformValMsg = 'Please Select Platform';
      return false;
    }
    if (this.approver === '') {
      this.approverValMsg = 'Please Enter Approver Name';
      return false;
    }
    if (this.owner === '') {
      this.ownerValMsg = 'Please Enter Current Owner EID';
      return false;
    }
    if (this.newOwner === '') {
      this.newOwnerValMsg = 'Please Enter New Owner EID';
      return false;
    }
    if (this.validateAttachment) {
      if (this.hasAttachment === false) {
        this.attachmentValMsg = 'Please attach necessary screenshot';
        return false;
      }
    }

    // Need to add validation for attachment
    if (this.comment === '') {
      this.commentValMsg = 'Please enter description comments';
      return false;
    }
    return true;
  }
  handleChange(value) {
    if (value === 'Project') {
      this.validateAttachment = true;
    } else {
      this.validateAttachment = false;
    }
  }

  approverEid() {
      this.approver = this.approver.split('@')[0]
      this.approver =  this.approver;
      const checkEmail = this.approver.match(this.regex);   
      if(checkEmail[0] === this.approver)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.approverValMsg = ''
      }  
      else
      {
        this.approverValMsg = 'Invalid EID,Please Enter valid EID'
        this.approver = '';
      }  
  }
  ownerEid() {
    this.owner = this.owner.split('@')[0]
    this.owner = this.owner;
    const checkEmail = this.owner.match(this.regex);   
      if(checkEmail[0] === this.owner)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.ownerValMsg=''
      }  
      else
      {
        this.ownerValMsg = 'Invalid EID,Please Enter valid EID';
        this.owner = '';
      }  
  }
  
  newOwnerEid(){
    this.newOwner = this.newOwner.split('@')[0]
    this.newOwner = this.newOwner;
    const checkEmail = this.newOwner.match(this.regex);   
      if(checkEmail[0] === this.newOwner)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.newOwnerValMsg=''
      }  
      else
      {
        this.newOwnerValMsg = 'Invalid EID,Please Enter valid EID';
        this.newOwner = '';
      }  

  }

  saveRequest() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        AppTeamName: this.applicationTeamName,
        PlatformId: this.platform,
        LicenseCategory: null,
        LicenseRequestType: null,
        RequesterOrganizationGroup: null,
        AIRID: null,
        NumberOfLicenses: null,
        WBSE: null,
        WBSEApprovedBy: this.approver,
        GeneralLedgerNo: null,
        Comments: null,
        CreatedDate: null,
        AssignmentDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        Status: null,
        Req_Flag: 'Transfer',
        License_Owner: this.owner,
        New_License_Owner: this.newOwner,
        HasEmployee_Attachment: this.leaveOrg,
        Attachment: null, // Add attachment
        Transfer_Release_Comment: this.comment,
        OpsComment: null,
        EmpList: [], filename: this.filname
      };
      console.log(dataToSend);
      this.createService.saveData(dataToSend).subscribe(res => {
        if (this.filname !== '') {
          this.newreqservice.saveFile(this.body, res).subscribe(res1 => {
            this.toastr.success('Request saved successfully.');
            this.clearAllData();
            this.loadingShow = false;
          }, error => {
            this.toastr.error('Some error occured.');
            console.log(error);
            this.loadingShow = false;
            this.clearAllData();
          });
        } else {
          this.toastr.success('Request saved successfully.');
          this.clearAllData();
          this.loadingShow = false;
        }
      },
        error => {
          console.log(error);
          this.toastr.error('Some error occured.');
          this.loadingShow = false;
          this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
  clearAllData() {
    this.platform = '';
    this.owner = ''; this.newOwner = ''; this.comment = '';
    this.filedata = '';
    this.hasAttachment = false; this.leaveOrg = 'Organization';
  }
  clearAllDatacancel() {
    this.appDisable = false;
    this.applicationTeamName = ''; this.platform = '';
    this.owner = ''; this.newOwner = ''; this.comment = '';
    this.filedata = '';
    this.hasAttachment = false; this.leaveOrg = 'Organization';
  }
  checkPlatform() {
    if (this.platform === '') {
      this.platformValMsg = 'Please select platform';
      this.owner = '';
      this.newOwner = '';
      return false;
    }
    return true;
  }
  resetOwnerField() {
    this.owner = '';
    this.newOwner = '';
  }
  checkUser(type) {
    if (!this.checkPlatform()) {
      return false;
    }
    let eid;
    type === 'new' ? eid = this.newOwner : eid = this.owner;
    if ((type === 'new' && this.newOwner !== '') || (type === 'old' && this.owner !== '')) {
      this.loadingShow = true;
      let platformId;
      if (this.platform.toString() === '1') {
        platformId = 1001;
      } else {
        platformId = 1002;
      }
      this.getLicenseService.verifyUser(eid, platformId, type).subscribe(res => {
        if (type === 'old') {
          if (res[0][0].count < 1) {
            this.owner = '';
            this.ownerValMsg = 'No license issued to this user';
          }
        }
        if (type === 'new') {
          if (res[0][0].count > 0) {
            this.newOwner = '';
            this.newOwnerValMsg = 'Request already raised for this user';
          }
        }
        this.loadingShow = false;
      }, error => {
        console.log(error);
      });
    }
  }
}

