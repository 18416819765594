import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'rebar-grid-checkbox',
  templateUrl: './grid-checkbox.component.html',
  styleUrls: ['./grid-checkbox.component.css']
})
export class GridCheckboxComponent implements ICellRendererAngularComp {

  constructor() { }
  rowData;
  params;
  label: string;
  agInit(params): void {
      this.params = params;
      this.rowData = params.data;
      this.label = this.params.label || null;
  }
  refresh(params?: any): boolean {
    return true;
}

}

