import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateLicenseRequestService {
  private serviceUrl = '';
  configData: any = null;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.configData = this.config.getConfig();
   // this.serviceUrl = this.configData.ServiceUrl;
    this.serviceUrl = this.configData.ServiceUrl;
  }
  public saveData(requestData) {
    const fullserviceUrl = this.serviceUrl + 'license-savenewrequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public updateBuyStatus(requestData) {
    const fullserviceUrl = this.serviceUrl + 'license-updatelicensebuystatus';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public editLicenseRequest(requestData) {
    const fullserviceUrl = this.serviceUrl + 'license-editlicensedetails';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public saveOperations(requestData) {
    const fullserviceUrl = this.serviceUrl + 'license-saveoperations';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
  public saveFile(filebody, url) {
    const fullserviceUrl = url;
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this.http.put(fullserviceUrl, filebody, { headers }).
    map((response: Response) => response);
  }
  public approveReject(requestData) {
    const fullserviceUrl = this.serviceUrl + 'license-approverequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(fullserviceUrl, requestData, { headers }).
    map((response: Response) => response);
  }
}
