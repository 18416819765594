import { Component, OnInit } from '@angular/core';
import { GetRequestService } from '../../../core/services/get-request.service';
import { ApproveButtonComponent } from '../approve-button/approve-button.component';
import { ApproveRequestComponent } from '../../create-request/approve-request/approve-request.component';
import { ViewRequestComponent } from '../view-request/view-request.component';
import { ReportDetailsButtonComponent } from '../report-details-button/report-details-button.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ReportDetailsComponent } from '../report-details/report-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-all-request',
  templateUrl: './all-request.component.html',
  styleUrls: ['./all-request.component.css']
})
export class AllRequestComponent implements OnInit {
  filterData: any; displayedColumns = '';
  public childGrid: any; public allData: any; public parentData: any; parentChildData: any;
  toDate = new Date(); fromDate = new Date(this.toDate.getFullYear(), (this.toDate.getMonth()), this.toDate.getDate() - 30);
  userPermission: any;
  frameworkComponents: any;
  disable: boolean;
  show: boolean;
  loadingShow = false;
  isUser = false; isEIIHide = true; isOpsHide = true;
  columnDefs;
  fromval = '';
  toval = '';
  userName = '';
  eid = '';
  eiData = 0; opsData = 0; showDiv = false;
  constructor(private service: GetRequestService, private modalService: NgbModal,
              private datepipe: DatePipe, private toastr: ToastrService) {
    this.frameworkComponents = {
      approve: ApproveButtonComponent,
      view: ViewRequestComponent,
      report: ReportDetailsButtonComponent
    };
    const urlArray = [{ name: 'applicationonbording', path: '/applicationonbording/request' }];
    this.service.changeUrl(urlArray);
    this.service.currentUrl.subscribe(url => {
      this.getRole();
    });
    service.userType.subscribe(userData => {
      this.userName = userData[0].EID;
    });
  }
  /**
   * Get the transaction data for all the documents as per request and application
   * @param fromdate ;
   * @param todate ;
   */
  defaultColDef = { resizable: true };
  rowData: any;
  alldata: any;
  viewUser(e) {
    const activeModal = this.modalService.open(ApproveRequestComponent, { size: 'xl' });
    activeModal.componentInstance.RequestId = e.rowData.Req_id;
    activeModal.componentInstance.approver = 'User';
    activeModal.componentInstance.statuses = [{
      eiistatus: e.rowData.EIIApproverStatusId,
      opsstatus: e.rowData.OpsApproverStatusId
    }];
    activeModal.componentInstance.disableVal = !((this.isUser || e.rowData.CreatedBy === this.eid) &&
      (e.rowData.opsApprovalStatus === 'Additional Information Needed' ||
        e.rowData.EiiApprovalStatus === 'Additional Information Needed'));
    activeModal.componentInstance.showComment = ((this.isUser || e.rowData.CreatedBy === this.eid) &&
      (e.rowData.opsApprovalStatus === 'Rejected' || e.rowData.EiiApprovalStatus === 'Rejected'
        || e.rowData.opsApprovalStatus === 'Additional Information Needed' ||
        e.rowData.EiiApprovalStatus === 'Additional Information Needed'));
    activeModal.componentInstance.passData.subscribe((res) => {
      if (res === 'f') {
        this.toastr.error('Some Error Occured');
      } else if (res === 's') {
        this.getAllRequest(this.fromval, this.toval);
        this.toastr.success('Record updated successfully.');
      } else {

      }
    });
  }
  oppsApproval(e) {
    const activeModal = this.modalService.open(ApproveRequestComponent, { size: 'xl' });
    activeModal.componentInstance.RequestId = e.rowData.Req_id;
    activeModal.componentInstance.approver = 'OPS';
    activeModal.componentInstance.disableVal = true;
    activeModal.componentInstance.showComment = true;
    activeModal.componentInstance.passData.subscribe((res) => {
      if (res === 'f') {
        this.toastr.error('Some Error Occured');
      } else if (res === 's') {
        this.getAllRequest(this.fromval, this.toval);
        this.toastr.success('Record updated successfully.');
      } else {

      }
    });
  }
  showDashboardDetails(e) {
    const activeModal = this.modalService.open(ReportDetailsComponent, { size: 'xl' });
    activeModal.componentInstance.RequestId = e.rowData.Req_id;
  }
  eiApproval(e) {
    const activeModal = this.modalService.open(ApproveRequestComponent, { size: 'xl' });
    activeModal.componentInstance.RequestId = e.rowData.Req_id;
    activeModal.componentInstance.approver = 'EII';
    activeModal.componentInstance.disableVal = true;
    activeModal.componentInstance.showComment = true;
    activeModal.componentInstance.passData.subscribe((res) => {
      if (res === 'f') {
        this.toastr.error('Some Error Occured');
      } else if (res === 's') {
        this.getAllRequest(this.fromval, this.toval);
        this.toastr.success('Record updated successfully.');
      } else {

      }
    });
  }
  getAllRequest(fromDate, toDate) {
   
    this.loadingShow = true;
    this.service.getAllRequest(fromDate, toDate)
      .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
      .subscribe(
        data1 => {
          console.log(data1);
          // debugger;
          this.rowData = data1[0];
          this.allData = data1[0];
          this.loadingShow = false;
          if (data1[1].length > 0) {
            this.eiData = data1[1].filter(x => x.EIIApproverStatusId === 101).length;
            this.opsData = data1[1].filter(x => x.OpsApproverStatusId === 101).length;
          }
          // this.rowData = [{RequestId: '1', platform: 'QlikSense', applicationname: 'Admin',
          // opsApprovalStatus: 'Pending', EiiApprovalStatus: 'Pending' }];
        },
        error => {
          this.loadingShow = false;
          this.parentData = error;
        }
      );
  }
  setFromDate() {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    console.log(this.fromval);
  }
  setToDate() {
    this.toval = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    console.log(this.toval);
  }
  statusColor(status) {
    return status === 'Approved' ? 'enableColor' :
      (status === 'Additional Information Needed' ? 'orangeColor' : 'pendingColor');
  }
  // statusColor(status) {

  //   return (status === 'Approved' || status === 'Released') ? 'enableColor' : (status === 'Rejected' ? 'cancelColor' : (status === 'Pending' ? 'pendingColor' : 'pendingColor' ));

  // }
  filterDataByRole(type) {
    if (type === 'EI') {
      this.rowData = this.allData.filter(x => x.EiiApprovalStatus === 'Pending');
    } else {
      this.rowData = this.allData.filter(x => x.opsApprovalStatus === 'Pending');
    }
  }
  finalStatusColor(data) {
    if (data.EiiApprovalStatus === 'Additional Information Needed' && data.opsApprovalStatus === 'Additional Information Needed') {
      return 'orangeColor';
    }
    if (data.EiiApprovalStatus === 'Approved' && data.opsApprovalStatus === 'Approved') {
      return 'enableColor';
    }
    if (data.EiiApprovalStatus === 'NA' && data.opsApprovalStatus === 'Approved') {
      return 'enableColor';
    }
    return 'pendingColor';
  }
  /**
   * Search the grid on the basis of dates selected
   */
  searchByDate() {
    const fromInput = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    const to = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllRequest(fromInput, to);
  }

  /**
   * initialeses the variables and functions
   */
  ngOnInit(): void {
    this.fromval = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
    this.toval = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + (this.toDate.getDate());
    this.getAllRequest(this.fromval, this.toval);
    this.service.getPeople().subscribe(
      data1 => {
        console.log(data1);
      },
      error => {
        console.log(error);
      }
    );
  }
  getRole() {
    this.service.getRole().take(1).subscribe(data1 => {
      if (data1[0].length > 0) {
        this.isUser = false;
        this.isEIIHide = true; this.isOpsHide = true;
        this.eid = data1[0][0].EID;
        data1[0][0].role === 'App' ? this.isUser = true : (data1[0][0].role === 'EII' ? this.isEIIHide = false : this.isOpsHide = false);
        this.showDiv = !this.isUser;
      } else {
        this.isUser = true;
        this.showDiv = false;
      }
      this.columnDefs = [
        { headerName: 'ID', field: 'Req_id', width: 80 },
        { headerName: 'Platform', field: 'RequestType', width: 110, filter: 'agTextColumnFilter' },
        {
          headerName: 'Application', field: 'Application_Name',
          width: this.isUser === true ? 280 : 180, filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Requestor', field: 'CreatedBy',
          width: 135, filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Ops Status', field: 'opsApprovalStatus', width: 135,
          filter: 'agTextColumnFilter', cellClass: (data) => this.statusColor(data.value)
        },
        {
          headerName: 'AI Status', field: 'EiiApprovalStatus', width: 135,
          cellClass: (data) => this.statusColor(data.value),
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Final Status', field: 'EiiApprovalStatus', width: 135,
          cellClass: (data) => this.finalStatusColor(data.data),
          cellRenderer: (data) => {
            return this.finalStatus(data.data);
          }, filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Date', field: 'CreatedDate',
          cellRenderer: (data) => {
            return this.datepipe.transform(data.value, 'yyyy-MM-dd');
          }, width: 110
        },
        // {headerName: 'Dashboard Details', width: 102, cellRenderer: 'report',
        // cellRendererParams: {
        //   onClick: this.showDashboardDetails.bind(this),
        //   label: 'Operations'
        // }, cellClass: 'grid-cell-centered' },
        {
          headerName: 'Ops Approval', width: 95, cellRenderer: 'approve',
          cellRendererParams: {
            onClick: this.oppsApproval.bind(this),
            label: 'Operations'
          }, cellClass: 'grid-cell-centered', hide: this.isOpsHide
        },
        {
          headerName: 'AI Approval', width: 95, cellRenderer: 'approve',
          cellRendererParams: {
            onClick: this.eiApproval.bind(this),
            label: 'EII'
          }, cellClass: 'grid-cell-centered', hide: this.isEIIHide
        },
        {
          headerName: 'View', width: 75, cellRenderer: 'view',
          cellRendererParams: {
            onClick: this.viewUser.bind(this),
            label: 'Click 1'
          }, cellClass: 'grid-cell-centered'
        }
      ];
    },
      error => {
        console.log(error);
      });
  }
  finalStatus(data) {
    if (data.EiiApprovalStatus === 'Pending' && data.opsApprovalStatus === 'Pending') {
      return 'Pending';
    }
    if (data.EiiApprovalStatus === 'NA' && data.opsApprovalStatus === 'Pending') {
      return 'Pending For Ops';
    }
    if (data.EiiApprovalStatus === 'NA' && data.opsApprovalStatus === 'Approved') {
      return 'Approved';
    }
    if (data.EiiApprovalStatus === 'NA' && data.opsApprovalStatus === 'Additional Information Needed') {
      return 'Pending';
    }
    if (data.EiiApprovalStatus === 'Pending' && data.opsApprovalStatus === 'Additional Information Needed') {
      return 'Pending For AI';
    }
    if (data.EiiApprovalStatus === 'Additional Information Needed' && data.opsApprovalStatus === 'Pending') {
      return 'Pending For Ops';
    }
    if (data.EiiApprovalStatus === 'Additional Information Needed' && data.opsApprovalStatus === 'Additional Information Needed') {
      return 'Additional Information Needed';
    }
    if (data.EiiApprovalStatus === 'Approved' && data.opsApprovalStatus === 'Approved') {
      return 'Approved';
    }
    if (data.EiiApprovalStatus === 'Approved' && data.opsApprovalStatus === 'Pending') {
      return 'Pending For Ops';
    }
    if (data.EiiApprovalStatus === 'Pending' && data.opsApprovalStatus === 'Approved') {
      return 'Pending For AI';
    }
    if (data.EiiApprovalStatus === 'Additional Information Needed' && data.opsApprovalStatus === 'Approved') {
      return 'Pending For AI';
    }
    if (data.EiiApprovalStatus === 'Approved' && data.opsApprovalStatus === 'Additional Information Needed') {
      return 'Pending For Ops';
    }
    if (data.EiiApprovalStatus === 'Cancelled' && data.opsApprovalStatus === 'Cancelled') {
      return 'Cancelled';
    }
  }
}
