import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { ConfirmationDialogService } from '../../../core/services/confirmation-dialog.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-license-popup',
  templateUrl: './license-approval-popup.component.html',
  styleUrls: ['./license-approval-popup.component.css']
})
export class LicenseApprovalPopUpComponent implements OnInit {
  @Input()
  userType;
  isView = 3;
  assignedOnDate = new Date();
  assignedOnVal = '';
  @Output() passData: EventEmitter<any> = new EventEmitter();
  @Input()
  RequestId: any;
  requesterName = ''; applicationTeamName = ''; platform = ''; licenseCategory = '';
  licenseReqType = ''; reqOrg = ''; numReqLicense = 0; arid = ''; approver = ''; wbs = '';
  accNumber = ''; comment = ''; disableVal = false;
  assigmentDate = new Date(); licenseType = ''; enviroment = ''; opsComment = '';
  licenseTypeValMsg = ''; enviromentValMsg = ''; opsCommentValMsg = '';
  applicationTeamNameValMsg = ''; platformValMsg = ''; licenseCategoryValMsg = '';
  licenseReqTypeValMsg = ''; reqOrgValMsg = ''; numReqLicenseValMsg = ''; aridValMsg = ''; approverValMsg = ''; wbsValMsg = '';
  accNumberValMsg = ''; commentValMsg = ''; requestedForValMsg = '';
  loadingShow = false; requestedForList = []; requestEid = ''; disableApprove = true;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
              private confirmationDialogService: ConfirmationDialogService, private toaster: ToastrService,
              private createService: CreateLicenseRequestService,
              private getService: GetLicenseRequestService) {
               }
  ngOnInit() {
    this.disableApprove =  this.userType === 'OPS' ? true : false;
    this.assignedOnVal = this.assigmentDate.getFullYear() + '-' +
    (this.assigmentDate.getMonth() + 1) + '-' + this.assigmentDate.getDate();
    console.log(this.assignedOnVal);
    this.loadingShow = true;
    this.getRequestById();
    console.log(this.userType);
  }
  getRequestById() {
    this.getService.getRequestById(this.RequestId, 'New_Req')
      .take(1).
      subscribe(
        data1 => {
          this.requesterName = data1[0][0].RequesterName; this.applicationTeamName = data1[0][0].AppTeamName;
          this.platform = data1[0][0].PlatformId; this.licenseCategory = data1[0][0].LicenseCategory;
          // this.licenseReqType = data1[0][0].LicenseRequestType;
          this.reqOrg = data1[0][0].RequesterOrganizationGroup; this.numReqLicense = data1[0][0].NumberOfLicenses;
          this.arid = data1[0][0].AIRID; this.approver = data1[0][0].WBSEApprovedBy; this.wbs = data1[0][0].WBSE;
          this.accNumber = data1[0][0].GeneralLedgerNo; this.requestedForList = []; this.comment = data1[0][0].Comments;
          this.loadingShow = false;
          this.requestedForList = data1[1];
        },
        error => {
          console.log(error);
          this.loadingShow = false;
        }
      );
  }
  setassignedOnDate() {
    this.assignedOnVal = this.assigmentDate.getFullYear() + '-' +
    (this.assigmentDate.getMonth() + 1) + '-' + this.assigmentDate.getDate();
    console.log(this.assignedOnVal);
  }

recieveFlag($event) {
  if ($event === true) {
      this.requestedForList = [];
  }
}
addNames() {
  this.requestedForList.push(this.requestEid);
  this.requestEid = '';
}
approveReject(type) {
if (this.approveValidation(type)) {
  this.loadingShow = true;
  const dataToSend = {
    Req_Flag: 'New_Req', approver: this.userType,
    AssignmentDate: this.assignedOnVal,
    licenseType_Id: this.licenseType, Environment: 'Production', OpsComment: this.opsComment,
    Req_ID: this.RequestId, EmpList: this.requestedForList, Status: type
  };
  console.log(dataToSend);
  this.createService.approveReject(dataToSend).subscribe(
    res => {
      console.log(res);
      this.loadingShow = false;
      this.passData.emit('s');
      this.activeModal.close();
    },
    error => {
      this.loadingShow = false;
      this.passData.emit('f');
      this.activeModal.close();
      console.log(error);
    }
  );
}
}
approveValidation(type) {
if (type === 'Approve' && this.userType === 'OPS') {
  if (this.licenseType === '') {
    this.licenseTypeValMsg = 'Please select license category type';
    return false;
 }
  if (this.enviroment === '') {
  this.enviromentValMsg = 'Please select environment type';
  return false;
}
  return true;
} else if (type === 'Reject') {
  if (this.opsComment === '') {
     this.opsCommentValMsg = 'Please enter comment for Rejection';
     return false;
  }
}
return true;
}

reviewRequest() {
  this.loadingShow = true;
  this.getService.reviewRequest(this.platform, this.RequestId).subscribe(
    res => {
      console.log( res[0][0].Status);
      this.disableApprove = res[0][0].Status === 1 ? false : true;
      this.loadingShow = false;
      if (!this.disableApprove) {
      this.toaster.success('Licenses are available.');
      }
      if (this.disableApprove) {
        this.toaster.error('License is not available.');
      }
    },
    error => {
      this.loadingShow = false;
      this.toaster.error('Some error Occured, Please try again');
    }
  );
}
}
