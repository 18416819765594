import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
  })

/**
 * Service class for app module
 */
export class AppService {
    private serviceUrl = '';
    configData: any = null;
    constructor(private http: HttpClient, private config: AppConfigService) {
      this.configData = this.config.getConfig();
      this.serviceUrl = this.configData.ServiceUrl;
    }

    /**
     * authenticate the user
     */

    authenticateUser() {
        const getserviceUrl = this.serviceUrl + 'getuserpermissionroles?&EnterpriseId=' + '';
        return this.http.get(getserviceUrl)
        .map(data => {
            return data;
        });
    }
  }

