import { LicenseQlikviewallComponent } from './feature/license-management/license-qlikviewall/license-qlikviewall.component';
import { AccessManageRequestComponent } from './feature/access-management/access-manage-request/access-manage-request.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';
import { NewRequestComponent } from './feature/create-request/new-request/new-request.component';
import { AllRequestComponent } from './feature/request-list/all-request/all-request.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { LicenseRequestComponent } from './feature/license-management/license-request/license-request.component';
import { LicenseAllRequestComponent } from './feature/license-management/license-all-request/license-all-request.component';
import { LicenseManageComponent } from './feature/license-management/license-manage/license-manage.component';
import { LicenseDashboardComponent } from './feature/license-management/license-dashboard/license-dashboard.component';
import { LicenseOperationComponent } from './feature/license-management/license-operation/license-operation.component';
import {AccessDashboardComponent } from './feature/access-management/access-dashboard/access-dashboard.component';
import {AccessRequestComponent } from './feature/access-management/access-request/access-request.component';
import {AccessAllRequestComponent } from './feature/access-management/access-all-request/access-all-request.component';
import {LicenseAssignedComponent } from './feature/license-management/license-assigned/license-assigned.component';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [REBAR_AUTH_GUARD],
        component: DashboardComponent
    },
    {
        path: 'chatbot/faq',
        canActivate: [REBAR_AUTH_GUARD],
        component: DashboardComponent
    },
    {
        path: 'applicationonbording/createrequest',
        canActivate: [REBAR_AUTH_GUARD],
        component: NewRequestComponent
    },
    {
        path: 'applicationonbording/request',
        canActivate: [REBAR_AUTH_GUARD],
        component: AllRequestComponent
    },
    {
        path: 'license/dashboard',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseDashboardComponent
    },
    {
        path: 'license/raiserequest',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseRequestComponent
    },
    {
        path: 'license/allrequest',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseAllRequestComponent
    },
    {
        path: 'license/assigned',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseAssignedComponent
    },
    {
        path: 'access/allrequest',
        canActivate: [REBAR_AUTH_GUARD],
        component: AccessAllRequestComponent
    },
    {
        path: 'license/manage',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseManageComponent
    },
    {
        path: 'license/operations',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseOperationComponent
    },
    {
        path: 'license/qlikviewall',
        canActivate: [REBAR_AUTH_GUARD],
        component: LicenseQlikviewallComponent
    },

    {
        path: 'access/dashboard',
        canActivate: [REBAR_AUTH_GUARD],
        component: AccessDashboardComponent
    },
    {
        path: 'access/raiserequest',
        canActivate: [REBAR_AUTH_GUARD],
        component: AccessRequestComponent
    },
    {
        path: 'access/manageapprovals',
        canActivate: [REBAR_AUTH_GUARD],
        component: AccessManageRequestComponent
    },
    {
        path: 'not-found',
        canActivate: [REBAR_AUTH_GUARD],
        component: PageNotFoundComponent,
    },
    { path: '**',
    redirectTo: 'not-found' }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
