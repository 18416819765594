import { Component, OnInit } from '@angular/core';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { GetRequestService } from '../../../core/services/get-request.service';
@Component({
  selector: 'rebar-license-assigned',
  templateUrl: './license-assigned.component.html',
  styleUrls: ['./license-assigned.component.css']
})
export class LicenseAssignedComponent implements OnInit {

  columnDefs;  
  appName = '';
  loadingShow = false;
  Appnames;
  keyword;
  rowData = [];
  public parentData: any;
  constructor(private getService: GetRequestService,private getLicenseService: GetLicenseRequestService) {  
    const urlArray = [{ name: 'Assigned', path: 'license/assigned' }];
    getService.changeUrl(urlArray);
 
  this.columnDefs = [
    {headerName: 'EID', field: 'License_Assigned_To', width: 200 },
    {headerName: 'Type Of License', field: 'License_Type', width: 400, filter: 'agTextColumnFilter'},
    {headerName: 'Platform', field: 'Platform', width: 400 , filter: 'agTextColumnFilter'}
    ];}

  ngOnInit() {
    this.getAppName();
  }

  getAppName() {
    this.loadingShow = true;
    this.getLicenseService.getapplicationName().subscribe(res => {
      this.Appnames = res[0];
      this.keyword = 'Application_Name';
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }

  searchByApplication() {
       this.getAllLicenseRequest();
  }

  getAllLicenseRequest() {
    this.loadingShow = true;
    this.getLicenseService.getLicenseAssigned(this.appName['Application_Name'])
    .take(1) // prevent memparentDataory leaks. Always unsubscribe subscriptions.
    .subscribe(
      data1 => {
        this.rowData = data1[0];
        this.loadingShow = false;
      },
      error => {
        this.parentData = error;
        this.rowData = [];
        this.loadingShow = false;
      }
    );
  }

}
