import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { ConfirmationDialogService } from '../../../core/services/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { OnChanges } from '@angular/core';
@Component({
  selector: 'app-license-request-view',
  templateUrl: './license-view-popup.component.html',
  styleUrls: ['./license-view-popup.component.css']
})
export class LicenseViewPopupComponent implements OnInit, OnChanges {
  assignedOnDate = new Date();
  assignedOnVal = '';
  @Input()
  RequestId: any;
  @Input()
  isView: number;
  isEdit: boolean;
  requesterName = ''; applicationTeamName = ''; platform = ''; licenseCategory = '';
  licenseReqType = ''; reqOrg = ''; numReqLicense = 0; arid = ''; approver = ''; wbs = '';
  accNumber = ''; comment = '';
  assigmentDate = new Date(); licenseType = ''; enviroment = ''; opsComment = '';
  licenseTypeValMsg = ''; enviromentValMsg = ''; opsCommentValMsg = '';
  requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = ''; licenseCategoryValMsg = '';
  licenseReqTypeValMsg = ''; reqOrgValMsg = ''; numReqLicenseValMsg = ''; aridValMsg = ''; approverValMsg = ''; wbsValMsg = '';
  accNumberValMsg = ''; commentValMsg = ''; requestedForValMsg = ''; regex = '[a-zA-Z.]*'
  loadingShow = false; requestedForList = []; requestEid = ''; addRequestedData = []; removeRequestedData = [];
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private createService: CreateLicenseRequestService,
              private toastr: ToastrService,  private confirmationDialogService: ConfirmationDialogService,
              private getLicService: GetLicenseRequestService,
              private getService: GetLicenseRequestService) { }

    ngOnInit() {
      this.getRequestById();
      this.isEdit = this.isView !== 2;
      console.log(this.isView);
    }
    ngOnChanges() {
      this.isEdit = this.isView !== 2;
    }
    getRequestById() {
      this.loadingShow = true;
      this.getService.getRequestById(this.RequestId, 'New_Req')
        .take(1).
        subscribe(
          data1 => {

            this.requesterName = data1[0][0].RequesterName; this.applicationTeamName = data1[0][0].AppTeamName;
            this.platform = data1[0][0].PlatformId; this.licenseCategory = data1[0][0].LicenseCategory;
            // this.licenseReqType = data1[0][0].LicenseRequestType;
            this.reqOrg = data1[0][0].RequesterOrganizationGroup; this.numReqLicense = data1[0][0].NumberOfLicenses;
            this.arid = data1[0][0].AIRID; this.approver = data1[0][0].WBSEApprovedBy; this.wbs = data1[0][0].WBSE;
            this.accNumber = data1[0][0].GeneralLedgerNo; this.requestedForList = [];
            this.addRequestedData = []; this.removeRequestedData = []; this.comment = data1[0][0].Comments;
            this.loadingShow = false;
            this.requestedForList = data1[1];

            // this.addRequestedData = data1[1];
            // console.log(this.firstdata)
          },
          error => {
            console.log(error);
            this.loadingShow = false;
          }
        );
    }
    validation() {
      if (this.reqOrg === '') {
        this.reqOrgValMsg = 'Please Enter Requester' + '\'' + 's Organization / Group';
        return false;
      }
      if (this.numReqLicense === 0) {
        this.numReqLicenseValMsg = 'Please Enter Number of Licenses Requested New/Renewal';
        return false;
      }
      if (this.arid === '') {
        this.aridValMsg = 'Please Enter AIRID';
        return false;
      }
      if (this.approver === '') {
        this.approverValMsg = 'Please Enter WBS Approver Name';
        return false;
      }
      if (this.wbs === '') {
        this.wbsValMsg = 'Please Enter WBS/Cost Collector';
        return false;
      }
      if (this.accNumber === '') {
        this.accNumberValMsg = 'Please Enter General Ledger Account No.';
        return false;
      }
      if (this.requestedForList.length === 0) {
        this.requestedForValMsg = 'Please Enter Requested for'; // and then hit "Add"
        return false;
      }
      if (this.comment === '') {
        this.commentValMsg = 'Please Enter Description for Request';
        return false;
      }
      return true;
    }
    updateRequest() {
      this.loadingShow = true;
      if (this.validation()) {
        const dataToSend = {
          AppTeamName: this.applicationTeamName,
          PlatformId: this.platform,
          LicenseCategory: this.licenseCategory,
          LicenseRequestType: this.licenseReqType,
          RequesterOrganizationGroup: this.reqOrg,
          AIRID: this.arid,
          NumberOfLicenses: this.numReqLicense,
          WBSE: this.wbs,
          WBSEApprovedBy: this.approver,
          GeneralLedgerNo: this.accNumber,
          Comments: this.comment,
          CreatedDate: null,
          AssignmentDate: null,
          ApprovedBy: null,
          ApprovedDate: null,
          Status: null,
          Req_Flag: 'New_Req',
          License_Owner: null,
          New_License_Owner: null,
          HasEmployee_Attachment: null,
          Attachment: null,
          Transfer_Release_Comment: null,
          OpsComment: null,
          EmpList: this.requestedForList,
          RequestId: this.RequestId,
          addRequestedData: this.addRequestedData,
          removeRequestedData: this.removeRequestedData
        };
        this.createService.editLicenseRequest(dataToSend).subscribe(res => {
          this.toastr.success('Request updated successfully.');
          // this.clearAllData();
          console.log(dataToSend);
          this.loadingShow = false;
          this.activeModal.close();
        },
          error => {
            console.log(error);
            this.loadingShow = false;
            // this.clearAllData();
          });
      } else {
        this.loadingShow = false;
      }
    }
    numberOnly($event): boolean {
      const charCode = ($event.which) ? $event.which : $event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    verifyUserId(eid): any {
      this.loadingShow = true;
      let platformId;
      if (this.platform.toString() === '1') {
        platformId = 1001;
      } else {
        platformId = 1002;
      }
      this.getLicService.verifyUser(eid, platformId, 'new').subscribe(res => {
        if (res[0][0].count < 1) {
          const array = { UserEID: this.requestEid, AssignedDate: null, licenseType_Id: null };
          this.addRequestedData.push(array);
          this.requestedForList.push(array);
          this.requestEid = '';
        } else {
          this.requestedForValMsg = 'Request already raised for this user';
        }
        this.loadingShow = false;
      }, error => {
        console.log(error);
        this.loadingShow = false;
        this.toastr.error('Some error occured');
        return null;
      });
    }
    updateEid() {
      if (this.platform === '') {
        this.platformValMsg = 'Please select platform';
        this.requestEid = '';
        return false;
      }
      return true;
    }

    approverEid() {
      this.approver = this.approver.split('@')[0]
      this.approver =  this.approver;
      const checkEmail = this.approver.match(this.regex);   
      if(checkEmail[0] === this.approver)
      {
          // console.log(checkEmail[0])
          // console.log(this.approver)
          this.approverValMsg=''
      }  
      else
      {
        this.approverValMsg = 'Invalid EID,Please Enter valid EID';
        this.approver = '';
      }  
  }

  requestedEid() {
    this.requestEid = this.requestEid.split('@')[0]
    this.requestEid =  this.requestEid;
    const checkEmail = this.requestEid.match(this.regex);   
    if(checkEmail[0] === this.requestEid)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.requestedForValMsg=''
    }  
    else
    {
      this.requestedForValMsg = 'Invalid EID,Please Enter valid EID';
      this.requestEid =  '';
    }  
 }
 
    addNames() {
      if (this.platform === '') {
        this.platformValMsg = 'Please select platform';
        this.requestEid = '';
        return false;
      } else if (this.platform !== '' && this.requestEid.trim() === '') {
        this.requestedForValMsg = 'Please enter requested for';
        return false;
      } else if (this.addRequestedData.some((item) => item.UserEID === this.requestEid)) {
        this.requestedForValMsg = 'Requested EID already present in below list.';
        this.requestEid = '';
        return false;
      } else {
        this.verifyUserId(this.requestEid);
      }
    }
    removeNames(eid) {
      if (this.addRequestedData.includes(eid)) {
        this.addRequestedData.forEach((item, index) => {
          if (item === eid) {
             this.addRequestedData.splice(index, 1);
             this.requestedForList.splice(this.requestedForList.indexOf(eid), 1);
             console.log(this.addRequestedData);
             console.log(this.removeRequestedData);
           }
          });
      } else {
        this.requestedForList.splice(this.requestedForList.indexOf(eid), 1);
        this.removeRequestedData.push(eid);
        console.log(this.requestedForList);
        console.log(this.removeRequestedData);
        console.log(this.addRequestedData);
      }
    }
    clearAllData() {
      this.platform = ''; this.licenseCategory = '';
      this.licenseReqType = ''; this.reqOrg = ''; this.numReqLicense = 0; this.arid = ''; this.approver = ''; this.wbs = '';
      this.accNumber = ''; this.requestedForList = []; this.comment = '';
    }
}
