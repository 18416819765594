import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-rebar-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.css']
})
export class DownloadButtonRequestComponent implements ICellRendererAngularComp {



  constructor() {}

    rowData;
    params;
    label: string;
    agInit(params): void {
        this.params = params;
        this.rowData = params.data;
        this.label = this.params.label || null;
    }
    refresh(params?: any): boolean {
        return true;
    }
    onClick($event) {
        if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      };
        this.params.onClick(params);

    }
  }

}
