import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { OnChanges } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-license-release-popup',
  templateUrl: './license-release-popup.component.html',
  styleUrls: ['./license-release-popup.component.css']
})
export class LicenseReleasePopUpComponent implements OnInit, OnChanges {
  @Input()
  userType;
   licId; licenseId;
  loadingShow = false; applicationTeamName = ''; platform = '';
 approver = '';
  comment = ''; opsComment = ''; opsCommentValMsg = '';
  licenseOwner = '';
  appDisable = true;
  requesterNameValMsg = ''; applicationTeamNameValMsg = ''; platformValMsg = ''; licenseOwnerValMsg = ''; commentValMsg = '';
  approverValMsg = ''; regex = '[a-zA-Z.]*';
  @Output() passData: EventEmitter<any> = new EventEmitter();
  @Input()
  RequestId: any;
  @Input()
  isView: number;
  isEdit: boolean;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private getLicenseService: GetLicenseRequestService,
              private toastr: ToastrService, private createSerive: CreateLicenseRequestService,
              private getService: GetLicenseRequestService, private createService: CreateLicenseRequestService) { }

  popOpen(p: NgbPopover, val): void {

    if (val > 2000000) {
      p.open();
    } else {
      p.close();
    }
  }
  closePopOver(p: NgbPopover) {
    p.close();
  }
  ngOnInit() {
    this.loadingShow = true;
    this.getRequestById();
    this.isEdit = this.isView !== 2;
  }
  ngOnChanges() {
    this.isEdit = this.isView !== 2;
  }
  getRequestById() {
    this.loadingShow = true;
    this.getService.getRequestById(this.RequestId, 'Release')
      .take(1).
      subscribe(
        data1 => {
          this.applicationTeamName = data1[0][0].AppTeamName; this.platform = data1[0][0].PlatformId;
          this.licenseOwner = data1[0][0].License_Owner;
          this.comment = data1[0][0].Transfer_Release_Comment;
          this.approver = data1[0][0].WBSEApprovedBy;
          if (data1[1].length > 0) {
            this.licId = data1[1][0].Lic_ID;
            this.licenseId = data1[1][0].License_ID;
          }
          this.loadingShow = false;
        },
        error => {
          console.log(error);
          this.loadingShow = false;
        }
      );
  }
  approveReject(type) {
    if (this.approveValidation(type)) {
      this.loadingShow = true;
      const dataToSend = {Req_ID: this.RequestId, approver: this.userType, opsComment: this.opsComment, License_Owner: this.licenseOwner,
        Lic_ID: this.licId, Status: type, Req_Flag: 'Release', };
      console.log(dataToSend);
      this.createService.approveReject(dataToSend).subscribe(
        res => {
          console.log(res);
          this.loadingShow = false;
          this.passData.emit('s');
          this.activeModal.close();
        },
        error => {
          this.loadingShow = false;
          this.passData.emit('f');
          this.activeModal.close();
          console.log(error);
        }
      );
    }
    }
  approveValidation(type) {
    if (type === 'Approve') {
      return true;
    } else {
      if (this.opsComment === '') {
        this.opsCommentValMsg = 'Please Enter comment for Rejection';
        return false;
      }
      return true;
    }
  }
  validation() {
    if (this.approver === '') {
      this.approverValMsg = 'Please Enter Approver Name';
      return false;
    }
    if (this.licenseOwner === '') {
      this.licenseOwnerValMsg = 'Please Enter Current Owner EID';
      return false;
    }
    if (this.comment === '') {
      this.commentValMsg = 'Please Enter Description';
      return false;
    }
    return true;
   }

   approverEid() {
    this.approver = this.approver.split('@')[0]
    this.approver =  this.approver;
    const checkEmail = this.approver.match(this.regex);   
    if(checkEmail[0] === this.approver)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.approverValMsg=''
    }  
    else
    {
      this.approverValMsg = 'Invalid EID,Please Enter valid EID'
      this.approver = ' ';
    }  
}
  
licenseOwnerEid(){
  this.licenseOwner = this.licenseOwner.split('@')[0]
    this.licenseOwner =  this.licenseOwner;
    const checkEmail = this.licenseOwner.match(this.regex);   
    if(checkEmail[0] === this.licenseOwner)
    {
        // console.log(checkEmail[0])
        // console.log(this.approver)
        this.licenseOwnerValMsg=''
    }  
    else
    {
      this.licenseOwnerValMsg = 'Invalid EID,Please Enter valid EID'
      this.licenseOwner = '';
    } 
}
   updateRequest() {
    this.loadingShow = true;
    if (this.validation()) {
      const dataToSend = {
        AppTeamName: this.applicationTeamName,
        PlatformId: this.platform,
        LicenseCategory: null,
        LicenseRequestType: null,
        RequesterOrganizationGroup: null,
        AIRID: null,
        NumberOfLicenses: null,
        WBSE: null,
        WBSEApprovedBy: this.approver,
        GeneralLedgerNo: null,
        Comments: this.comment,
        CreatedDate: null,
        AssignmentDate: null,
        ApprovedBy: null,
        ApprovedDate: null,
        Status: null,
        Req_Flag: 'Release',
        License_Owner : this.licenseOwner,
        New_License_Owner : null,
        HasEmployee_Attachment : null,
        Attachment : null,
         RequestId: this.RequestId,
        Transfer_Release_Comment : this.comment,
        OpsComment : null
      };
      this.createSerive.editLicenseRequest(dataToSend).subscribe(res => {
        this.toastr.success('Request updated successfully.');
        // this.clearAllData();
        this.loadingShow = false;
        this.activeModal.close();
      },
        error => {
          console.log(error);
          this.loadingShow = false;
          // this.clearAllData();
        });
    } else {
      this.loadingShow = false;
    }
  }
  clearAllData() {
    // this.applicationTeamName = '';
    this.platform = ''; this.licenseOwner = '';
    this.comment = '';
  }
  clearAllDatacancel() {
   this.appDisable = false;
   this.applicationTeamName = ''; this.platform = '';
   this.licenseOwner = '';
   this.comment = '';
 }
 resetOwnerField() {
  this.licenseOwner = '';
}
checkUser(type) {
  // if (!this.checkPlatform()) {
  //   return false;
  // }
  if (type === 'old' && this.licenseOwner !== '') {
  this.loadingShow = true;
  let platformId;
  if (this.platform.toString() === '1' ) {
    platformId = 1001;
  } else {
    platformId = 1002;
  }
  this.getLicenseService.verifyUser(this.licenseOwner, platformId, type).subscribe(res => {
    if (res[0][0].count < 1) {
          this.licenseOwner = '';
          this.licenseOwnerValMsg = 'No license issued to this user';
      }
    this.loadingShow = false;
  }, error => {
console.log(error);
  });
}
}
}
