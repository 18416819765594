import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../environments/environment.local';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { AccessRequestService } from '../../../core/services/access-request.service';
import { DataService } from '../../../core/services/DataService';
import { ToastrService } from 'ngx-toastr';
import { AccessRequestData } from '../../../core/model/access-request.model';
import {Router} from '@angular/router';

import {NewRequestType} from '../../../core/services/enums';

@Component({
  selector: 'app-stream-level-adgroup',
  templateUrl: './stream-level-adgroup.component.html',
  styleUrls: ['./stream-level-adgroup.component.css'],
})
export class StreamLevelAdgroupComponent implements OnInit {
  adgroups = [];
  removedadGroups: any[] = [];
  removedadGroupsText = '';
  removedadGrouplist;
  removedADCount = 0;
  newAdGroup = '';
  submitValMsg  = '';
  environmentname = '';
  selectedstreamName = '';
  loadingShow = false;
  comments = '';
  RequestTypeEnum = NewRequestType;
  @Input() DetailsFromStreamLevelAccess: AccessRequestData;
  @Output() backtoraiseRequest: EventEmitter<any> = new EventEmitter();
  constructor(private getDataService: DataService , private accessRequestService: AccessRequestService, private toastr: ToastrService,
              private modalService: NgbModal, private route: Router ) {

  }
  ngOnInit() {
    this.getADGroupsByAppId(this.DetailsFromStreamLevelAccess.AccessApplicationTeamId);
    this.environmentname = this.DetailsFromStreamLevelAccess.Environment;
    this.selectedstreamName = this.DetailsFromStreamLevelAccess.selectedstreamName;
  }

  validation() {
    if (this.newAdGroup !== '' && (this.newAdGroup.includes(',') || this.newAdGroup.includes(';'))) {
      this.submitValMsg  = 'Add only one AD group to submit a request.';
      return false;
    }
    if (this.removedadGroupsText === '' && this.newAdGroup === '' ) {
      this.submitValMsg  = ' Atleast One AD group is mandatory.(Add New or remove from Existing AD group(s))';
      // this.submitValMsg  = 'Add an AD group or remove from existing AD group(s) to submit a request.';
      return false;
    } else  if (this.comments === '') {
      // var oldADCount = this.adgroups.length;
      // var newAddedADCount = (this.newAdGroup !== '' ? 1 : 0);
      // var removedADCount = this.removedadGroups.length;
      console.log('Total AD Count =' + this.adgroups.length + ' + ' + (this.newAdGroup !== '' ? 1 : 0) +
      ' - ' + this.removedADCount + ' = ' + ((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount));
      if (((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount) > 1) {
          this.submitValMsg  = 'Please provide Business Justification for having more than one AD group';
          return false;
        }
    }
    // else if ((this.adgroups.length > 0 &&
    //    this.removedadGroups.length !== this.adgroups.length && this.newAdGroup !== '' && this.comments === '')) {
    //   this.submitValMsg  = 'Please provide comments for having more than one AD group';
    //   return false;
    // }
    return true;
  }
  // openConfirmBox(content){
  //   if (this.validation () === true) {
  //     if (((this.adgroups.length + (this.newAdGroup !== '' ? 1 : 0)) - this.removedADCount) > 1) {
  //   const activeModal = this.modalService.open(content, { size: 'lg' });
  //     } else {
  //       this.Submit_Click(content);
  //     }
  //   }
  // }
  Submit_Click() {
    if (this.validation()) {
      this.loadingShow = true;
      this.DetailsFromStreamLevelAccess.RequestTypeId = this.RequestTypeEnum.StreamADAccess;
      this.DetailsFromStreamLevelAccess.AddedADGroups = this.newAdGroup;
      this.DetailsFromStreamLevelAccess.RemovedADGroups = this.removedadGroupsText;
      this.DetailsFromStreamLevelAccess.RequesterComments = this.comments;
      console.log(this.DetailsFromStreamLevelAccess);
      this.accessRequestService.saveAccessRequest(this.DetailsFromStreamLevelAccess).subscribe((res: any) => {
        if (res[0].Res === 'Success') {
        this.toastr.success('Access Request Submitted.');
        // this.backtoraiseRequest.emit(true);
        this.route.navigate(['/access/allrequest']);
        // window.location.pathname='/access/raiserequest';
        } else {
          this.toastr.error('Some error occured.');
        }
        // this.modalService.dismissAll(content);
        this.clearAllData();
        this.loadingShow = false;
      },
        error => {
          console.log(error);
          // this.modalService.dismissAll(content);
          this.loadingShow = false;
          this.toastr.error('Some error occured.');
          this.clearAllData();
        });
  }
  }
  clearAllData() {
  }

  getADGroups() {
    this.getDataService.getADGroupsData().subscribe(res => {
      this.adgroups = res[0];
    }, error => {
      console.log(error);
    });
  }
  getADGroupsByAppId(appId) {
    this.loadingShow = true;
    this.accessRequestService.getADGroupsByAppId(appId).subscribe(res => {
      this.adgroups = res[0];
      console.log('retrived the add groups for the app id');
      console.log(res);
      this.loadingShow = false;
    }, error => {
      console.log(error);
      this.loadingShow = false;
    });
  }
  // Check box check/ uncheck enent capture for removing the selected item
  OnChange(e , selectedvalue) {
    console.log('selectedvalue' + selectedvalue);
    if (e.target.checked) {
      console.log('if');
      this.removedadGroups.push(selectedvalue);
    } else {
      console.log('else');
      this.removedadGroups.splice(this.removedadGroups.indexOf(selectedvalue), 1);
    }
    if (this.removedadGroups.length <= 0) {
      this.removedadGroupsText = '';
      this.removedADCount = 0;
    }

    console.log('removedadGroups length' + this.removedadGroups.length);
  }

  removeSelected_click()  {
    this.submitValMsg = '';
    if (this.removedadGroups.length > 0) {
      this.removedADCount = 0;
      this.removedadGroupsText = '';
      this.removedadGrouplist =  this.adgroups.filter(a => this.removedadGroups.indexOf(a.AccessApplicationTeam_ADGroupId) > -1);
      this.removedadGrouplist.forEach(element => {
      this.removedadGroupsText += element.Name +  ';';
      this.removedADCount++;
      });
      this.removedadGroupsText = this.removedadGroupsText.slice(0, -1);
  } else  {
    this.removedadGroupsText = '';
    this.removedADCount = 0;
  }

  }

  }
