import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
  export class AccessRequestService {
    private serviceUrl = '';
    configData: any = null;
    constructor(private http: HttpClient, private config: AppConfigService) {
        this.configData = this.config.getConfig();
        this.serviceUrl = this.configData.ServiceUrl;
      }
      public getMasterTables() {
        const url = this.serviceUrl + 'access-getmastertables';
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public getApplicationTeamsByEnvironment(environmentId) {
        const url = this.serviceUrl + 'access-getstreamsbyurl?environmentid=' + environmentId;
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public saveAccessRequest(requestData) {
        const fullserviceUrl = this.serviceUrl + 'access-savenewrequest';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(fullserviceUrl, requestData, { headers }).
        map((response: Response) => response);
      }
      public saveAllSecurityRequest(requestData) {
        const fullserviceUrl = this.serviceUrl + 'access-savenewrequest';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(fullserviceUrl, requestData, { headers }).
        map((response: Response) => response);
      }
      public getADGroupsByAppId(AppId) {
        const url = this.serviceUrl + 'access-getadgroupbyapp?appid=' + AppId;
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public getAdminEIDsByAppId(AppId) {
        const url = this.serviceUrl + 'access-getadminbyapp?appid=' + AppId;
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public getFileShareADGroupsByAppId(AppId) {
        const url = this.serviceUrl + 'access-getadgroupforfileshare?appid=' + AppId;
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public getDashBoardDetailsByAppId(AppId) {
        const url = this.serviceUrl + 'access-getdashboarddetailsbyapp?appid=' + AppId;
        return this.http.get(url).map(data => {
                return data;
            });
      }
      public reviewRequest(requestReviewData) {
        const fullserviceUrl = this.serviceUrl + 'access-approvereject';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(fullserviceUrl, requestReviewData, { headers }).
        map((response: Response) => response);
      }
  }
