import { Component, OnInit } from '@angular/core';
import { LicensePoolPopupComponent } from '../license-pool-popup/license-pool-popup.component';

import { GetLicenseRequestService } from '../../../core/services/get-license-request.service';
import { CreateLicenseRequestService } from '../../../core/services/create-license-request.service';
import { ViewButtonRequestComponent } from '../view-button/view-button.component';
import { GridCheckboxComponent } from '../grid-checkbox/grid-checkbox.component'
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-license-manage-pool',
  templateUrl: './license-manage-pool.component.html',
  styleUrls: ['./license-manage-pool.component.css']
})
export class LicenseManagePoolComponent implements OnInit {
  columnDefs; loadingShow = false;
  filterData: any; showDiv = true;
  licIdArray = [];
  frameworkComponents: any; isRowSelectable;
  defaultColDef = { resizable: true };
  rowData = [];
  qlikSenseData = ''; qlikViewData = '';
  constructor(private toastr: ToastrService,
              private modalService: NgbModal, private datepipe: DatePipe, private creteservice: CreateLicenseRequestService,
              private getLicenseService: GetLicenseRequestService) {
    this.frameworkComponents = {
      view: ViewButtonRequestComponent,
      gridCheckbox: GridCheckboxComponent
    };
    this.columnDefs = [
      {
        headerName: '', width: 55, cellRenderer: 'gridCheckbox',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellClass: 'grid-cell-centered'
      },
      { headerName: 'License ID', field: 'Lic_ID', width: 140 },
      { headerName: 'User EID', field: 'UserEID', width: 150, filter: 'agTextColumnFilter' },
      { headerName: 'Request ID', field: 'Req_ID', width: 140, filter: 'agTextColumnFilter' },
      { headerName: 'Platform', field: 'Platform', width: 140, filter: 'agTextColumnFilter' },
      {
        headerName: 'Assigned Date', field: 'AssignedDate', width: 160,
        cellRenderer: (data) => {
          return this.datepipe.transform(data.value, 'yyyy-MM-dd');
        }, filter: 'agTextColumnFilter'
      },
      { headerName: 'Status', field: 'Status', width: 140, filter: 'agTextColumnFilter' },
      { headerName: 'Type', field: 'Type', width: 140, filter: 'agTextColumnFilter' },
      {
        headerName: 'View', width: 80, cellRenderer: 'view',
        cellRendererParams: {
          onClick: this.licenseView.bind(this),
          label: 'View'
        }, cellClass: 'grid-cell-centered'
      }
    ];
    // tslint:disable-next-line: only-arrow-functions
    this.isRowSelectable = function(rowNode) {
      console.log(rowNode.data.BuyStatus);
      return (rowNode.data.BuyStatus === 0  && rowNode.data.StatusID === 101) ? true : false;
    };
  }
  ngOnInit() {
    this.getAllAsignedLicense();
    // this.getLicenseCount();
  }
  onSelectionChanged(event) {
    console.log(event.node.selected);
    event.node.selected === true ? this.licIdArray.push(event.node.data.Lic_ID) :
     this.licIdArray.splice(this.licIdArray.indexOf(event.node.data.Lic_ID), 1);
    console.log(this.licIdArray);

  }
  getLicenseCount() {
    this.getLicenseService.getLicenseCount().subscribe(res => {
      res[0].forEach(row => {
        if (row.Platform === 'Qlik Sense') {
          this.qlikSenseData = row.Remaining + '/' + row.Total;
        } else {
          this.qlikViewData = row.Remaining + '/' + row.Total;
        }
      });
    }, error => {
    //  this.rowData = [];
      console.log(error);
    });
  }
  updateBuyStatus() {
  this.loadingShow = true;
  const dataToSend = {licId : this.licIdArray};
  this.creteservice.updateBuyStatus(dataToSend).subscribe((res: any) => {
  this.loadingShow = false;
  if (res === 'Success') {
    this.toastr.success('Record(s) Updated Successfully.');
    this.getAllAsignedLicense();
  } else {
    this.toastr.success('Something went wrong, some error occured');
  }
  console.log(res);
}, error => {
  this.toastr.success('Something went wrong, some error occured');
  this.loadingShow = false;
  console.log(error);
});
  }
  getAllAsignedLicense() {
    this.loadingShow = true;
    this.getLicenseService.getAllAsignedRequest().subscribe(res => {
      this.rowData = res[0];
      console.log(this.rowData);
      this.loadingShow = false;
    }, error => {
      this.rowData = [];
      this.loadingShow = false;
      console.log(error);
    });
  }
  licenseView(e) {
    const modelRef = this.modalService.open(LicensePoolPopupComponent, { size: 'xl' });
    modelRef.componentInstance.LicenseID = e.rowData.Lic_ID;
  }
}
