import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetRequestService } from '../../../core/services/get-request.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  columnDefs;
  rowData: any;
  @Input()
  RequestId: any;
  defaultColDef = { resizable: true };
  constructor(public activeModal: NgbActiveModal, private getService: GetRequestService,
              private datepipe: DatePipe, ) {
    this.columnDefs = [
      {headerName: 'Reports', field: 'Report', width: 180},
      {headerName: 'Refresh Frequency', field: 'Frequency', width: 150, filter: 'agTextColumnFilter'},
      {headerName: 'Time Schedule', field: 'Schedule_Refresh', width: 160,
      filter: 'agTextColumnFilter' },
      {headerName: 'Planned Release', field: 'Planned_Release', cellRenderer: (data) => {
        return this.datepipe.transform(data.value, 'yyyy-MM-dd');
      }, width: 160 },
      {headerName: 'Ops Status', field: 'OpsApprovalStatus', width: 210,
      filter: 'agTextColumnFilter'},
      {headerName: 'AI Status', field: 'EIIApprovalStatus', width: 210,
      filter: 'agTextColumnFilter'}
    ];
   }

  ngOnInit() {
    this.getService.getDashboardDetails(this.RequestId).subscribe(res => {
     this.rowData = res[0];
    },
    error => {

    });
  }

}
